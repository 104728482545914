import {IbanProviders} from '@wallex-core-client/core/interfaces/iban.interface';
import {FirebaseOptions} from '@firebase/app';

export interface IHeaderMessage {
  title: string;
  text?: string;
  type: HeaderMessageType;
}

export enum HeaderMessageType {
  SAVING = 'saving',
  DEFAULT = 'default'
}

export interface IConfig {
  isMocked?: boolean;
  minSupportedVersion: {
    ios: string;
    android: string;
    web: string;
  };
  assetsSupportSendReceiveForms: string[];
  documents: IConfigDocuments;
  providers: IProvidersConfig;
  modules: IModulesConfig;
  locales: ILanguage[];
  friendInviteLink: string;
}

export interface IResult<T> {
  result: T;
}

export interface IConfigDocuments {
  privacypolicy: string;
  savingstermsandconditions?: string;
  credittermsandconditions?: string;
  termsandconditions: string;
  referralprogram: string;
  feeschedule: string;
}

export interface IProvidersConfig {
  card: string;
  iban: IbanProviders;
}

export interface IModulesConfig {
  isReferralProgramModuleIncluded: boolean;
}

export interface IEnvironment {
  production: boolean;
  serverBaseUri: string;
  wsUrl: string;
  intercomId: string;
  reduxStoreFreeze: boolean;
  reduxDevTools: boolean;
  isFireblocksKeyGenerationEnabled?: boolean;
  reCaptchaSiteKey?: string;
  isReferralProgramEnabled?: boolean;
  appLink?: string;
  isAuthBanner: boolean;
  firebaseConfig: FirebaseOptions;
  vapidKey: string;
  backgroundNotificationChannel: string;
  backgroundNotificationClickChannel: string;
  isAdditionalSolutionsMenuShown?: boolean;
  sentryDsn?: string;
}

export interface ILanguage {
  flag: string;
  name: string;
  code: string;
}

export type Writable<T> = {
  -readonly [K in keyof T]: T[K];
};

export enum FeeType {
  PERCENTAGE = 'PERCENTAGE',
  FIXED = 'FIXED'
}
