import {createFeatureSelector} from '@ngrx/store';

import {IAsset} from '@wallex-core-client/core/interfaces/asset.interface';

import {APP_VERSION} from '@wallex-core-client/core/constants/main-constants';
import {getInitialState} from '@wallex-core-client/utils';

export const assetsStateKey = 'assets';

export interface IAssetsState {
  readonly assets: IAsset[];
  readonly isAssetsLoadingFailure: boolean;
}

export const blankAssetsState = {
  assets: [],
  isAssetsLoadingFailure: false
};

export const initialAssetsState: IAssetsState = getInitialState(APP_VERSION, assetsStateKey) || blankAssetsState;

export const selectAssetsState = createFeatureSelector<IAssetsState>(assetsStateKey);
