import {Directive, HostListener, Input, OnChanges, SimpleChanges} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

import {numberToString} from '@wallex-core-client/utils';

@Directive({
  selector: '[appMinAmount]'
})
export class MinAmountDirective implements OnChanges {
  @Input('appMinAmount') decimals?: number;
  @Input('minAmountControl') formControl?: UntypedFormControl;
  @Input('maxAmountLength') maxlength?: number;

  private COMMA_LENGTH: number = 1;

  constructor() {}

  ngOnChanges({decimals}: SimpleChanges): void {
    if (this.formControl && decimals.previousValue && decimals.currentValue) {
      this.formControl.patchValue('');
    }
  }

  @HostListener('input', ['$event']) onInput(event: any): any {
    const value = event.target.value;

    if (value.includes('.')) {
      event.target.value = numberToString(value);
    }

    if (this.maxlength) {
      this.calcByMaxlength(event, this.maxlength);
      return;
    }

    let localDecimals = this.decimals;

    if (!localDecimals) return;

    if (value.includes(',')) {
      const lengthOfWholePart = value.split(',')[0].length + this.COMMA_LENGTH;
      localDecimals = Number(localDecimals) + lengthOfWholePart;
    } else {
      localDecimals = Number(localDecimals) + value.length;
    }

    event.target.setAttribute('maxlength', localDecimals);
  }

  private calcByMaxlength(event: any, maxlength: number) {
    const value = event.target.value;

    if (value.includes(',')) {
      const lengthOfWholePart = value.split(',')[0].length;
      if (maxlength - lengthOfWholePart < this.decimals!) {
        maxlength = lengthOfWholePart + this.decimals!;
      } else {
        maxlength = lengthOfWholePart + this.decimals + this.COMMA_LENGTH;
      }
    }

    event.target.setAttribute('maxlength', maxlength);
  }
}
