<span class="p-input-icon-left disp-block">
  <img class="search-input__icon" src="/assets/search.svg" alt="" width="24" height="24" />
  <input
    class="search-input__field disp-block"
    type="text"
    pInputText
    [placeholder]="placeholder"
    [disabled]="isDisabled"
    [value]="value"
    (input)="onChange($event)"
    (blur)="touched()"
  />
  <img *ngIf="value" class="search-input__delete" src="/assets/delete.svg" width="24" height="24" alt="" (click)="clearValue()" />
</span>
