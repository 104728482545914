import {createFeatureSelector} from '@ngrx/store';

import {IServerErrorDisplayData} from '@wallex-core-client/core/interfaces/error-display.interface';
import {IbanProviders} from '@wallex-core-client/core/interfaces/iban.interface';
import {APP_VERSION} from '@wallex-core-client/core/constants/main-constants';
import {IConfig} from '@wallex-core-client/core/interfaces/common.interface';
import {LANGUAGES} from '@wallex-core/constants/language.constants';
import {getInitialState} from '@wallex-core-client/utils';

export const appStateKey = 'app';

export interface IAppStatus {
  readonly isLoading: boolean;
  readonly hasFatalError: boolean;
  readonly errorData: IServerErrorDisplayData | null;
}

export interface IAppState {
  readonly appStatus: IAppStatus;
  readonly routerHistory: string[];
  readonly title: string;
  readonly isMainScreenActive: boolean;
}

export const emptyAppConfig: IConfig = {
  isMocked: true,
  minSupportedVersion: {
    ios: '',
    android: '',
    web: ''
  },
  assetsSupportSendReceiveForms: [],
  documents: {
    privacypolicy: '',
    savingstermsandconditions: '',
    credittermsandconditions: '',
    termsandconditions: '',
    referralprogram: '',
    feeschedule: ''
  },
  providers: {
    card: '',
    iban: IbanProviders.VOID
  },
  modules: {
    isReferralProgramModuleIncluded: false
  },
  locales: LANGUAGES,
  friendInviteLink: ''
};

const emptyAppState: IAppState = {
  appStatus: {
    isLoading: false,
    hasFatalError: false,
    errorData: null
  },
  routerHistory: [],
  title: '',
  isMainScreenActive: false
};

export const initialAppState: IAppState = getInitialState(APP_VERSION, appStateKey) || emptyAppState;

export const selectAppState = createFeatureSelector<IAppState>(appStateKey);
