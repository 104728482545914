import {Pipe, PipeTransform} from '@angular/core';

import {stringToNumber} from '@wallex-core-client/utils';

@Pipe({
  name: 'stringToNumber'
})
export class StringToNumberPipe implements PipeTransform {
  transform(value: number | string): number {
    return stringToNumber(value);
  }
}
