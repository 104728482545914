import {map, shareReplay, filter} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';

import {combineLatest, Observable} from 'rxjs';

import {omit} from 'lodash-es';

import {
  AddCurrencies,
  ClearSettings,
  LoadAutoConversionCurrencies,
  LoadGeneralSettings,
  LoadCountries,
  UpdateGeneralSettings,
  UpdateAutoConversionStatus,
  UpdateBalanceVisibility
} from '@wallex-core/store/settings/settings.actions';
import {ISettingsState, selectSettingsState} from '@wallex-core/store/settings/settings-state.model';
import {IAutoConversionStatus} from '@wallex-core-client/core/interfaces/auto-conversion.interface';
import {IGeneralSettings} from '@wallex-core-client/core/interfaces/general-settings.interface';
import {RefCurrencyResponseDto} from '@wallex-core-client/core/dtos/reference-currency.dto';

@Injectable({
  providedIn: 'root'
})
export class SettingsStateFacade {
  constructor(private readonly store$: Store<ISettingsState>) {}

  private readonly state$ = this.store$.pipe(select(selectSettingsState));

  readonly currencies$ = this.state$.pipe(
    select(state => state.currencies),
    shareReplay(1)
  );

  readonly generalSettings$ = this.state$.pipe(
    select(state => state.generalSettings),
    shareReplay(1)
  );

  readonly autoConversionStatus$ = this.generalSettings$.pipe(
    map(settings => omit(settings, 'isBalanceSeen')),
    shareReplay(1)
  );

  readonly isAutoConversionEnabled$ = this.generalSettings$.pipe(
    select(generalSettings => generalSettings.autoConversionEnabled),
    shareReplay(1)
  );

  readonly autoConversionAsset$ = this.generalSettings$.pipe(
    select(generalSettings => generalSettings.autoConversionAsset),
    shareReplay(1)
  );

  readonly autoConversionCurrencies$ = this.state$.pipe(
    select(state => state.autoConversionCurrencies),
    shareReplay(1)
  );

  readonly isBalanceShown$ = this.generalSettings$.pipe(
    select(settings => settings?.isBalanceSeen),
    shareReplay(1)
  );

  readonly allCountries$ = this.state$.pipe(
    select(state => state.countries),
    shareReplay(1)
  );

  readonly profileCountriesCodes$ = this.state$.pipe(
    select(state => state.profileCountriesCodes),
    shareReplay(1)
  );

  readonly profileCountries$ = combineLatest([this.allCountries$, this.profileCountriesCodes$]).pipe(
    filter(([countries, profileCountriesCodes]) => !!countries?.length && !!profileCountriesCodes?.length),
    map(([countries, profileCountriesCodes]) => countries.filter(country => profileCountriesCodes.includes(country.code))),
    shareReplay(1)
  );

  public getCurrencyByAsset$(asset: string): Observable<RefCurrencyResponseDto | undefined> {
    return this.currencies$.pipe(
      map(currencies => {
        return currencies.find(currency => currency.shortCode === asset);
      })
    );
  }

  public addCurrencies(currencies: RefCurrencyResponseDto[]): void {
    this.store$.dispatch(new AddCurrencies(currencies));
  }

  public loadCountries(): void {
    this.store$.dispatch(new LoadCountries());
  }

  public loadGeneralSettings(): void {
    this.store$.dispatch(new LoadGeneralSettings());
  }

  public updateGeneralSettings(settings: IGeneralSettings): void {
    this.store$.dispatch(new UpdateGeneralSettings(settings));
  }

  public updateAutoConversionStatus(autoConversionStatus: IAutoConversionStatus): void {
    this.store$.dispatch(new UpdateAutoConversionStatus(autoConversionStatus));
  }

  public updateBalanceVisibility(isBalanceSeen: boolean): void {
    this.store$.dispatch(new UpdateBalanceVisibility(isBalanceSeen));
  }

  public loadAutoConversionCurrencies(): void {
    this.store$.dispatch(new LoadAutoConversionCurrencies());
  }

  public clearSettings(): void {
    this.store$.dispatch(new ClearSettings());
  }
}
