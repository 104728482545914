import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(data: any[], filterByKey: string, filterByValue: string): any[] | null {
    if (!filterByValue) return data;

    const filteredData = data.filter(object => {
      const fieldValueLower = object[filterByKey].toLowerCase();
      const filterByValueLower = filterByValue.toLowerCase();

      return fieldValueLower.includes(filterByValueLower);
    });

    if (filteredData.length) return filteredData;

    return null;
  }
}
