import localeEn from '@angular/common/locales/en';
import localeBg from '@angular/common/locales/bg';
import localeZh from '@angular/common/locales/zh';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localePt from '@angular/common/locales/pt';
import localeRu from '@angular/common/locales/ru';
import localeAr from '@angular/common/locales/ar';

import {ILanguage} from '@wallex-core-client/core/interfaces/common.interface';

export enum Locale {
  EN = 'en',
  BG = 'bg',
  CN = 'cn',
  ES = 'es',
  FR = 'fr',
  IT = 'it',
  PT = 'pt',
  RU = 'ru',
  AR = 'ar'
}

export const LANGUAGES: ILanguage[] = [
  {
    flag: '🇧🇬',
    name: 'Bulgarian',
    code: Locale.BG
  },
  {
    flag: '󠁧󠁢󠁥󠁮󠁧🇨🇳',
    name: 'Chinese',
    code: Locale.CN
  },
  {
    flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
    name: 'English',
    code: Locale.EN
  },
  {
    flag: '🇫🇷',
    name: 'French',
    code: Locale.FR
  },
  {
    flag: '🇮🇹',
    name: 'Italian',
    code: Locale.IT
  },
  {
    flag: '🇵🇹',
    name: 'Portuguese',
    code: Locale.PT
  },
  {
    flag: '🇷🇺',
    name: 'Russian',
    code: Locale.RU
  },
  {
    flag: '🇪🇸',
    name: 'Spanish',
    code: Locale.ES
  }
];

export const LOCALES = Object.values(Locale);

export const DEFAULT_LOCALE = Locale.EN;

export const ngLocales = {
  [Locale.EN]: localeEn,
  [Locale.BG]: localeBg,
  [Locale.CN]: localeZh,
  [Locale.ES]: localeEs,
  [Locale.FR]: localeFr,
  [Locale.IT]: localeIt,
  [Locale.PT]: localePt,
  [Locale.RU]: localeRu,
  [Locale.AR]: localeAr
};
