import {Component, ChangeDetectionStrategy, Input, HostBinding, forwardRef, ChangeDetectorRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchInputComponent),
      multi: true
    }
  ]
})
export class SearchInputComponent implements ControlValueAccessor {
  @HostBinding('class.search-input') className: boolean = true;
  @Input() placeholder!: string;

  public changed!: (value: string) => void;
  public touched!: () => void;
  public value!: string;
  public isDisabled!: boolean;

  constructor(private cd: ChangeDetectorRef) {}

  public onChange(event: Event): void {
    const value: string = (<HTMLInputElement>event.target).value;
    this.value = value;
    this.changed(value);
  }

  public registerOnChange(fn: any): void {
    this.changed = fn;
  }

  public registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  public writeValue(value: string): void {
    this.value = value;
    this.cd.detectChanges();
  }

  public setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  public clearValue(): void {
    this.value = '';
    this.changed('');
  }
}
