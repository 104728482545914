import {NgModule} from '@angular/core';

import {DatePipe} from '@angular/common';

import {
  DateTransactionPipe,
  TransactionStateClass,
  TransactionStateIcon,
  TransactionStateLabel,
  TransactionTitle,
  TransactionTypeIcon,
  TransparentTransactionTypeIcon
} from '@wallex-core-client/pipes';

@NgModule({
  declarations: [
    TransactionTypeIcon,
    TransparentTransactionTypeIcon,
    TransactionTitle,
    TransactionStateIcon,
    TransactionStateClass,
    TransactionStateLabel,
    DateTransactionPipe
  ],
  exports: [
    TransactionTypeIcon,
    TransparentTransactionTypeIcon,
    TransactionTitle,
    TransactionStateIcon,
    TransactionStateClass,
    TransactionStateLabel,
    DateTransactionPipe
  ],
  providers: [DatePipe]
})
export class TransactionPipeModule {}
