import {Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appNumberOnly]'
})
export class NumberOnlyDirective {
  allowedSymbolsRegex?: RegExp;
  isEnabled = true;
  @Input('allowedSymbols') set allowedRegex(allowedSymbolsRegex: string | null) {
    if (allowedSymbolsRegex === null) {
      this.isEnabled = false;
    }
    if (allowedSymbolsRegex) {
      this.allowedSymbolsRegex = new RegExp(allowedSymbolsRegex);
    }
  }
  numberOnlyRegex = /^[0-9]*[,.]{0,1}[0-9]*$/;

  @HostListener('keydown', ['$event'])
  public onKeydown(event: KeyboardEvent): void {
    if (!this.isEnabled) return;
    const {key} = event;
    const newValue = this.getNewValue(event, key);
    if (!this.valueIsValid(newValue) && this.isKeyPrintable(event) && !this.isSpecialOperation(event)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent): void {
    if (!this.isEnabled) return;
    const pastedText = event.clipboardData!.getData('text');
    const newValue = this.getNewValue(event, pastedText);
    if (!this.valueIsValid(newValue)) {
      event.preventDefault();
    }
  }

  private getNewValue(event: KeyboardEvent | ClipboardEvent, str: string): string {
    const target = event.target as HTMLInputElement;
    const {value = '', selectionStart, selectionEnd} = target;
    if (selectionStart && selectionEnd) {
      return [...value.split('').splice(0, selectionStart), str, ...value.split('').splice(selectionEnd)].join('');
    }
    const keyBoardEvent = event as KeyboardEvent;
    if (keyBoardEvent.key) {
      return keyBoardEvent.key;
    }
    return str;
  }

  private valueIsValid(value: string): boolean {
    const regex = this.allowedSymbolsRegex ? this.allowedSymbolsRegex : this.numberOnlyRegex;
    return regex.test(value);
  }

  private isSpecialOperation(event: KeyboardEvent): boolean {
    const {keyCode, ctrlKey, metaKey} = event;

    const keysACVXYZ = [65, 67, 86, 88, 89, 90];
    return (ctrlKey || metaKey) && keysACVXYZ.indexOf(keyCode) >= 0;
  }

  private isKeyPrintable(event: KeyboardEvent): boolean {
    const {keyCode} = event;
    return (
      (keyCode > 47 && keyCode < 58) ||
      keyCode === 32 ||
      keyCode === 13 ||
      (keyCode > 64 && keyCode < 91) ||
      (keyCode > 95 && keyCode < 112) ||
      (keyCode > 185 && keyCode < 193) ||
      (keyCode > 218 && keyCode < 223)
    );
  }
}
