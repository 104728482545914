export enum NotificationType {
  kycStatusApproved = 'kyc.status.approved',
  kycStatusRejected = 'kyc.status.rejected',
  cardLimitsApproved = 'card.limits.approved',
  cardLimitsFailed = 'card.limits.failed',
  ibanIbanCreated = 'iban.iban.created',
  ibanIbanFailed = 'iban.iban.failed',
  savingMaturityDateReached = 'saving.maturity_date.reached',
  birthdayGreetingsSent = 'birthday.greetings.sent',
  newsCreated = 'news.created',
  manualCreated = 'manual.created',
  transactionReceivedTransfer = 'transaction.received.transfer',
  transactionSentTransferCompleted = 'transaction.sent.transfer.completed',
  transactionSentTransferFailed = 'transaction.sent.transfer.failed',
  savingRewardCreated = 'saving.reward.created'
}

export const TransactionEventTypes: NotificationType[] = [
  NotificationType.transactionReceivedTransfer,
  NotificationType.transactionSentTransferCompleted,
  NotificationType.transactionSentTransferFailed,
  NotificationType.savingRewardCreated
];

export const dateFormat = 'H:mm:ss';
export const CONTAINER_HEIGHT = 'calc(100vh - 173px)';
export const mobileResolutionContainerHeight = 'calc(100vh - 196px)';
export const elementVisibilityThresholdToTriggerReadMark = 0.8; // how much % of the element is in view triggers read mark
export const pageSize = 20;
export const transactionNotificationPrefix = 'transaction';
