import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {DialogService} from 'primeng/dynamicdialog';
import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {ITwoFaEnablePayload, ITwoFaEnableResponse, ITwoFaQrCodeResponse} from '@wallex-core-client/core/interfaces/2fa.interface';
import {defaultPopupConfig, ENVIRONMENT_TOKEN, SKIP_AUTH_INTERCEPTOR} from '@wallex-core-client/core/constants/main-constants';
import {IEnvironment} from '@wallex-core-client/core/interfaces/common.interface';
import {UserStateFacade} from '@wallex-core/store/user/user-state-facade.service';
import {JwtTokenService} from '@wallex-core/services/jwt-token.service';
import {DefaultDialogComponent} from '@wallex-core-client/ui';
import {Routes} from '@wallex-core/constants/routes.enum';

@Injectable({
  providedIn: 'root'
})
export class TwoFaService {
  private host!: string;
  private allowedRoutesForUnsettedTwoFa = [
    Routes.dashboard,
    Routes.settings,
    Routes.twoFaSetup,
    Routes.twoFaConfirm,
    Routes.twoFaSuccess,
    Routes.signIn,
    Routes.exchange,
    Routes.all_accounts,
    Routes.savings,
    Routes.news,
    Routes.cards,
    Routes.newCard,
    Routes.markets,
    Routes.notifications,
    Routes.credits,
    Routes.referralProgram
  ];

  constructor(
    private translate: TranslateService,
    private dialogService: DialogService,
    private userStateFacade: UserStateFacade,
    private http: HttpClient,
    private jwtTokenService: JwtTokenService,
    private router: Router,
    @Inject(ENVIRONMENT_TOKEN) environment: IEnvironment
  ) {
    this.host = environment.serverBaseUri;
  }

  public getQrCode(): Observable<ITwoFaQrCodeResponse> {
    let headers = new HttpHeaders();
    headers = headers.append(SKIP_AUTH_INTERCEPTOR.title, SKIP_AUTH_INTERCEPTOR.value);

    return this.http.get<ITwoFaQrCodeResponse>(`${this.host}/auth/two-factor-auth/generate-qrcode`, {headers}).pipe(
      map(twoFaQrCode => {
        this.userStateFacade.updateTwoFaQrCode(twoFaQrCode);
        return twoFaQrCode;
      })
    );
  }

  public enableTwoFa(payload: ITwoFaEnablePayload): Observable<ITwoFaEnableResponse> {
    let headers = new HttpHeaders();
    headers = headers.append(SKIP_AUTH_INTERCEPTOR.title, SKIP_AUTH_INTERCEPTOR.value);

    return this.http.post<ITwoFaEnableResponse>(`${this.host}/auth/two-factor-auth/enable`, payload, {headers}).pipe(
      map(response => {
        this.jwtTokenService.token = response.token;
        return response;
      })
    );
  }

  public initTwoFa(): void {
    const ref = this.dialogService.open(DefaultDialogComponent, {
      ...defaultPopupConfig(this.translate.instant('two_fa.title')),
      data: {
        message: this.translate.instant('two_fa.to_use_all_features'),
        okayButtonLabel: this.translate.instant('two_fa.confirm')
      }
    });

    ref.onClose.subscribe(result => {
      if (result) this.router.navigate([Routes.twoFaSetup]).catch(() => null);
    });
  }

  public isUserAllowedToOpenRoute(url: string, isTwoFaOn: boolean): boolean {
    return isTwoFaOn || this.allowedRoutesForUnsettedTwoFa.some(route => route === url);
  }
}
