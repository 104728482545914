import {map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, switchMap} from 'rxjs';

import {NotificationDto, NotificationPayload, NotificationsResponseDto, UnreadCountDto} from '@wallex-core-client/core/dtos/notifications.dto';
import {NotificationsStateFacade} from '@wallex-core/store/notifications/notifications-state-facade.service';
import {NotificationType, pageSize} from '@wallex-core-client/core/constants/notification.constants';
import {ENVIRONMENT_TOKEN} from '@wallex-core-client/core/constants/main-constants';
import {IEnvironment} from '@wallex-core-client/core/interfaces/common.interface';
import {isTransactionEventNotification} from '@wallex-core-client/utils';
import {RoutePaths, Routes} from '@wallex-core/constants/routes.enum';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  host: string;

  constructor(private http: HttpClient, private notificationsStateFacade: NotificationsStateFacade, @Inject(ENVIRONMENT_TOKEN) environment: IEnvironment) {
    this.host = environment.serverBaseUri;
  }

  getMessages(after: string | null = ''): Observable<NotificationsResponseDto> {
    const params = this.getListRequestParams(after);
    return this.http.get<NotificationsResponseDto>(`${this.host}/push-notifications/list/messages${params}`);
  }

  getListRequestParams(after: string | null = ''): string {
    const take = `take=${pageSize}`;
    return after?.length ? `?after=${after}&${take}` : `?${take}`;
  }

  getNotification(id: string): Observable<NotificationDto> {
    return this.http.get<NotificationDto>(`${this.host}/push-notifications/data/${id}`);
  }

  getNotifications(after: string | null = ''): Observable<NotificationsResponseDto> {
    const params = this.getListRequestParams(after);
    return this.http.get<NotificationsResponseDto>(`${this.host}/push-notifications/list/transactions${params}`);
  }

  getUnreadNotificationsCount(): Observable<UnreadCountDto> {
    return this.http.get<UnreadCountDto>(`${this.host}/push-notifications/unread/count`).pipe(
      tap(res => {
        this.notificationsStateFacade.setUnreadNumber(res);
      })
    );
  }

  markNotificationsRead(ids: string[]): Observable<any> {
    return this.http.post<any>(`${this.host}/push-notifications`, {ids}).pipe(switchMap(_ => this.getUnreadNotificationsCount()));
  }

  public getNotificationRedirectUrl(message: NotificationPayload, messageId: string | null = null): string {
    const {type, id} = message;
    if (isTransactionEventNotification(type)) return `${Routes.account}/account/${RoutePaths.transactionDetails}/${id}`;
    switch (type) {
      case NotificationType.savingMaturityDateReached:
        return `${Routes.savingDetails}/${id}`;
      case NotificationType.ibanIbanCreated:
      case NotificationType.ibanIbanFailed:
        return `${Routes.account}/EUR`;
      default:
        const notificationId = messageId ? messageId : id;
        return `${Routes.notifications}/${RoutePaths.messageDetails}/${notificationId}`;
    }
  }
}
