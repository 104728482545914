import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

import {Router} from '@angular/router';

import {catchError, debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {Observable, of, Subject, switchMap, takeUntil} from 'rxjs';
import {isString} from 'lodash-es';

import {SelectedWalletStateFacade} from '@wallex-core/store/selected-wallet/selected-wallet-state-facade.service';
import {commaToDot, getMinAmount, numberToString, stringToNumber} from '@wallex-core-client/utils';
import {ITopUpWithCardFee} from '@wallex-core-client/core/interfaces/checkout.interface';
import {AppStateFacade} from '@wallex-core/store/app/app-state-facade.service';
import {TransactionsService} from '@wallex-core/services/transactions.service';
import {IWallet} from '@wallex-core-client/core/interfaces/wallet.interface';
import {EXAMPLE_URL} from '@wallex-core/constants/defaults.constants';
import {RoutePaths, Routes} from '@wallex-core/constants/routes.enum';
import {minAmountValidator} from '@wallex-core-client/validators';

@Component({
  selector: 'app-top-up-with-card-dialog',
  templateUrl: './top-up-with-card-dialog.component.html',
  styleUrls: ['./top-up-with-card-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopUpWithCardDialogComponent implements OnInit, OnDestroy {
  public account!: IWallet;
  public form!: UntypedFormGroup;
  public isConfirmClicked = false;

  public feeEstimateError: string | null = null;
  public estimatedFee = 0;
  private readonly INVALID_FEE = '--';

  private readonly notifier$ = new Subject<void>();

  constructor(
    public ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private selectedWalletStateFacade: SelectedWalletStateFacade,
    private router: Router,
    private transactionsService: TransactionsService,
    private appStateFacade: AppStateFacade
  ) {}

  public get fee(): string {
    if (!this.form.value.amount) return this.INVALID_FEE;

    return numberToString(this.estimatedFee);
  }

  public get totalSum(): string | number {
    const amount = stringToNumber(this.form.value.amount);

    if (!amount) return this.INVALID_FEE;

    return numberToString(amount - this.estimatedFee);
  }

  ngOnInit(): void {
    this.initializeValues();
    this.initializeForm();
    this.initializeListeners();
  }

  private initializeForm(): void {
    const minAmount = getMinAmount(this.account.decimals);

    this.form = this.fb.group({
      amount: [null, [Validators.required, minAmountValidator(minAmount)]]
    });

    this.cd.detectChanges();
  }

  private initializeListeners(): void {
    this.form.controls['amount'].valueChanges
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        switchMap(amount => this.getEstimateFee$(amount)),
        takeUntil(this.notifier$)
      )
      .subscribe(result => {
        if (isString(result)) {
          this.feeEstimateError = result;
          this.cd.detectChanges();
          return;
        }

        this.feeEstimateError = null;
        this.estimatedFee = stringToNumber(result.estimatedFee);
        this.cd.detectChanges();
      });
  }

  private initializeValues(): void {
    this.account = this.config.data.account;
  }

  private getEstimateFee$(amount: string): Observable<ITopUpWithCardFee | string> {
    const payload = {amount: commaToDot(amount), walletId: this.account.id};

    return this.transactionsService.topUpWithCardFee(payload).pipe(catchError(error => of(error.error.message)));
  }

  public submit(): void {
    this.isConfirmClicked = true;
    this.cd.detectChanges();

    const payload = {
      amount: commaToDot(this.form.value.amount),
      walletId: this.account.id,
      successUrl: `${EXAMPLE_URL}/success`,
      cancelUrl: `${EXAMPLE_URL}/cancel`,
      failureUrl: `${EXAMPLE_URL}/failure`
    };

    this.transactionsService.topUpWithCard(payload).subscribe(
      data => {
        this.selectedWalletStateFacade.updateTopUpWithCardUrl(data.link);
        this.router.navigate([`${Routes.account}/${this.account.asset}/${RoutePaths.topUpWithCard}`]).catch(() => null);
        this.ref.close();
      },
      response => {
        this.appStateFacade.handleFatalError(response);
        this.isConfirmClicked = false;
        this.cd.detectChanges();
      }
    );
  }

  ngOnDestroy() {
    this.notifier$.next();
    this.notifier$.complete();
  }
}
