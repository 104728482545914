import {ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {firstValueFrom, withLatestFrom} from 'rxjs';
import snsWebSdk from '@sumsub/websdk';

import {TranslateService} from '@ngx-translate/core';

import {chineseLangCode, chineseISO639Code} from '@wallex-core-client/core/constants/main-constants';
import {UserStateFacade} from '@wallex-core/store/user/user-state-facade.service';
import {AppStateFacade} from '@wallex-core/store/app/app-state-facade.service';
import {KycService} from '@wallex-core/services/kyc.service';

@Component({
  selector: 'app-kyc-dialog',
  templateUrl: './kyc-dialog.component.html',
  styleUrls: ['./kyc-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KycDialogComponent implements OnInit {
  isKYCAccessible = true;
  userProfile: any;

  constructor(
    private userStateFacade: UserStateFacade,
    private kycService: KycService,
    private appStateFacade: AppStateFacade,
    private cdr: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.config?.data?.secondTry) {
      this.isKYCAccessible = false;
    } else {
      this.getKYCToken();
    }
  }

  getKYCToken() {
    this.config.styleClass = 'kyc-dialog close-icon';

    this.kycService
      .getKYCToken()
      .pipe(withLatestFrom(this.userStateFacade.userProfile$))
      .subscribe(
        ([token, userProfile]) => {
          try {
            this.isKYCAccessible = true;
            this.cdr.detectChanges();
            this.launchWebSdk(token, userProfile.email, undefined, {});
          } catch (err) {
            this.isKYCAccessible = false;
            console.log(err);
          }
        },
        response => {
          this.handleTokenFetchingError(response);
        }
      );
  }

  private handleTokenFetchingError(error: any) {
    this.isKYCAccessible = false;
    this.ref.close();
    this.cdr.detectChanges();
    this.appStateFacade.handleFatalError(error);
  }

  launchWebSdk(accessToken: string, applicantEmail: string, applicantPhone: string | undefined, customI18nMessages: any) {
    let lang = this.translate.currentLang === chineseLangCode ? chineseISO639Code : this.translate.currentLang;
    let snsWebSdkInstance = snsWebSdk
      .init(accessToken, () => this.getNewAccessToken())
      .withConf({
        lang,
        email: applicantEmail,
        phone: applicantPhone,
        i18n: customI18nMessages //JSON of custom SDK Translations
      })
      .withOptions({addViewportTag: false, adaptIframeHeight: true})
      // see below what kind of messages WebSDK generates
      .on('idCheck.onStepCompleted', payload => {
        console.log('onStepCompleted', payload);
      })
      .on('idCheck.onError', error => {
        console.log('onError', error);
      })
      .build();

    snsWebSdkInstance.launch('#sumsub-websdk-container');
  }

  async getNewAccessToken(): Promise<string> {
    return await firstValueFrom(this.kycService.getKYCToken()).catch(response => {
      this.handleTokenFetchingError(response.error);
      return '';
    });
  }

  public close(event: any): void {
    event.preventDefault();
    this.ref.close();
  }
}
