import {Component, ChangeDetectionStrategy, Input} from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgeComponent {
  @Input() isRed: boolean = false;
  @Input() isActive?: boolean;
  @Input() value: number | null = 0;

  constructor() {}
}
