import {map, shareReplay, tap, withLatestFrom} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {
  AddWallet,
  ClearWallets,
  LoadWallets,
  LoadWalletsFailure,
  LoadWalletsSuccess,
  SetSegmentDistributions,
  SetTotalBalance,
  SetWalletBalance,
  ToggleFavorite
} from '@wallex-core/store/wallets/wallets.actions';
import {IFavoriteWallet, IWallet, IWalletBalance} from '@wallex-core-client/core/interfaces/wallet.interface';
import {SegmentDistributionDto, SegmentDistributionsDto} from '@wallex-core-client/core/dtos/balance.dto';
import {IWalletsState, selectWalletsState} from '@wallex-core/store/wallets/wallets-state.model';
import {ENVIRONMENT_TOKEN} from '@wallex-core-client/core/constants/main-constants';
import {IEnvironment} from '@wallex-core-client/core/interfaces/common.interface';
import {UserStateFacade} from '@wallex-core/store/user/user-state-facade.service';
import {AppStateFacade} from '@wallex-core/store/app/app-state-facade.service';
import {assetType} from '@wallex-core-client/core/interfaces/asset.interface';
import {IbanStatuses} from '@wallex-core-client/core/dtos/wallet.dto';

@Injectable({
  providedIn: 'root'
})
export class WalletsStateFacade {
  constructor(
    private readonly store$: Store<IWalletsState>,
    private userStateFacade: UserStateFacade,
    private appStateFacade: AppStateFacade,
    @Inject(ENVIRONMENT_TOKEN) private environment: IEnvironment
  ) {}

  private readonly state$ = this.store$.pipe(select(selectWalletsState));

  readonly totalBalance$ = this.state$.pipe(
    select(state => state.totalBalance),
    shareReplay(1)
  );

  readonly totalBalanceInRefCurrency$ = this.totalBalance$.pipe(
    withLatestFrom(this.userStateFacade.currency$),
    map(([totalBalance, currency]) => {
      return `${totalBalance[currency] || 0}`;
    }),
    shareReplay(1)
  );

  readonly wallets$ = this.state$.pipe(
    select(state => state.wallets),
    map(wallets =>
      [...wallets].sort((walletA: IWallet, walletB: IWallet) => {
        if ((walletA.isFavorite && walletB.isFavorite) || (!walletA.isFavorite && !walletB.isFavorite)) return 0;
        if (walletA.isFavorite && !walletB.isFavorite) return -1;
        return 1;
      })
    ),
    shareReplay(1)
  );

  readonly exchangeWallets$ = this.state$.pipe(
    select(state => state.wallets),
    map(wallets => wallets.filter(wallet => wallet.isExchangeSupported)),
    shareReplay(1)
  );

  readonly cryptoWallets$ = this.wallets$.pipe(
    map(wallets => {
      return wallets.filter(wallet => wallet.assetType === assetType.crypto);
    }),
    shareReplay(1)
  );

  readonly fiatWallets$ = this.wallets$.pipe(
    map(wallets => {
      return wallets.filter(wallet => wallet.assetType === assetType.fiat);
    }),
    shareReplay(1)
  );

  readonly favoriteWallets$ = this.wallets$.pipe(
    map(wallets => wallets.filter(wallet => wallet.isFavorite)),
    shareReplay(1)
  );

  readonly isWalletsLoadingFailure$ = this.state$.pipe(
    select(state => state.isWalletsLoadingFailure),
    shareReplay(1)
  );

  readonly segmentDistributions$ = this.state$.pipe(select(state => state.segmentDistributions));

  public isShowReceive(wallet: IWallet): boolean {
    if (wallet.assetType === assetType.crypto || !wallet.isIbanSupported) {
      return Boolean(wallet.availableTopUpTypes.length);
    }

    return wallet.ibanStatus === IbanStatuses.SET;
  }

  public isShowSend(wallet: IWallet): boolean {
    if (wallet.assetType === assetType.crypto) {
      return Boolean(wallet.availableTransferTypes.length);
    }

    if (!wallet.isIbanSupported) {
      return Boolean(wallet.availableTransferTypes.length);
    }

    return wallet.ibanStatus === IbanStatuses.SET;
  }
  public get walletsWithReceive$(): Observable<IWallet[] | null> {
    return this.wallets$.pipe(
      withLatestFrom(this.userStateFacade.isIndividualAccount$),
      map(([wallets, isIndividualAccount]) => {
        if (!wallets) return null;

        return wallets.filter(wallet => {
          return !isIndividualAccount || this.isShowReceive(wallet);
        });
      })
    );
  }

  public get walletsWithSend$(): Observable<IWallet[] | null> {
    return this.wallets$.pipe(
      withLatestFrom(this.userStateFacade.isIndividualAccount$),
      map(([wallets, isIndividualAccount]) => {
        if (!wallets) return null;

        return wallets.filter(wallet => {
          return !isIndividualAccount || this.isShowSend(wallet);
        });
      })
    );
  }

  public isReceiveButtonShown$(wallet: IWallet): Observable<boolean> {
    return this.userStateFacade.isIndividualAccount$.pipe(
      map(isIndividualAccount => {
        if (!isIndividualAccount) return true;
        return this.isShowReceive(wallet);
      })
    );
  }

  public isSendButtonShown$(wallet: IWallet): Observable<boolean> {
    return this.userStateFacade.isIndividualAccount$.pipe(
      map(isIndividualAccount => {
        if (!isIndividualAccount) return true;
        return this.isShowSend(wallet);
      })
    );
  }

  public isIbanRequestButtonShown$(account: IWallet): Observable<boolean> {
    return this.userStateFacade.isIndividualAccount$.pipe(
      map(
        isIndividualAccount => isIndividualAccount && account.assetType === assetType.fiat && account.isIbanSupported && account.ibanStatus !== IbanStatuses.SET
      )
    );
  }

  public getWalletById$(walletId: string): Observable<IWallet | undefined> {
    return this.wallets$.pipe(map(wallets => this.getWalletById(wallets, walletId)));
  }

  public getWalletByAsset$(asset: string): Observable<IWallet | undefined> {
    return this.wallets$.pipe(map(wallets => this.getWalletByAsset(wallets, asset)));
  }

  public segmentDistributionByCurrency$(currency: string): Observable<SegmentDistributionDto | undefined> {
    return this.segmentDistributions$.pipe(
      map(distributions => {
        return distributions?.[currency];
      })
    );
  }

  public getWalletById(wallets: IWallet[], walletId: string): IWallet | undefined {
    return wallets.find(wallet => wallet.id === walletId);
  }

  public getWalletByAsset(wallets: IWallet[], asset: string): IWallet | undefined {
    return wallets.find(wallet => wallet.asset === asset);
  }

  public setTotalBalance(balance: Record<string, number>) {
    this.store$.dispatch(new SetTotalBalance(balance));
  }

  public setSegmentDistributions(segmentDistributions: SegmentDistributionsDto): void {
    this.store$.dispatch(new SetSegmentDistributions(segmentDistributions));
  }

  public setWalletBalance(walletBalance: IWalletBalance) {
    this.store$.dispatch(new SetWalletBalance(walletBalance));
  }

  public loadWallets(): void {
    this.store$.dispatch(new LoadWallets());
  }

  public loadWalletsSuccess(wallets: IWallet[]) {
    this.store$.dispatch(new LoadWalletsSuccess(wallets));
  }

  public loadWalletsFailure() {
    this.store$.dispatch(new LoadWalletsFailure());
  }

  public addWallet(wallet: IWallet) {
    this.store$.dispatch(new AddWallet(wallet));
  }

  public toggleFavorite(favoriteWallet: IFavoriteWallet) {
    this.store$.dispatch(new ToggleFavorite(favoriteWallet));
  }

  public clearWallets(): void {
    this.store$.dispatch(new ClearWallets());
  }
}
