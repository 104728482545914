import {differenceInYears, parse, isValid, subYears} from 'date-fns';
import {AbstractControl, ValidationErrors} from '@angular/forms';

export const checkDate = (dateFormat: string, comparison: number = 0): ValidationErrors | null => {
  return (control: AbstractControl) => {
    if (!control?.value?.length) return null;

    const dateValue = parse(control.value, dateFormat, new Date());

    if (!isValid(dateValue)) {
      return {invalidDate: true};
    }

    const today = new Date();
    const minimumDate = subYears(today, comparison);

    if (dateValue > minimumDate) {
      return {invalidDate: true};
    }

    return null;
  };
};

export const isAdult = (dateFormat: string) => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control?.value?.length) return null;
    const specifiedDate = parse(control.value, dateFormat, new Date());
    const duration = differenceInYears(new Date(), specifiedDate);
    return duration < 18 ? {notAdult: true} : null;
  };
};
