import {Pipe, PipeTransform} from '@angular/core';

import {CreditStatus, CreditStatusForUi} from '@wallex-core-client/core/interfaces/credits.interface';

@Pipe({
  name: 'creditStatus'
})
export class CreditStatusPipe implements PipeTransform {
  transform(status: CreditStatus): string {
    let statusKey;
    switch (status) {
      case CreditStatus.closed:
        statusKey = CreditStatusForUi.repaid;
        break;
      case CreditStatus.failed:
        statusKey = CreditStatusForUi.liquidated;
        break;
      default:
        statusKey = CreditStatusForUi.active;
        break;
    }
    return `credit.status.${statusKey}`;
  }
}
