import {Action} from '@ngrx/store';

import {IKYCData} from '@wallex-core-client/core/interfaces/kyc-data.interface';

export enum IbanActionType {
  UPDATE_KYC_DATA = '[KYC] Update KYC Data',
  CLEAR_IBAN = '[KYC] Clear Iban'
}
export class UpdateKYCData implements Action {
  readonly type = IbanActionType.UPDATE_KYC_DATA;

  constructor(readonly kycData: IKYCData) {}
}

export class ClearIbanAction implements Action {
  readonly type = IbanActionType.CLEAR_IBAN;
}

export type IbanAction = UpdateKYCData | ClearIbanAction;
