export enum additionalSolutions {
  CustodyPro = 'custody_pro',
  SmartCustody = 'smart_custody',
  SelfCustody = 'self_custody',
  Corporate = 'corporate',
  Private = 'private'
}

export const additionalSolutionsUrls = new Map<additionalSolutions, string>([
  [additionalSolutions.SmartCustody, 'https://wallex.global/smart-custody'],
  [additionalSolutions.CustodyPro, 'https://wallex.global/custody-pro'],
  [additionalSolutions.SelfCustody, 'https://wallex.global/self-managed-custody'],
  [additionalSolutions.Corporate, 'https://wallex.global/corporate'],
  [additionalSolutions.Private, 'https://wallex.global/personal']
]);
