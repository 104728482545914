import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

import {stringToNumber} from '@wallex-core-client/utils';

export function minAmountValidator(minAmount: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) return null;

    const number = stringToNumber(value);

    if (number < minAmount) return {minAmountError: true};

    return null;
  };
}

export function maxAmountValidator(maxAmount: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) return null;

    const number = stringToNumber(value);

    if (number > maxAmount) return {maxAmountError: true};

    return null;
  };
}
