import {createFeatureSelector} from '@ngrx/store';

import {IKYCData} from '@wallex-core-client/core/interfaces/kyc-data.interface';
import {APP_VERSION} from '@wallex-core-client/core/constants/main-constants';
import {getInitialState} from '@wallex-core-client/utils';

export const ibanStateKey = 'iban';

export interface IbanStateInterface {
  readonly kycData: IKYCData | null;
}

export const blankIbanState = {
  kycData: null
};

export const initialIbanState: IbanStateInterface = getInitialState(APP_VERSION, ibanStateKey) || blankIbanState;

export const selectIbanState = createFeatureSelector<IbanStateInterface>(ibanStateKey);
