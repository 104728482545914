export interface IDeliveryMethod {
  asset: string;
  method: string;
  price: string;
  icon: string;
  title: string;
  description: string;
  assetSign: string;
  id: string;
}

export interface ICardAddress {
  country: string;
  city: string;
  postcode: string;
  address: string;
}

export interface ICardOrderFee {
  cardOrderFee: string;
}

export interface IOrderCardPayload {
  firstName: string;
  lastName: string;
  deliveryMethodId: string;
}

export interface IOrderCardResponse {
  id: string;
  holderName: string;
  cardNumber: string;
  asset: string;
}

export interface ICardPin {
  pin: string;
}

export interface ICardsResponse {
  cards: ICard[];
}

export interface ICard {
  id: string;
  holderName: string;
  cardNumber: string;
  asset: string;
  status: CardStatuses;
}

export interface ILimitsStatus {
  status: LimitStatuses;
}

export interface IBlockCardPayload {
  reason: BlockCardReasons;
}

export interface ITopUpPayload {
  amount: string;
  assetFrom: string;
}

export interface ICardTransactionHistory {
  balance: number;
  currency: string;
  stopDate: string;
  transactions: ICardTransaction[];
}

export interface ICardTransaction {
  Amount: number;
  Currency: string;
  Date: string;
  Desciption: string;
  Description: string;
}

export enum BlockCardReasons {
  LOST = 'lost',
  STOLEN = 'stolen',
  CLOSE = 'close'
}

export enum DocumentTypes {
  PROOF_OF_IDENTITY = 'proof-of-identity',
  PROOF_OF_RESIDENCE = 'proof-of-residence'
}

export enum CardStatuses {
  INACTIVE = 'Inactive',
  NORMAL = 'Normal',
  SUSPEND = 'Suspend'
}

export enum CardProviders {
  INTERCASH = 'intercash'
}

export enum LimitStatuses {
  NOT_APPLIED = 'not-applied',
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected'
}
