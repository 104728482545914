<p *ngIf="headerMessageType.DEFAULT === message.type" [innerHTML]="message.text" class="header-message__text mb-0"></p>

<ng-container *ngIf="headerMessageType.SAVING === message.type">
  <p class="header-message__text">{{ 'savings.info_popup.first_paragraph' | translate }}</p>
  <p class="header-message__text">{{ 'savings.info_popup.second_paragraph' | translate }}</p>
  <h2 class="header-message__title">{{ 'savings.info_popup.first_title' | translate }}</h2>
  <p class="header-message__text">{{ 'savings.info_popup.third_paragraph' | translate }}</p>
  <h2 class="header-message__title">{{ 'savings.info_popup.second_title' | translate }}</h2>
  <p class="header-message__text">{{ 'savings.info_popup.last_paragraph' | translate }}</p>
  <small class="header-message__small">{{ 'savings.info_popup.small_text' | translate }}</small>
</ng-container>
