import {Pipe, PipeTransform} from '@angular/core';

import {ICountry} from '@wallex-core-client/core/interfaces/country.interface';

@Pipe({
  name: 'countryName'
})
export class CountryNamePipe implements PipeTransform {
  transform(countryCode: string, countries: ICountry[] | null): unknown {
    if (!countries) return null;

    const country = countries.find(country => country.code === countryCode);

    if (!country) return countryCode;

    return country.name;
  }
}
