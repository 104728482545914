import {switchMap, withLatestFrom} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';

import {NotificationsStateFacade} from '@wallex-core/store/notifications/notifications-state-facade.service';
import {ENVIRONMENT_TOKEN} from '@wallex-core-client/core/constants/main-constants';
import {IEnvironment} from '@wallex-core-client/core/interfaces/common.interface';
import {DeviceDto} from '@wallex-core-client/core/dtos/device.dto';
import {AuthService} from '@wallex-core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private host: string;

  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT_TOKEN) environment: IEnvironment,
    private notificationsStateFacade: NotificationsStateFacade,
    private authService: AuthService
  ) {
    this.host = environment.serverBaseUri;
    this.authService.logouted$
      .pipe(
        withLatestFrom(this.notificationsStateFacade.pushToken$),
        switchMap(([, token]) => {
          if (token) {
            return this.deleteUserDevice(token);
          }
          return of(null);
        })
      )
      .subscribe(
        _ => {
          console.log('Removed user device');
        },
        err => {
          console.log('Error on removing user device ', err);
        }
      );
  }

  registerUserDevice(data: DeviceDto): Observable<{}> {
    return this.http.post<{}>(`${this.host}/devices`, data);
  }

  deleteUserDevice(pushToken: string): Observable<any> {
    return this.http.delete<any>(`${this.host}/devices`, {
      body: {
        pushToken
      }
    });
  }
}
