import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {MessageService} from 'primeng/api';

import {IWallet} from '@wallex-core-client/core/interfaces/wallet.interface';

@Component({
  selector: 'app-receive-info-crypto-dialog',
  templateUrl: './receive-info-crypto-dialog.component.html',
  styleUrls: ['./receive-info-crypto-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService]
})
export class ReceiveInfoCryptoDialogComponent implements OnInit {
  account: IWallet;
  assetName: string;
  address!: string;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private translate: TranslateService, private messageService: MessageService) {}

  ngOnInit(): void {
    if (this.config?.data?.account) {
      this.account = this.config?.data?.account;
      this.assetName = `${this.account.title} (${this.account.asset})`;
      this.address = this.account.assetType === 'fiat' ? this.account.iban : this.account.address;
    }
  }

  close(event: MouseEvent) {
    event.preventDefault();
    this.ref.close(false);
  }

  copyAddressToClipboard() {
    navigator.clipboard.writeText(this.address).catch(() => null);
    this.messageService.add({
      severity: 'info',
      detail: this.translate.instant('account.receive-dialog.address-copied'),
      sticky: false
    });
  }
}
