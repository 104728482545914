import {Component, Input} from '@angular/core';

import {IWallet} from '../../../../../core/interfaces/wallet.interface';

@Component({
  selector: 'app-disabled-amount-input',
  templateUrl: './disabled-amount-input.component.html',
  styleUrls: ['./disabled-amount-input.component.scss']
})
export class DisabledAmountInputComponent {
  @Input() value!: number;
  @Input() account!: IWallet | null;
}
