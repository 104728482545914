import {Action} from '@ngrx/store';

import {ICard, ICardAddress, ICardTransactionHistory, IDeliveryMethod, LimitStatuses} from '@wallex-core-client/core/interfaces/cards.interface';

export enum CardsActionType {
  ADD_DELIVERY_METHODS = '[Cards] Add Delivery Methods',
  ADD_CARDS = '[Cards] Add Cards',
  ADD_CARD_ADDRESS = '[Cards] Add Card Address',
  ADD_CARD_ORDER_FEED = '[Cards] Add Card Order Fee',
  ADD_PIN = '[Cards] Add Pin',
  ADD_TRANSACTION_HISTORY = '[Cards] Add Transaction History',
  SET_LIMITS_STATUS = '[Cards] Set Limits Status',
  CLEAR_CARDS = '[Cards] Clear Cards'
}

export class AddDeliveryMethods implements Action {
  readonly type = CardsActionType.ADD_DELIVERY_METHODS;

  constructor(readonly deliveryMethods: IDeliveryMethod[]) {}
}

export class AddCards implements Action {
  readonly type = CardsActionType.ADD_CARDS;

  constructor(readonly cards: ICard[]) {}
}

export class AddPin implements Action {
  readonly type = CardsActionType.ADD_PIN;

  constructor(readonly pin: string) {}
}

export class AddCardAddress implements Action {
  readonly type = CardsActionType.ADD_CARD_ADDRESS;

  constructor(readonly cardAddress: ICardAddress) {}
}

export class AddCardOrderFee implements Action {
  readonly type = CardsActionType.ADD_CARD_ORDER_FEED;

  constructor(readonly cardOrderFee: string) {}
}

export class AddTransactionHistory implements Action {
  readonly type = CardsActionType.ADD_TRANSACTION_HISTORY;

  constructor(readonly transactionHistory: ICardTransactionHistory) {}
}

export class SetLimitsStatus implements Action {
  readonly type = CardsActionType.SET_LIMITS_STATUS;

  constructor(readonly limitStatus: LimitStatuses) {}
}

export class ClearCardsAction implements Action {
  readonly type = CardsActionType.CLEAR_CARDS;
}

export type CardsAction =
  | AddDeliveryMethods
  | ClearCardsAction
  | AddCards
  | AddCardAddress
  | AddCardOrderFee
  | AddPin
  | AddTransactionHistory
  | SetLimitsStatus;
