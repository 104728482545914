import {Component, ChangeDetectionStrategy} from '@angular/core';

@Component({
  selector: 'app-accounts-switch-overlay',
  templateUrl: './accounts-switch-overlay.component.html',
  styleUrls: ['./accounts-switch-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountsSwitchOverlayComponent {
  constructor() {}
}
