import {Component, ChangeDetectionStrategy} from '@angular/core';

import {additionalSolutions, additionalSolutionsUrls} from '@wallex-core/constants/addtional-solutions.map';

@Component({
  selector: 'app-additional-solutions',
  templateUrl: './additional-solutions.component.html',
  styleUrls: ['./additional-solutions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalSolutionsComponent {
  public readonly additionalSolutions = Object.values(additionalSolutions);
  public indexSeparatorNumber = 2;

  public openUrl(solution: string) {
    window.open(additionalSolutionsUrls.get(solution as additionalSolutions), '_blank');
  }

  public getSolutionIcon(solution: string): string {
    return `/assets/additional-solutions/${solution}.svg`;
  }

  public getSolutionHeader(solution: string, isSubheader = false): string {
    return `additional-solutions.${solution}-${isSubheader ? 'sub' : ''}header`;
  }
}
