import {createFeatureSelector} from '@ngrx/store';

import {ITwoFaQrCodeResponse, ITwoFaRequestPayload} from '@wallex-core-client/core/interfaces/2fa.interface';
import {IOrganization, IUser} from '@wallex-core-client/core/interfaces/user-profile.interface';
import {ITransactionModel} from '@wallex-core-client/core/interfaces/transaction.interface';
import {APP_VERSION} from '@wallex-core-client/core/constants/main-constants';
import {KycStatus} from '@wallex-core-client/core/interfaces/auth.interface';
import {getInitialState} from '@wallex-core-client/utils';

export const userStateKey = 'user';

export interface IUserState {
  readonly user: IUser;
  readonly isPersonalInfoSpecified: boolean;
  readonly isEmailConfirmed: boolean;
  readonly KYCStatus: KycStatus;
  readonly canResendConfirmationCodeAfter: number;
  readonly twoFaRequestPayload: null | ITwoFaRequestPayload<any, any>;
  readonly twoFaQrCode: null | ITwoFaQrCodeResponse;
  readonly twoFaPending: boolean;
  readonly currentOrganization: IOrganization | null;
  readonly lastTransactions: ITransactionModel[] | null;
  readonly isReferralCodeActivated: boolean;
}

export const blankUserState = {
  user: {
    profile: {}
  } as unknown as IUser,
  isPersonalInfoSpecified: false,
  isEmailConfirmed: false,
  KYCStatus: KycStatus.unverified,
  canResendConfirmationCodeAfter: 0,
  twoFaRequestPayload: null,
  twoFaQrCode: null,
  twoFaPending: false,
  currentOrganization: null,
  lastTransactions: null,
  isReferralCodeActivated: false
};

export const initialUserState: IUserState = getInitialState(APP_VERSION, userStateKey) || blankUserState;

export const selectUserState = createFeatureSelector<IUserState>(userStateKey);
