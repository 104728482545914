import {Component, Input} from '@angular/core';

import {DecimalPipe} from '@angular/common';

import {IWallet} from '@wallex-core-client/core/interfaces/wallet.interface';

@Component({
  selector: 'app-fee-container',
  templateUrl: './fee-container.component.html',
  styleUrls: ['./fee-container.component.scss'],
  providers: [DecimalPipe]
})
export class FeeContainerComponent {
  @Input() labelText = 'account.send-dialog.additional-fee';
  @Input() totalFee!: number;
  @Input() account!: IWallet;
  @Input() isShowFeeInRefCurrency!: boolean;
  @Input() currencySign!: string | null;
  @Input() feeInRefCurrency!: number;
}
