import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {of} from 'rxjs';

import {LoadWallets, LoadWalletsFailure, LoadWalletsSuccess, WalletsActionType} from '@wallex-core/store/wallets/wallets.actions';
import {AppStateFacade} from '@wallex-core/store/app/app-state-facade.service';
import {IWallet} from '@wallex-core-client/core/interfaces/wallet.interface';
import {WalletService} from '@wallex-core/services/wallet.service';

@Injectable()
export class WalletsEffects {
  constructor(private actions$: Actions, private walletService: WalletService, private appStateFacade: AppStateFacade) {}

  readonly loadWallets$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadWallets>(WalletsActionType.LOAD_WALLETS),
      switchMap(() => this.walletService.loadWallets()),
      mergeMap((wallets: IWallet[]) => [new LoadWalletsSuccess(wallets)]),
      catchError(error => {
        this.appStateFacade.handleFatalError(error);
        return of(new LoadWalletsFailure());
      })
    )
  );
}
