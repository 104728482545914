import {createFeatureSelector} from '@ngrx/store';

import {IGeneralSettings} from '@wallex-core-client/core/interfaces/general-settings.interface';
import {RefCurrencyResponseDto} from '@wallex-core-client/core/dtos/reference-currency.dto';
import {ICountry} from '@wallex-core-client/core/interfaces/country.interface';
import {IAsset} from '@wallex-core-client/core/interfaces/asset.interface';

import {APP_VERSION} from '@wallex-core-client/core/constants/main-constants';
import {getInitialState} from '@wallex-core-client/utils';

export const settingsStateKey = 'settings';

export interface ISettingsState {
  readonly currencies: RefCurrencyResponseDto[];
  readonly countries: ICountry[];
  readonly profileCountriesCodes: string[];
  readonly generalSettings: IGeneralSettings;
  readonly autoConversionCurrencies: IAsset[] | null;
}

export const blankGeneralSettingsState = {
  autoConversionEnabled: false,
  autoConversionAsset: '',
  isBalanceSeen: true,
  userId: ''
};

export const blankSettingsState = {
  currencies: [],
  countries: [],
  profileCountriesCodes: [],
  generalSettings: blankGeneralSettingsState,
  autoConversionCurrencies: null
};

export const initialSettingsState: ISettingsState = getInitialState(APP_VERSION, settingsStateKey) || blankSettingsState;

export const selectSettingsState = createFeatureSelector<ISettingsState>(settingsStateKey);
