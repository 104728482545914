import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-success-animation',
  templateUrl: './success-animation.component.html',
  styleUrls: ['./success-animation.component.scss']
})
export class SuccessAnimationComponent {
  @Input() width!: string;
  @Input() height!: string;
}
