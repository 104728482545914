import {Injectable} from '@angular/core';
import {isNull} from 'lodash-es';

import {mainConstants} from '@wallex-core-client/core/constants/main-constants';
import {ITokenData} from '@wallex-core-client/core/interfaces/auth.interface';
import {StorageService} from '@wallex-core/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class JwtTokenService {
  private _token: ITokenData | null = null;

  constructor(private storageService: StorageService) {}

  set token(token: ITokenData | null) {
    const tokenData = token;
    if (!isNull(tokenData)) {
      const dateNow = new Date().getTime();
      tokenData.accessExpiresIn = dateNow + tokenData.accessExpiresIn * 1000;
      tokenData.refreshExpiresIn = dateNow + tokenData.refreshExpiresIn * 1000;
    }
    this._token = tokenData;
    this.storageService.setItem(mainConstants.TOKEN_KEY, tokenData);
  }

  get token(): ITokenData | null {
    if (!isNull(this._token)) {
      return this._token;
    }
    this._token = this.storageService.getItem(mainConstants.TOKEN_KEY);
    return this._token;
  }

  clearToken() {
    this._token = null;
    this.storageService.removeItem(mainConstants.TOKEN_KEY);
  }
}
