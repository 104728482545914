import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {TranslateModule} from '@ngx-translate/core';

import {ImgSrcToBackgroundPipeModule} from '@wallex-core-client/pipes';
import {ActionButtonComponent} from './action-button.component';

@NgModule({
  imports: [CommonModule, TranslateModule.forChild(), ImgSrcToBackgroundPipeModule],
  declarations: [ActionButtonComponent],
  exports: [ActionButtonComponent]
})
export class ActionButtonModule {}
