import {accountSign} from '@wallex-core-client/core/interfaces/transaction.interface';

export const getAmountClass = (sign: accountSign): string => {
  switch (sign) {
    case accountSign.plus:
      return 'green';
    case accountSign.minus:
      return '';
    default:
      return '';
  }
};
