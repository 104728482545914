import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {
  RequestedFiatTransaction,
  ISendTransactionTwoFaPayload,
  ITransactionDetails,
  ITransactionFile,
  ITransactionModel
} from '@wallex-core-client/core/interfaces/transaction.interface';
import {
  ITopUpWithCardFee,
  ITopUpWithCardFeePayload,
  ITopUpWithCardPayload,
  ITopUpWithCardResponse
} from '@wallex-core-client/core/interfaces/checkout.interface';
import {ENVIRONMENT_TOKEN, SKIP_LOADER_INTERCEPTOR} from '@wallex-core-client/core/constants/main-constants';
import {IResponsePagination} from '@wallex-core-client/core/interfaces/api-response.interface';
import {IEnvironment} from '@wallex-core-client/core/interfaces/common.interface';
import {AppStateFacade} from '@wallex-core/store/app/app-state-facade.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {
  private host!: string;
  private ibanProvider!: string;
  private TRANSACTIONS_LIMIT: number = 10;

  constructor(private http: HttpClient, @Inject(ENVIRONMENT_TOKEN) private environment: IEnvironment, private appStateFacade: AppStateFacade) {
    this.host = environment.serverBaseUri;
    this.appStateFacade.ibanProvider$.subscribe(ibanProvider => {
      this.ibanProvider = ibanProvider;
    });
  }

  public getTransactions(asset: string, page: number, take: number = this.TRANSACTIONS_LIMIT): Observable<IResponsePagination<ITransactionModel[]>> {
    return this.http.get<IResponsePagination<ITransactionModel[]>>(`${this.host}/${asset}/transactions?page=${page}&take=${take}`);
  }

  public getLastTransactions(quantity: number): Observable<ITransactionModel[]> {
    return this.http.get<ITransactionModel[]>(`${this.host}/user/dashboard/transactions/history?quantity=${quantity}`);
  }

  public getTransactionHistory(asset: string, dateFrom: string, dateTo: string): Observable<any> {
    return this.http
      .get<string>(`${this.host}/${asset}/transactions/data/history/?dateFrom=${dateFrom}&dateTo=${dateTo}`, {responseType: 'blob' as 'json'})
      .pipe(
        map(response => {
          const newBlob = new Blob([response], {type: 'application/pdf'});
          return window.URL.createObjectURL(newBlob);
        })
      );
  }

  public topUpWithCard(payload: ITopUpWithCardPayload): Observable<ITopUpWithCardResponse> {
    return this.http.post<ITopUpWithCardResponse>(`${this.host}/top-up/payment-link/request`, payload);
  }

  public topUpWithCardFee(payload: ITopUpWithCardFeePayload): Observable<ITopUpWithCardFee> {
    const headers = {
      [SKIP_LOADER_INTERCEPTOR.title]: SKIP_LOADER_INTERCEPTOR.value
    };

    return this.http.post<ITopUpWithCardFee>(`${this.host}/top-up/estimate/fee`, payload, {headers});
  }

  public sendTransaction(transaction: ISendTransactionTwoFaPayload): Observable<ITransactionModel> {
    return this.http.post<ITransactionModel>(`${this.host}/${transaction.asset}/transactions/send`, transaction);
  }

  public sendFiatTransaction(transaction: RequestedFiatTransaction[0]): Observable<ITransactionModel> {
    return this.http.post<ITransactionModel>(`${this.host}/account/iban/${this.ibanProvider}/payout/send`, transaction);
  }

  public getTransactionById(tx: string): Observable<ITransactionDetails> {
    return this.http.get<ITransactionDetails>(`${this.host}/transactions/details/${tx}`);
  }

  public uploadFileForTransaction(formData: FormData): Observable<ITransactionFile> {
    return this.http.post<ITransactionFile>(`${this.host}/user/file/upload/transaction`, formData);
  }
}
