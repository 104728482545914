import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {isUndefined} from 'lodash-es';
import {Observable} from 'rxjs';

import {SKIP_TOKEN_INTERCEPTOR} from '@wallex-core-client/core/constants/main-constants';
import {JwtTokenService} from '@wallex-core/services/jwt-token.service';
import {AuthService} from '@wallex-core/services/auth.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private jwtTokenService: JwtTokenService, private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(SKIP_TOKEN_INTERCEPTOR.title)) {
      return next.handle(req);
    }

    const token = this.jwtTokenService.token?.accessToken;
    if (req.url === this.authService.refreshTokenEndpoint || isUndefined(token)) {
      return next.handle(req);
    }

    const signedRequest = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
    return next.handle(signedRequest);
  }
}
