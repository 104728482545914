import {Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, OnDestroy, Output, EventEmitter} from '@angular/core';

import {Subscription} from 'rxjs';

import {Router} from '@angular/router';

import {AppStateFacade} from '@wallex-core/store/app/app-state-facade.service';
import {RewardsService} from '@wallex-core/services/rewards.service';
import {Routes} from '@wallex-core/constants/routes.enum';

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RewardsComponent implements OnInit, OnDestroy {
  @Output() buttonClicked = new EventEmitter<void>();
  private subscriptions: Subscription[];
  public isReferralProgramEnabled: boolean;
  bonusesAmount: string | null;
  referralAmount: string | null;
  symbol: string;

  constructor(private appStateFacade: AppStateFacade, private rewardsService: RewardsService, private cdr: ChangeDetectorRef, private router: Router) {}

  ngOnInit() {
    this.initSubscriptions();
    this.getRewards();
  }

  private initSubscriptions() {
    this.subscriptions = [
      this.appStateFacade.isReferralProgramEnabled$.subscribe(isReferralProgramEnabled => {
        this.isReferralProgramEnabled = isReferralProgramEnabled;
        this.cdr.detectChanges();
      })
    ];
  }

  private getRewards() {
    this.rewardsService.getRewards().subscribe(
      res => {
        this.bonusesAmount = res.bonusesAmount;
        this.referralAmount = res.referralAmount;
        this.symbol = res.symbol;
        this.cdr.detectChanges();
      },
      error => {
        this.appStateFacade.handleFatalError(error);
      }
    );
  }

  public openReferralInfo() {
    void this.router.navigate([Routes.settings], {queryParams: {tab: 2}}).catch(() => null);
    this.buttonClicked.emit();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
