<p [innerHTML]="message"></p>
<div class="button-area">
  <ng-container *ngIf="!okayButtonLabel">
    <button pButton [label]="'common.okay' | translate" (click)="close()"></button>
  </ng-container>
  <ng-container *ngIf="okayButtonLabel">
    <button pButton class="cancel-button" *ngIf="isShowCloseButton" [label]="'common.cancel' | translate" (click)="close()"></button>
    <button pButton type="button" class="mr-0" [label]="okayButtonLabel | translate" (click)="confirm()"></button>
  </ng-container>
</div>
