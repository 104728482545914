export const mainConstants = {
  TOKEN_KEY: 'WAL_JWT_TOKEN',
  VERSION_KEY: 'version'
};

export const SKIP_AUTH_INTERCEPTOR = {
  title: 'skip-auth-interceptor',
  value: 'true'
};

export const SKIP_TOKEN_INTERCEPTOR = {
  title: 'skip-token-interceptor',
  value: 'true'
};

export const SKIP_LOADER_INTERCEPTOR = {
  title: 'skip-loader-interceptor',
  value: 'true'
};

export const defaultPopupConfig = (title: string, isModal = false, biggerPadding = false) => ({
  header: title,
  width: POPUP_WIDTH,
  closable: isModal,
  styleClass: biggerPadding ? 'no-x-button bigger-padding' : 'no-x-button',
  closeOnEscape: isModal,
  dismissableMask: isModal
});

export const ENVIRONMENT_TOKEN = Symbol('ENVIRONMENT');

export const MENU_ITEMS_TOKEN = Symbol('MENU_ITEMS');

export const LOCALE_COOKIE_KEY = 'wallex_locale';

export const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const specialSymbolRegex = /[$&+,:;=?@#|'<>.^*()%!-/{}\\\[\]~_`]/;

export const specialSymbolAndLetterRegex = /^(?=.*[a-zA-Z])(?=.*[\W_]).*$/;

export const isNumber = /^-?(0|[0-9]\d*)?$/;

export const locale = 'fr-FR';

export const PAGINATION_TAKE = 10;

export const PAGINATION_FIRST_PAGE = 1;

export const FIAT_DECIMALS = 2;

export const DIFF_RATE_DECIMALS = 2;

export const POPUP_WIDTH = '564px';

export const DATE_FORMAT = 'dd/MM/yyyy';

export const DATE_FORMAT_FROM_BACKEND = 'yyyy-MM-dd';

export const APP_VERSION = '1.6.0';

export const chineseLangCode = 'cn';

export const chineseISO639Code = 'zh';

export const hiddenAmount = '********';

export const forbiddenResourceErrorMessage = 'texts.Forbidden resource';

export const blockedUserErrorText = 'errors.you-blocked-for-brute-force';
