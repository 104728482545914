import {MessagePayload} from '@angular/fire/messaging';
import {Action} from '@ngrx/store';

import {UnreadCountDto} from '@wallex-core-client/core/dtos/notifications.dto';

export enum NotificationsActionType {
  SET_UNREAD_NUMBER = '[Notifications] Set unread notifications number',
  SET_PUSH_TOKEN = '[Notifications] Set permission requested',
  ADD_PUSH_NOTIFICATION = '[Notifications] Add push notification',
  CLEAR_PUSH_NOTIFICATION = '[Notifications] Clear foreground push notification',
  CLEAR_DATA = '[Notifications] Clear data'
}
export class SetUnreadNumber implements Action {
  readonly type = NotificationsActionType.SET_UNREAD_NUMBER;

  constructor(readonly unreadNotificationsNumber: UnreadCountDto) {}
}

export class SetPushToken implements Action {
  readonly type = NotificationsActionType.SET_PUSH_TOKEN;

  constructor(readonly token: string) {}
}

export class AddPushNotification implements Action {
  readonly type = NotificationsActionType.ADD_PUSH_NOTIFICATION;

  constructor(readonly notification: MessagePayload) {}
}

export class ClearPushNotification implements Action {
  readonly type = NotificationsActionType.CLEAR_PUSH_NOTIFICATION;

  constructor() {}
}

export class ClearData implements Action {
  readonly type = NotificationsActionType.CLEAR_DATA;
}

export type NotificationsAction = SetUnreadNumber | SetPushToken | AddPushNotification | ClearPushNotification | ClearData;
