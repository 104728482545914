import {Component, HostBinding} from '@angular/core';

@Component({
  selector: 'app-burger-animation',
  templateUrl: './burger-animation.component.html',
  styleUrls: ['./burger-animation.component.scss']
})
export class BurgerAnimationComponent {
  @HostBinding('attr.role') role = 'button';
}
