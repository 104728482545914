import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {ENVIRONMENT_TOKEN} from '@wallex-core-client/core/constants/main-constants';
import {IEnvironment} from '@wallex-core-client/core/interfaces/common.interface';
import {RewardsResponse} from '@wallex-core-client/core/dtos/rewards.dto';

@Injectable({
  providedIn: 'root'
})
export class RewardsService {
  private host!: string;

  constructor(private http: HttpClient, @Inject(ENVIRONMENT_TOKEN) environment: IEnvironment) {
    this.host = environment.serverBaseUri;
  }

  public getRewards(): Observable<RewardsResponse> {
    return this.http.get<RewardsResponse>(`${this.host}/rewards/overall`);
  }
}
