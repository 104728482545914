import {Action} from '@ngrx/store';

import {ICreditAsset, ICreditPeriod, ILtvGradation, IRequestedCredit, ICredit} from '@wallex-core-client/core/interfaces/credits.interface';
import {IPaginatedList} from '@wallex-core-client/core/interfaces/api-response.interface';

export const enum CreditsActionType {
  ADD_CREDIT_ASSETS = '[Credits] Add credit assets',
  ADD_COLLATERAL_ASSETS = '[Credits] Add collateral assets',
  ADD_CREDIT_PERIODS = '[Credits] Add credit periods',
  ADD_LTV_GRADATION = '[Credits] Add ltv gradation',
  REQUEST_CREDIT = '[Credits] Request credit',
  REPAY_CREDIT = '[Credits] Repay credit',
  ADD_ACTIVE_CREDITS = '[Credits] Add active credits',
  ADD_ARCHIVE_CREDITS = '[Credits] Add archive credits',
  CLEAR_REQUESTED_CREDIT = '[Credits] Clear requested credit',
  CLEAR_REPAID_CREDIT = '[Credits] Clear repaid credit',
  CLEAR_CREDITS = '[Credits] Clear credits'
}

export class AddCreditAssets implements Action {
  readonly type = CreditsActionType.ADD_CREDIT_ASSETS;

  constructor(readonly assets: ICreditAsset[]) {}
}

export class AddCollateralAssets implements Action {
  readonly type = CreditsActionType.ADD_COLLATERAL_ASSETS;

  constructor(readonly assets: ICreditAsset[]) {}
}

export class AddCreditPeriods implements Action {
  readonly type = CreditsActionType.ADD_CREDIT_PERIODS;

  constructor(readonly periods: ICreditPeriod[]) {}
}

export class AddLtvGradation implements Action {
  readonly type = CreditsActionType.ADD_LTV_GRADATION;

  constructor(readonly ltvGradation: ILtvGradation[]) {}
}

export class RequestCredit implements Action {
  readonly type = CreditsActionType.REQUEST_CREDIT;

  constructor(readonly credit: IRequestedCredit) {}
}

export class RepayCredit implements Action {
  readonly type = CreditsActionType.REPAY_CREDIT;

  constructor(readonly credit: IRequestedCredit) {}
}

export class AddActiveCredits implements Action {
  readonly type = CreditsActionType.ADD_ACTIVE_CREDITS;

  constructor(readonly credits: IPaginatedList<ICredit>) {}
}

export class AddArchiveCredits implements Action {
  readonly type = CreditsActionType.ADD_ARCHIVE_CREDITS;

  constructor(readonly credits: IPaginatedList<ICredit>) {}
}

export class ClearRequestedCredit implements Action {
  readonly type = CreditsActionType.CLEAR_REQUESTED_CREDIT;

  constructor() {}
}

export class ClearRepaidCredit implements Action {
  readonly type = CreditsActionType.CLEAR_REPAID_CREDIT;

  constructor() {}
}

export class ClearCredits implements Action {
  readonly type = CreditsActionType.CLEAR_CREDITS;

  constructor() {}
}

export type CreditsAction =
  | AddCreditAssets
  | AddCollateralAssets
  | AddCreditPeriods
  | AddLtvGradation
  | RequestCredit
  | RepayCredit
  | AddActiveCredits
  | AddArchiveCredits
  | ClearRequestedCredit
  | ClearRepaidCredit
  | ClearCredits;
