import {Pipe, PipeTransform} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

import {ITableParentPlan} from '@wallex-core-client/core/interfaces/savings.interface';
import {numberToString, daysToMonths} from '@wallex-core-client/utils';

@Pipe({
  name: 'savingTableDuration'
})
export class SavingTableDurationPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(rowData: ITableParentPlan | null): string {
    if (!rowData) return '0';

    if (!rowData.minDuration && !rowData.maxDuration) {
      return this.translate.instant('savings.flexible');
    }

    if (!rowData.minDuration) {
      return `${this.translate.instant('savings.flexible')} - ${rowData.maxDuration} ${this.translate.instant('savings.days')}`;
    }
    if (rowData.minBonusPercent || rowData.maxBonusPercent) {
      return `${daysToMonths(rowData.minDuration)} - ${daysToMonths(rowData.maxDuration)} ${this.translate.instant('savings.months')}`;
    }
    return `${rowData.minDuration} - ${rowData.maxDuration} ${this.translate.instant('savings.days')}`;
  }
}

@Pipe({
  name: 'savingTableAPY'
})
export class SavingTableAPYPipe implements PipeTransform {
  transform(rowData: ITableParentPlan | null): string {
    if (!rowData || !rowData.minAPY || !rowData.maxAPY) return '0,00%';

    const minAPY = numberToString(rowData.minAPY.toFixed(2));
    const maxAPY = numberToString(rowData.maxAPY.toFixed(2));

    if (rowData.minAPY === rowData.maxAPY) return minAPY;

    return `${minAPY}% - ${maxAPY}%`;
  }
}

@Pipe({
  name: 'savingTableBonus'
})
export class SavingTableBonusPipe implements PipeTransform {
  transform(rowData: ITableParentPlan | null): string {
    if (!rowData || !rowData.minBonusPercent || !rowData.maxBonusPercent) return '0,00%';

    const minBonusPercent = numberToString(rowData.minBonusPercent.toFixed(2));
    const maxBonusPercent = numberToString(rowData.maxBonusPercent.toFixed(2));

    if (rowData.minBonusPercent === rowData.maxBonusPercent) return minBonusPercent;

    return `${minBonusPercent}% - ${maxBonusPercent}%`;
  }
}

@Pipe({
  name: 'savingAPY'
})
export class SavingAPYPipe implements PipeTransform {
  transform(apy: number | string): string | number {
    if (!apy) return apy;

    const result = numberToString(Number(apy).toFixed(2));
    return `${result}%`;
  }
}

@Pipe({
  name: 'savingDuration'
})
export class SavingDurationPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(duration: number | string): string | number {
    if (!duration) return this.translate.instant('savings.flexible');

    return `${duration} ${this.translate.instant('savings.days')}`;
  }
}

@Pipe({
  name: 'savingComboDuration'
})
export class SavingComboDurationPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(duration: number | string): string | number {
    if (!duration) return this.translate.instant('savings.flexible');

    return `${daysToMonths(duration)} ${this.translate.instant('savings.months')}`;
  }
}
