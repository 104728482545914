import {TranslateModule} from '@ngx-translate/core';
import {DropdownModule} from 'primeng/dropdown';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';

import {DropdownFieldComponent} from '@wallex-core-client/ui';

@NgModule({
  declarations: [DropdownFieldComponent],
  imports: [DropdownModule, CommonModule, FormsModule, TranslateModule],
  exports: [DropdownFieldComponent]
})
export class DropdownFieldModule {}
