import {catchError, map, switchMap, take} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable, of} from 'rxjs';

import {mapValues} from 'lodash-es';

import {ChartInterval, IPriceChange, IRateModel, IRateResponse, IWalletChart, IWalletChartResponse} from '@wallex-core-client/core/interfaces/rate.interface';
import {ENVIRONMENT_TOKEN, SKIP_LOADER_INTERCEPTOR} from '@wallex-core-client/core/constants/main-constants';
import {IEnvironment, IResult} from '@wallex-core-client/core/interfaces/common.interface';
import {UserStateFacade} from '@wallex-core/store/user/user-state-facade.service';
import {IWallet} from '@wallex-core-client/core/interfaces/wallet.interface';
import {getPreviousDateISO} from '@wallex-core-client/utils';

@Injectable({
  providedIn: 'root'
})
export class RatesService {
  constructor(private http: HttpClient, private userStateFacade: UserStateFacade, @Inject(ENVIRONMENT_TOKEN) environment: IEnvironment) {
    this.host = environment.serverBaseUri;
  }

  private host!: string;

  private getRates(currency: string, asset: string): Observable<IRateResponse> {
    const headers = {[SKIP_LOADER_INTERCEPTOR.title]: SKIP_LOADER_INTERCEPTOR.value};

    return this.http.get<IResult<IRateResponse>>(`${this.host}/market/price?assetsTo=${currency}${asset}`, {headers}).pipe(map(response => response.result));
  }

  public getRatesForUserAccounts(currency: string, shortcodes: Pick<IWallet, 'asset'>[]): Observable<Record<string, IRateModel>> {
    const result = shortcodes.map(shortcode => `&assetsFrom=${shortcode.asset}`).join('');
    return this.getRates(currency, result).pipe(
      map(rates => {
        return mapValues(rates, value => ({
          rate: value[currency],
          asset: currency
        }));
      })
    );
  }

  public loadRate(asset: string): Observable<IRateModel> {
    return this.userStateFacade.currency$.pipe(
      switchMap(currency => {
        return this.getRates(currency, `&assetsFrom=${asset}`).pipe(map(rate => ({rate: rate[asset][currency], asset: currency})));
      }),
      take(1)
    );
  }

  public getWalletChart(asset: string, base: string, interval: ChartInterval, since: string): Observable<IWalletChart> {
    const headers = {[SKIP_LOADER_INTERCEPTOR.title]: SKIP_LOADER_INTERCEPTOR.value};

    return this.http
      .get<IResult<IWalletChartResponse[]>>(`${this.host}/market/chart?asset=${asset}&base=${base}&interval=${interval}&since=${since}`, {headers})
      .pipe(map(response => ({data: response.result, asset})));
  }

  public getWalletCharts(currency: string, shortcodes: Pick<IWallet, 'asset'>[], interval: ChartInterval, daysOffset: number): Observable<IWalletChart[]> {
    const charts$ = shortcodes.map(shortcode =>
      this.getWalletChart(shortcode.asset, currency, interval, getPreviousDateISO(daysOffset)).pipe(catchError(() => of(null)))
    );

    return forkJoin(charts$).pipe(
      map(walletCharts => {
        return walletCharts.filter(walletChart => {
          return walletChart && walletChart.data?.length;
        });
      })
    ) as unknown as Observable<IWalletChart[]>;
  }

  public getPriceChanges(currency: string, shortcodes: Pick<IWallet, 'asset'>[]): Observable<Record<string, IPriceChange>> {
    const headers = {[SKIP_LOADER_INTERCEPTOR.title]: SKIP_LOADER_INTERCEPTOR.value};

    const assets = shortcodes.map(shortcode => `&assetsFrom=${shortcode.asset}`).join('');
    return this.http.get<IResult<IRateResponse>>(`${this.host}/market/price-changes?assetsTo=${currency}${assets}`, {headers}).pipe(
      map(response => response.result),
      map(diffRates => {
        return mapValues(diffRates, value => ({
          diffRate: value[currency],
          asset: currency
        }));
      })
    );
  }
}
