<p-dropdown
  [options]="options"
  (onChange)="onChange($event)"
  [ngModel]="value"
  [optionValue]="optionValue"
  [optionLabel]="optionLabel"
  [filter]="isFilterEnable"
  [filterBy]="filterBy"
  [placeholder]="placeholder"
  [filterMatchMode]="filterMatchMode"
  [filterPlaceholder]="filterPlaceholder"
  [ngClass]="{'dropdown-invalid': isInvalid}"
  class="dropdown"
>
  <ng-template pTemplate="empty">
    <ng-container *ngIf="options && options.length; else emptyOptions">
      <p class="dropdown-field__empty-text text-left mb-0">{{ emptyMessage | translate }}</p>
    </ng-container>
    <ng-template #emptyOptions>
      <p class="dropdown-field__empty-text">{{ 'errors.went_wrong' | translate }}</p>
      <a class="dropdown-field__empty-link" href="#" (click)="onTryAgain($event)">{{ 'errors.try_again' | translate }}</a>
    </ng-template>
  </ng-template>

  <ng-template pTemplate="item" let-item>
    <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{$implicit: item}"></ng-container>
  </ng-template>

  <ng-template pTemplate="selectedItem" let-item>
    <ng-container [ngTemplateOutlet]="currentItemTemplate" [ngTemplateOutletContext]="{$implicit: item}"></ng-container>
  </ng-template>
</p-dropdown>

<ng-template #defaultItemTemplate let-item>
  {{ item[optionLabel] }}
</ng-template>

<ng-template #defaultCurrentItemTemplate let-item>
  {{ item[optionLabel] }}
</ng-template>

<ng-content></ng-content>
