import {assetType} from '../interfaces/asset.interface';

/**
 * @deprecated this dto will be removed in the future,
 * use {@link IWallet}
 */
export class WalletResponseDto {
  id: string;
  asset: string;
  iban: string;
  ibanStatus: IbanStatuses;
  address: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  assetId: string;
  title: string;
  contractAddress: string;
  baseCurrency: string;
  availableTopUpTypes: TopUpTypes[];
  availableTransferTypes: TopUpTypes[];
  decimals: number;
  color: string;
  icon: string;
  isExchangeSupported: boolean;
  assetType: assetType;
  isIbanSupported: boolean;
  isFavorite: boolean;
  filterBy?: string;
}

export enum TopUpTypes {
  WIRE = 'WIRE',
  CARD = 'CARD',
  CRYPTO = 'CRYPTO'
}

export enum IbanStatuses {
  EMPTY = 'EMPTY',
  PENDING = 'PENDING',
  SET = 'SET'
}
