import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {SKIP_AUTH_INTERCEPTOR} from '@wallex-core-client/core/constants/main-constants';
import {JwtTokenService} from '@wallex-core/services/jwt-token.service';
import {AuthService} from '@wallex-core/services/auth.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private tokenService: JwtTokenService, private router: Router, private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(null, (err: HttpErrorResponse) => {
        if (err.status === HttpStatusCode.Unauthorized && !req.headers.has(SKIP_AUTH_INTERCEPTOR.title)) {
          this.authService.logoutFromApp();
          return;
        }
      })
    );
  }
}
