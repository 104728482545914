<div class="header__overlay-user">
  <div class="d-flex align-items-center">
    <app-avatar [profileAvatar]="user?.profile?.profileImageLink" [isIndividualAccount]="false" width="40px" height="40px"></app-avatar>
    <div>
      <div class="user-name-and-kys-status">
        <p class="header__overlay-username">{{ currentOrganization?.legalName }}</p>
        <app-kyc-status></app-kyc-status>
      </div>
      <p class="header__overlay-email">{{ user?.email }}</p>
    </div>
  </div>
</div>
<app-links-overlay (toSettings)="toSettingsEvent.emit()" (openLogout)="openLogoutEvent.emit()"></app-links-overlay>
