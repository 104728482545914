import {createFeatureSelector} from '@ngrx/store';

import {ITransactionModel, IRequestedCryptoTransaction, RequestedFiatTransaction} from '@wallex-core-client/core/interfaces/transaction.interface';
import {IResponsePagination} from '@wallex-core-client/core/interfaces/api-response.interface';
import {APP_VERSION} from '@wallex-core-client/core/constants/main-constants';
import {getInitialState} from '@wallex-core-client/utils';

export const selectedWalletStateKey = 'selectedWallet';

export const emptyWalletState: ISelectedWalletState = {
  asset: '',
  walletId: null,
  requestedCryptoTransaction: null,
  requestedFiatTransaction: null,
  topUpWithCardUrl: null,
  transactions: {
    data: [],
    count: 0
  }
};

export interface ISelectedWalletState {
  readonly walletId: string | null;
  readonly asset: string;
  readonly topUpWithCardUrl: null | string;
  readonly requestedCryptoTransaction: IRequestedCryptoTransaction | null;
  readonly requestedFiatTransaction: RequestedFiatTransaction | null;
  readonly transactions: IResponsePagination<ITransactionModel[]>;
}

export const initialSelectedWalletState: ISelectedWalletState = getInitialState(APP_VERSION, selectedWalletStateKey) || emptyWalletState;

export const selectSelectedWalletState = createFeatureSelector<ISelectedWalletState>(selectedWalletStateKey);
