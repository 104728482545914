import {cloneDeep} from 'lodash-es';

import {blankWalletsState, initialWalletsState, IWalletsState} from '@wallex-core/store/wallets/wallets-state.model';
import {WalletsActionType} from '@wallex-core/store/wallets/wallets.actions';
import {NeoBankingAction} from '@wallex-core/store/action.model';

export function walletsReducer(state = initialWalletsState, action: NeoBankingAction): IWalletsState {
  switch (action.type) {
    case WalletsActionType.LOAD_WALLETS_SUCCESS:
      return {
        ...state,
        wallets: action.wallets,
        isWalletsLoadingFailure: false
      };

    case WalletsActionType.LOAD_WALLETS_FAILURE:
      return {
        ...state,
        wallets: [],
        isWalletsLoadingFailure: true
      };

    case WalletsActionType.ADD_WALLET: {
      return {
        ...state,
        wallets: [...state.wallets, action.wallet]
      };
    }

    case WalletsActionType.SET_TOTAL_BALANCE: {
      return {
        ...state,
        totalBalance: action.totalBalance
      };
    }

    case WalletsActionType.SET_WALLET_BALANCE: {
      const wallets = cloneDeep(state.wallets);
      const walletId = action.walletBalance.walletId;
      const walletBalance = action.walletBalance.balance;
      const foundWallet = wallets.find(wallet => wallet.id === walletId);
      if (foundWallet) foundWallet.balance = walletBalance;

      return {
        ...state,
        wallets
      };
    }

    case WalletsActionType.SET_SEGMENT_DISTRIBUTIONS: {
      return {
        ...state,
        segmentDistributions: action.segmentDistributions
      };
    }

    case WalletsActionType.TOGGLE_FAVORITE: {
      const wallets = cloneDeep(state.wallets);
      const affectedWalletIndex = wallets.findIndex(wallet => wallet.id === action.favoriteWallet.walletId);
      if (affectedWalletIndex !== -1) {
        wallets[affectedWalletIndex].isFavorite = action.favoriteWallet.isFavorite;
      }
      return {
        ...state,
        wallets
      };
    }

    case WalletsActionType.CLEAR_WALLETS: {
      return {...blankWalletsState};
    }

    default:
      return state;
  }
}
