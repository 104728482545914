import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {tap} from 'rxjs/operators';

import {
  IAvatarResponse,
  IBusinessAddress,
  IChangePassword,
  IDeletionUserResponse,
  IOrganization,
  IOrganizationTypes,
  IProfile,
  IProfilePayload,
  IUser,
  OrganizationTypesEnum
} from '@wallex-core-client/core/interfaces/user-profile.interface';
import {IEnvironment, IResult} from '@wallex-core-client/core/interfaces/common.interface';
import {IUserUpdateResponse} from '@wallex-core-client/core/interfaces/auth.interface';
import {ENVIRONMENT_TOKEN} from '@wallex-core-client/core/constants/main-constants';
import {IUserHash} from '@wallex-core-client/core/interfaces/user-hash.interface';
import {UserStateFacade} from '@wallex-core/store/user/user-state-facade.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient, private userStateFacade: UserStateFacade, @Inject(ENVIRONMENT_TOKEN) environment: IEnvironment) {
    this.host = environment.serverBaseUri;
  }

  private host!: string;

  public getUser(): Observable<IUser> {
    return this.http.get<IUser>(`${this.host}/user`).pipe(
      tap(user => {
        this.userStateFacade.addUser(user);
        this.userStateFacade.setKYCStatus(user.kycStatus);

        if (user.emailConfirmedAt) {
          this.userStateFacade.confirmEmail();
          return;
        }

        this.userStateFacade.clearEmailConfirmation();
      })
    );
  }

  public updateProfile(personalInfo: IProfilePayload): Observable<IProfile> {
    return this.http.patch<IProfile>(`${this.host}/profile`, personalInfo);
  }

  public updateLanguage(language: string): Observable<IProfile> {
    return this.http.patch<IProfile>(`${this.host}/user/language`, {language});
  }

  public uploadAvatar(payload: FormData): Observable<IAvatarResponse> {
    return this.http.post<IAvatarResponse>(`${this.host}/profile/file/upload`, payload);
  }

  public deleteAvatar(): Observable<IResult<boolean>> {
    return this.http.patch<IResult<boolean>>(`${this.host}/profile/file/delete`, {});
  }

  public getUserHash(): Observable<IUserHash> {
    return this.http.get<IUserHash>(`${this.host}/intercom/hash/web`);
  }

  public changePassword(passwordData: IChangePassword): Observable<IUserUpdateResponse> {
    return this.http.put<any>(`${this.host}/user/password`, passwordData);
  }

  public updateUserOrganization(payload: IBusinessAddress): Observable<IOrganization> {
    return this.http.patch<IOrganization>(`${this.host}/organization`, payload);
  }

  public getTypeOrganizations(type: OrganizationTypesEnum): Observable<IOrganizationTypes[]> {
    return this.http.get<IOrganizationTypes[]>(`${this.host}/organization/business/list/${type}`);
  }

  public requestToDeleteUser(): Observable<IDeletionUserResponse> {
    return this.http.post<IDeletionUserResponse>(`${this.host}/user/deletion-request`, {});
  }

  public formatDate(value: string): string {
    const date = value.split('/');
    const day = date[0];
    const month = date[1];
    const year = date[2];
    return `${year}-${month}-${day}`;
  }

  public formatBirthDateForInput(value: string): string {
    const date = new Date(value);
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const monthNumber = date.getMonth() + 1;
    const month = monthNumber < 10 ? `0${monthNumber}` : monthNumber;
    return `${day}/${month}/${date.getFullYear()}`;
  }
}
