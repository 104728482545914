import {createFeatureSelector} from '@ngrx/store';

import {APP_VERSION} from '@wallex-core-client/core/constants/main-constants';
import {INew} from '@wallex-core-client/core/interfaces/news.interface';
import {getInitialState} from '@wallex-core-client/utils';

export const newsStateKey = 'news';

export interface INewsState {
  readonly news: INew[] | null;
}

export const blankNewsState = {
  news: null
};

export const initialNewsState: INewsState = getInitialState(APP_VERSION, newsStateKey) || blankNewsState;

export const selectNewsState = createFeatureSelector<INewsState>(newsStateKey);
