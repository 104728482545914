<div class="header__overlay-additional-solutions">
  <div
    *ngFor="let solution of this.additionalSolutions; index as i"
    class="d-flex align-items-center solution-item"
    (click)="openUrl(solution)"
    [ngClass]="{separator: i === indexSeparatorNumber}"
  >
    <img class="additional-solutions__icon" [src]="getSolutionIcon(solution)" />
    <div>
      <p class="additional-solutions__header">{{ getSolutionHeader(solution) | translate }}</p>
      <p class="additional-solutions__subheader">{{ getSolutionHeader(solution, true) | translate }}</p>
    </div>
  </div>
</div>
