import {Component, ChangeDetectionStrategy, Input, HostBinding, forwardRef, ChangeDetectorRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true
    }
  ]
})
export class RadioButtonComponent implements ControlValueAccessor {
  @HostBinding('class.radio') className: boolean = true;
  @Input() name!: string;
  @Input() inputId!: string;
  @Input() value!: string;
  @Input() isChecked: boolean = false;

  public isDisabled!: boolean;
  public changed!: (value: string) => void;
  public touched!: () => void;

  constructor(private cd: ChangeDetectorRef) {}

  public onChange(event: Event): void {
    const value: string = (<HTMLInputElement>event.target).value;
    this.changed(value);
  }

  public writeValue(value: string): void {
    this.isChecked = this.value === value;
    this.cd.detectChanges();
  }

  public registerOnChange(fn: any): void {
    this.changed = fn;
  }

  public registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.cd.detectChanges();
  }
}
