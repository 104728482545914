import {Pipe, PipeTransform} from '@angular/core';

import {assetSuffixes} from '@wallex-core-client/utils';

@Pipe({
  name: 'assetCodeBrief'
})
export class AssetCodeBriefPipe implements PipeTransform {
  transform(coinCode: string, isCoinCodeCropped = true): string {
    if (!coinCode) {
      return '';
    }
    if (!isCoinCodeCropped) return coinCode;
    let fixedCoinCode = coinCode;
    Object.values(assetSuffixes).forEach(value => {
      if (fixedCoinCode.includes(value)) {
        fixedCoinCode = fixedCoinCode.replace(value, '');
      }
    });
    return fixedCoinCode;
  }
}
