import {Action} from '@ngrx/store';

import {IAsset} from '@wallex-core-client/core/interfaces/asset.interface';

export enum AssetsActionType {
  LOAD_ASSETS = '[Assets] Load assets',
  LOAD_ASSETS_SUCCESS = '[Assets] Assets loaded successfully',
  LOAD_ASSETS_FAILURE = '[Assets] Assets failed to load',
  CLEAR_ASSETS = '[Assets] Clear Assets'
}

export class LoadAssets implements Action {
  readonly type = AssetsActionType.LOAD_ASSETS;

  constructor() {}
}

export class LoadAssetsSuccess implements Action {
  readonly type = AssetsActionType.LOAD_ASSETS_SUCCESS;

  constructor(readonly assets: IAsset[]) {}
}

export class LoadAssetsFailure implements Action {
  readonly type = AssetsActionType.LOAD_ASSETS_FAILURE;
}

export class ClearAssets implements Action {
  readonly type = AssetsActionType.CLEAR_ASSETS;
}

export type AssetsAction = LoadAssets | LoadAssetsSuccess | LoadAssetsFailure | ClearAssets;
