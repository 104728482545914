import {Action} from '@ngrx/store';

import {IMarketDetails, IMarketList, IRateModel, IWalletChart} from '@wallex-core-client/core/interfaces/rate.interface';
import {IWallet} from '@wallex-core-client/core/interfaces/wallet.interface';
import {IAsset} from '@wallex-core-client/core/interfaces/asset.interface';

export enum RatesActionType {
  LOAD_RATES = '[Rates] Set rates',
  LOAD_RATES_SUCCESS = '[Rates] Rates loaded successfully',
  LOAD_RATES_FAILURE = '[Rates] Rates failed to load',
  LOAD_WALLET_CHARTS = '[Rates] Load wallet charts',
  LOAD_WALLET_CHARTS_SUCCESS = '[Rates] Wallet charts loaded successfully',
  LOAD_WALLET_CHARTS_FAILURE = '[Rates] Wallet charts loaded failure',
  LOAD_WALLET_CHART = '[Rates] Load wallet chart',
  LOAD_WALLET_CHART_SUCCESS = '[Rates] Wallet chart loaded successfully',
  LOAD_WALLET_CHART_FAILURE = '[Rates] Wallet chart loaded failure',
  LOAD_MARKET_LIST = '[Rates] Load market list',
  LOAD_MARKET_LIST_SUCCESS = '[Rates] Load market list successfully',
  LOAD_MARKET_LIST_FAILURE = '[Rates] Load market list failure',
  LOAD_MARKET_DETAILS = '[Rates] Load market details',
  LOAD_MARKET_DETAILS_SUCCESS = '[Rates] Load market details successfully',
  LOAD_MARKET_DETAILS_FAILURE = '[Rates] Load market details failure',
  SET_RATE_FOR_ACCOUNT = '[Rates] Set rate for account',
  CLEAR_RATES = '[Rates] Clear rates'
}

export class LoadRates implements Action {
  readonly type = RatesActionType.LOAD_RATES;

  constructor() {}
}

export class LoadRatesSuccess implements Action {
  readonly type = RatesActionType.LOAD_RATES_SUCCESS;

  constructor(readonly rates: Record<string, IRateModel>) {}
}

export class LoadRatesFailure implements Action {
  readonly type = RatesActionType.LOAD_RATES_FAILURE;

  constructor() {}
}

export class LoadWalletCharts implements Action {
  readonly type = RatesActionType.LOAD_WALLET_CHARTS;

  constructor() {}
}

export class LoadWalletChartsFailure implements Action {
  readonly type = RatesActionType.LOAD_WALLET_CHARTS_FAILURE;

  constructor() {}
}

export class LoadWalletChartsSuccess implements Action {
  readonly type = RatesActionType.LOAD_WALLET_CHARTS_SUCCESS;

  constructor(readonly walletCharts: IWalletChart[]) {}
}

export class LoadWalletChart implements Action {
  readonly type = RatesActionType.LOAD_WALLET_CHART;

  constructor(readonly wallet: IWallet) {}
}

export class LoadWalletChartSuccess implements Action {
  readonly type = RatesActionType.LOAD_WALLET_CHART_SUCCESS;

  constructor(readonly walletChart: IWalletChart) {}
}

export class LoadWalletChartFailure implements Action {
  readonly type = RatesActionType.LOAD_WALLET_CHART_FAILURE;

  constructor() {}
}

export class SetRateForAccount implements Action {
  readonly type = RatesActionType.SET_RATE_FOR_ACCOUNT;

  constructor(readonly asset: string, readonly rates: IRateModel) {}
}

export class LoadMarketList implements Action {
  readonly type = RatesActionType.LOAD_MARKET_LIST;

  constructor(readonly assets: IAsset[]) {}
}

export class LoadMarketListSuccess implements Action {
  readonly type = RatesActionType.LOAD_MARKET_LIST_SUCCESS;

  constructor(readonly marketList: IMarketList[]) {}
}

export class LoadMarketListFailure implements Action {
  readonly type = RatesActionType.LOAD_MARKET_LIST_FAILURE;

  constructor() {}
}

export class LoadMarketDetails implements Action {
  readonly type = RatesActionType.LOAD_MARKET_DETAILS;

  constructor(readonly currency: string, readonly asset: IAsset) {}
}

export class LoadMarketDetailsSuccess implements Action {
  readonly type = RatesActionType.LOAD_MARKET_DETAILS_SUCCESS;

  constructor(readonly marketDetails: IMarketDetails) {}
}

export class LoadMarketDetailsFailure implements Action {
  readonly type = RatesActionType.LOAD_MARKET_DETAILS_FAILURE;

  constructor() {}
}

export class ClearRates implements Action {
  readonly type = RatesActionType.CLEAR_RATES;

  constructor() {}
}

export type RatesAction =
  | LoadRates
  | LoadRatesSuccess
  | LoadRatesFailure
  | LoadWalletCharts
  | LoadWalletChart
  | LoadWalletChartSuccess
  | LoadWalletChartsSuccess
  | SetRateForAccount
  | LoadMarketList
  | LoadMarketListSuccess
  | LoadMarketListFailure
  | LoadMarketDetails
  | LoadMarketDetailsSuccess
  | LoadMarketDetailsFailure
  | ClearRates;
