import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {enableProdMode} from '@angular/core';

import {init} from '@sentry/angular';

import {environment} from './environments/environment';
import {AppModule} from './app/app.module';

if (environment.sentryDsn) {
  init({
    dsn: environment.sentryDsn,
    environment: environment.production ? 'production' : 'stage'
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
