import {IBanner} from '@wallex-core-client/core/interfaces/banner.interface';

export const bannerSkeleton: IBanner = {
  id: '0',
  bannerUrl: `../../../assets/default-banner.svg`,
  name: '',
  visualisationPosition: 1
};

export const defaultBanners: IBanner[] = Array(6).fill(bannerSkeleton);
