import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DialogService} from 'primeng/dynamicdialog';
import {Observable, Subject, takeUntil} from 'rxjs';
import {OverlayPanel} from 'primeng/overlaypanel';
import {Router} from '@angular/router';

import {MessageService} from 'primeng/api';

import {CookieService} from 'ngx-cookie-service';

import {map} from 'rxjs/operators';

import {ChangeLanguageDialogComponent} from '@wallex-core/features/dashboard/header/change-language-dialog/change-language-dialog.component';
import {defaultPopupConfig, ENVIRONMENT_TOKEN, LOCALE_COOKIE_KEY} from '@wallex-core-client/core/constants/main-constants';
import {IEnvironment, ILanguage} from '@wallex-core-client/core/interfaces/common.interface';
import {AssetsStateFacade} from '@wallex-core/store/assets/assets-state-facade.service';
import {UserStateFacade} from '@wallex-core/store/user/user-state-facade.service';
import {AppStateFacade} from '@wallex-core/store/app/app-state-facade.service';
import {assetType} from '@wallex-core-client/core/interfaces/asset.interface';
import {KycStatus} from '@wallex-core-client/core/interfaces/auth.interface';
import {AuthService} from '@wallex-core/services/auth.service';
import {DefaultDialogComponent} from '@wallex-core-client/ui';
import {KycService} from '@wallex-core/services/kyc.service';
import {Routes} from '@wallex-core/constants/routes.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService]
})
export class HeaderComponent implements OnInit, OnDestroy {
  public title: string;
  public kycStatus: string;
  public kycStatuses = KycStatus;
  public routes = Routes;
  public isShowInfoHint = false;
  private notifier = new Subject<void>();
  public isBuyCryptoLinkEnabled = false;
  public isAdditionalSolutionsMenuShown = false;

  @Input() isMobileMenuOpened = false;
  @Output() openMobileMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get currentLanguage(): Observable<ILanguage> {
    return this.appStateFacade.locales$.pipe(
      map(locales => {
        return locales.find(lang => lang.code === this.cookieService.get(LOCALE_COOKIE_KEY))!;
      })
    );
  }

  constructor(
    private router: Router,
    public userStateFacade: UserStateFacade,
    private kycService: KycService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    public appStateFacade: AppStateFacade,
    private dialogService: DialogService,
    private translate: TranslateService,
    private messageService: MessageService,
    private cookieService: CookieService,
    public assetsStateFacade: AssetsStateFacade,
    @Inject(ENVIRONMENT_TOKEN) environment: IEnvironment
  ) {
    if (environment.isAdditionalSolutionsMenuShown) {
      this.isAdditionalSolutionsMenuShown = true;
    }
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  private initSubscriptions(): void {
    this.userStateFacade.KYCStatus$.pipe(takeUntil(this.notifier)).subscribe(kycStatus => {
      this.kycStatus = kycStatus;
      this.cdr.detectChanges();
    });

    this.router.events.pipe(takeUntil(this.notifier)).subscribe(() => {
      this.appStateFacade.fieldChanged$.next(false);
      this.appStateFacade.isToastShown$.next(false);
    });

    this.appStateFacade.fieldChanged$.pipe(takeUntil(this.notifier)).subscribe(isHeaderMessageShown => {
      if (!isHeaderMessageShown || window.innerWidth >= 900) return;
      this.showToast();
    });

    this.appStateFacade.isToastShown$.pipe(takeUntil(this.notifier)).subscribe(isToastShown => {
      if (!isToastShown) return;
      this.showToast();
    });
  }

  private showToast() {
    this.messageService.add({
      severity: 'info',
      detail: this.appStateFacade.changeStatusText,
      sticky: false
    });
  }

  public toSettings(overlay: OverlayPanel): void {
    overlay.hide();
    this.router.navigate([this.routes.settings]).catch(() => null);
  }

  public openLogout(overlay: OverlayPanel): void {
    overlay.hide();

    const ref = this.dialogService.open(DefaultDialogComponent, {
      ...defaultPopupConfig(this.translate.instant('header.logout_dialog.title')),
      data: {
        message: this.translate.instant('header.logout_dialog.text'),
        okayButtonLabel: this.translate.instant('header.logout_dialog.confirm')
      }
    });

    ref.onClose.subscribe(isConfirm => {
      if (isConfirm) this.authService.logoutFromApp();
    });
  }

  public onChangeLanguage(): void {
    this.dialogService.open(ChangeLanguageDialogComponent, {
      ...defaultPopupConfig('Language', true)
    });
  }

  public initKyc(kycStatus: string): void {
    if (kycStatus === this.kycStatuses.approved) return;

    this.kycService.initKYCOnly();
  }

  public onOpenMenu(): void {
    this.openMobileMenu.emit(!this.isMobileMenuOpened);
  }

  public toDashboard(): void {
    void this.router.navigate([this.routes.dashboard]);
    this.isMobileMenuOpened && this.openMobileMenu.emit(false);
  }

  public toExchange(): void {
    void this.router.navigate([Routes.exchange]);
  }

  public toSwap(): void {
    void this.router.navigate([Routes.exchange], {queryParams: {byAssetType: assetType.crypto, title: this.translate.instant('main_screen.swap')}});
  }

  public openBuyCrypto() {
    this.router.navigate([Routes.buyCrypto]).catch(err => console.log(err));
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }
}
