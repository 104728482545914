export const stringToNumber = (value: string | number): number => {
  const result = +String(value).replace(',', '.');

  if (isNaN(result)) return 0;

  return result;
};

export const numberToString = (value: number | string): string => {
  return String(value).replace('.', ',');
};

export const commaToDot = (value: string | number): string => {
  return String(value).replace(',', '.');
};

export const dotToComma = (value: string | number): string => {
  return String(value).replace('.', ',');
};
