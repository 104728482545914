import {Action} from '@ngrx/store';

import {IBanner} from '@wallex-core-client/core/interfaces/banner.interface';

export enum BannersActionType {
  LOAD_MAIN_SCREEN_BANNERS = '[Banners] Load main screen banners',
  LOAD_MAIN_SCREEN_BANNERS_SUCCESS = '[Banners] Load main screen banners success',
  LOAD_MAIN_SCREEN_BANNERS_FAILURE = '[Banners] Load main screen banners failure',
  DELETE_BANNERS_FROM_MAIN_SCREEN = '[Banners] Delete banner from main screen',
  ADD_BANNERS_TO_AUTH_SCREEN = '[Banners] Add banners to auth screen',
  DELETE_BANNERS_FROM_AUTH_SCREEN = '[Banners] Delete banner from auth screen',
  DELETE_ALL_BANNERS = '[Banners] Delete all banners'
}

export class LoadMainScreenBanners implements Action {
  readonly type = BannersActionType.LOAD_MAIN_SCREEN_BANNERS;

  constructor() {}
}

export class LoadMainScreenBannersSuccess implements Action {
  readonly type = BannersActionType.LOAD_MAIN_SCREEN_BANNERS_SUCCESS;

  constructor(readonly banners: IBanner[]) {}
}

export class LoadMainScreenBannersFailure implements Action {
  readonly type = BannersActionType.LOAD_MAIN_SCREEN_BANNERS_FAILURE;

  constructor() {}
}

export class DeleteBannerFromMainScreen implements Action {
  readonly type = BannersActionType.DELETE_BANNERS_FROM_MAIN_SCREEN;

  constructor(readonly id: number) {}
}

export class AddBannersToAuthScreen implements Action {
  readonly type = BannersActionType.ADD_BANNERS_TO_AUTH_SCREEN;

  constructor(readonly banners: IBanner[]) {}
}

export class DeleteBannerFromAuthScreen implements Action {
  readonly type = BannersActionType.DELETE_BANNERS_FROM_AUTH_SCREEN;

  constructor(readonly id: number) {}
}

export class DeleteAllBanners implements Action {
  readonly type = BannersActionType.DELETE_ALL_BANNERS;

  constructor() {}
}

export type BannersAction =
  | LoadMainScreenBanners
  | LoadMainScreenBannersSuccess
  | LoadMainScreenBannersFailure
  | AddBannersToAuthScreen
  | DeleteBannerFromMainScreen
  | DeleteBannerFromAuthScreen
  | DeleteAllBanners;
