import {shareReplay} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';

import {INewsState, selectNewsState} from '@wallex-core/store/news/news-state.model';
import {ClearNewsAction, UpdateNews} from '@wallex-core/store/news/news.actions';
import {INew} from '@wallex-core-client/core/interfaces/news.interface';

@Injectable({
  providedIn: 'root'
})
export class NewsStateFacade {
  constructor(private readonly store$: Store<INewsState>) {}

  private readonly state$ = this.store$.pipe(select(selectNewsState));

  readonly news$ = this.state$.pipe(
    select(state => state.news),
    shareReplay(1)
  );

  public updateNews(news: INew[]): void {
    this.store$.dispatch(new UpdateNews(news));
  }

  public clearNews(): void {
    this.store$.dispatch(new ClearNewsAction());
  }
}
