import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {InfiniteScrollComponent} from '@wallex-core-client/ui';

@NgModule({
  declarations: [InfiniteScrollComponent],
  imports: [CommonModule],
  exports: [InfiniteScrollComponent]
})
export class InfiniteScrollModule {}
