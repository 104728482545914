import {NgModule} from '@angular/core';

import {
  CountryNamePipeModule,
  CropNumberPipeModule,
  CryptoToFiatPipeModule,
  DiffDatePipeModule,
  DigitsAfterCommaPipeModule,
  FilterPipeModule,
  DotToCommaPipeModule,
  PlusNumbersPipeModule,
  FormatDatePipeModule,
  ImgSrcToBackgroundPipeModule,
  ToArrayPipeModule,
  NumberToStringPipeModule,
  MinuteSecondsLeftPipeModule,
  SafeHtmlPipeModule,
  StringToNumberPipeModule,
  TransactionPipeModule,
  SavingPipesModule,
  NumberLimitPipeModule,
  NotificationPipeModule,
  NumberFrPipeModule
} from '@wallex-core-client/pipes';

@NgModule({
  imports: [
    CountryNamePipeModule,
    CropNumberPipeModule,
    CryptoToFiatPipeModule,
    DiffDatePipeModule,
    DigitsAfterCommaPipeModule,
    DotToCommaPipeModule,
    FilterPipeModule,
    FormatDatePipeModule,
    ImgSrcToBackgroundPipeModule,
    ToArrayPipeModule,
    NumberToStringPipeModule,
    PlusNumbersPipeModule,
    MinuteSecondsLeftPipeModule,
    SafeHtmlPipeModule,
    StringToNumberPipeModule,
    TransactionPipeModule,
    SavingPipesModule,
    NumberLimitPipeModule,
    NotificationPipeModule,
    NumberFrPipeModule
  ],
  exports: [
    CountryNamePipeModule,
    CropNumberPipeModule,
    CryptoToFiatPipeModule,
    DiffDatePipeModule,
    DigitsAfterCommaPipeModule,
    DotToCommaPipeModule,
    FilterPipeModule,
    FormatDatePipeModule,
    ImgSrcToBackgroundPipeModule,
    ToArrayPipeModule,
    NumberToStringPipeModule,
    PlusNumbersPipeModule,
    MinuteSecondsLeftPipeModule,
    SafeHtmlPipeModule,
    StringToNumberPipeModule,
    TransactionPipeModule,
    SavingPipesModule,
    NumberLimitPipeModule,
    NotificationPipeModule,
    NumberFrPipeModule
  ]
})
export class PipesModule {}
