import {Action} from '@ngrx/store';

import {IOrganization, IProfile, IUser} from '@wallex-core-client/core/interfaces/user-profile.interface';
import {ITransactionModel} from '@wallex-core-client/core/interfaces/transaction.interface';
import {ITwoFaQrCodeResponse} from '@wallex-core-client/core/interfaces/2fa.interface';
import {KycStatus} from '@wallex-core-client/core/interfaces/auth.interface';

export enum UserActionType {
  ADD_USER = '[User] Add User',
  ADD_USER_PROFILE = '[User] Add User Profile',
  UPDATE_USER_PROFILE = '[User] Update User Profile',
  CLEAR_USER_STATE = '[User] Clear User State',
  SET_CONFIRMATION_CODE_VALIDITY_TIME_STAMP = '[User] Set Confirmation Code Validity Time Stamp',
  SET_KYC_STATUS = '[User] Set KYC Status',
  SET_EMAIL_CONFIRMATION = '[User] Set Email Confirmation',
  CLEAR_EMAIL_CONFIRMATION = '[User] Clear email confirmation',
  UPDATE_TWO_FA_REQUEST_PAYLOAD = '[User] Update Two Fa Request Payload',
  UPDATE_TWO_FA_QR_CODE = '[User] Update Two Fa Qr Code',
  UPDATE_TWO_FA_PENDING = '[User] Update Two Fa Pending',
  UPDATE_ORGANIZATION = '[User] Update Organization',
  SET_IS_USER_DELETION_REQUESTED = '[User] Set Is User Deletion Requested',
  LOAD_LAST_TRANSACTIONS = '[User] Load Last Transactions',
  LOAD_LAST_TRANSACTIONS_FAILURE = '[User] Load Last Transactions Failure',
  SET_LAST_TRANSACTIONS = '[User] Set Last Transactions',
  UPLOAD_AVATAR = '[User] Upload Avatar',
  UPLOAD_AVATAR_SUCCESS = '[User] Upload Avatar Success',
  UPLOAD_AVATAR_FAILURE = '[User] Upload Avatar Failure',
  DELETE_AVATAR = '[User] Delete Avatar',
  DELETE_AVATAR_SUCCESS = '[User] Delete Avatar Success',
  DELETE_AVATAR_FAILURE = '[User] Delete Avatar Failure',
  SET_REFERRAL_CODE_ACTIVATED = '[User] Set Referral Code Activated'
}

export class AddUser implements Action {
  readonly type = UserActionType.ADD_USER;

  constructor(readonly user: IUser) {}
}

export class UpdateUserProfile implements Action {
  readonly type = UserActionType.UPDATE_USER_PROFILE;

  constructor(readonly profile: IProfile) {}
}

export class AddUserProfile implements Action {
  readonly type = UserActionType.ADD_USER_PROFILE;

  constructor(readonly profile: IProfile) {}
}

export class ClearUserState implements Action {
  readonly type = UserActionType.CLEAR_USER_STATE;

  constructor() {}
}

export class SetConfirmationCodeValidityTimeStamp implements Action {
  readonly type = UserActionType.SET_CONFIRMATION_CODE_VALIDITY_TIME_STAMP;

  constructor(readonly timeStamp: number) {}
}

export class SetKYCStatus implements Action {
  readonly type = UserActionType.SET_KYC_STATUS;

  constructor(readonly status: KycStatus) {}
}

export class SetEmailConfirmation implements Action {
  readonly type = UserActionType.SET_EMAIL_CONFIRMATION;

  constructor() {}
}

export class ClearEmailConfirmation implements Action {
  readonly type = UserActionType.CLEAR_EMAIL_CONFIRMATION;

  constructor() {}
}

export class UpdateTwoFaRequestPayload implements Action {
  readonly type = UserActionType.UPDATE_TWO_FA_REQUEST_PAYLOAD;

  constructor(readonly twoFaRequestPayload: null | any) {}
}

export class UpdateTwoFaQrCode implements Action {
  readonly type = UserActionType.UPDATE_TWO_FA_QR_CODE;

  constructor(readonly twoFaQrCode: ITwoFaQrCodeResponse) {}
}

export class UpdateTwoFaPending implements Action {
  readonly type = UserActionType.UPDATE_TWO_FA_PENDING;

  constructor(readonly twoFaPending: boolean) {}
}

export class UpdateOrganization implements Action {
  readonly type = UserActionType.UPDATE_ORGANIZATION;

  constructor(readonly currentOrganization: IOrganization) {}
}

export class SetIsUserDeletionRequested implements Action {
  readonly type = UserActionType.SET_IS_USER_DELETION_REQUESTED;

  constructor(readonly date: string) {}
}

export class LoadLastTransactionsAction implements Action {
  readonly type = UserActionType.LOAD_LAST_TRANSACTIONS;
}

export class LoadLastTransactionsFailure implements Action {
  readonly type = UserActionType.LOAD_LAST_TRANSACTIONS_FAILURE;

  constructor() {}
}

export class SetLastTransactionsAction implements Action {
  readonly type = UserActionType.SET_LAST_TRANSACTIONS;

  constructor(readonly lastTransactions: ITransactionModel[]) {}
}

export class UploadAvatarAction implements Action {
  readonly type = UserActionType.UPLOAD_AVATAR;

  constructor(readonly formData: FormData) {}
}

export class UploadAvatarSuccessAction implements Action {
  readonly type = UserActionType.UPLOAD_AVATAR_SUCCESS;

  constructor(readonly avatarLink: string) {}
}

export class UploadAvatarFailureAction implements Action {
  readonly type = UserActionType.UPLOAD_AVATAR_FAILURE;

  constructor() {}
}

export class DeleteAvatarAction implements Action {
  readonly type = UserActionType.DELETE_AVATAR;

  constructor() {}
}

export class DeleteAvatarSuccessAction implements Action {
  readonly type = UserActionType.DELETE_AVATAR_SUCCESS;

  constructor() {}
}

export class DeleteAvatarFailureAction implements Action {
  readonly type = UserActionType.DELETE_AVATAR_FAILURE;

  constructor() {}
}

export class SetReferralCodeActivated implements Action {
  readonly type = UserActionType.SET_REFERRAL_CODE_ACTIVATED;

  constructor() {}
}

export type UserAction =
  | AddUser
  | AddUserProfile
  | ClearUserState
  | UpdateUserProfile
  | SetConfirmationCodeValidityTimeStamp
  | SetKYCStatus
  | SetEmailConfirmation
  | UpdateTwoFaRequestPayload
  | ClearEmailConfirmation
  | UpdateTwoFaQrCode
  | UpdateTwoFaPending
  | UpdateOrganization
  | SetIsUserDeletionRequested
  | LoadLastTransactionsAction
  | LoadLastTransactionsFailure
  | SetLastTransactionsAction
  | UploadAvatarAction
  | UploadAvatarSuccessAction
  | UploadAvatarFailureAction
  | DeleteAvatarAction
  | DeleteAvatarSuccessAction
  | DeleteAvatarFailureAction
  | SetReferralCodeActivated;
