import {MessagePayload} from '@angular/fire/messaging';
import {createFeatureSelector} from '@ngrx/store';

import {ITransactionDetails} from '@wallex-core-client/core/interfaces/transaction.interface';
import {NotificationDto} from '@wallex-core-client/core/dtos/notifications.dto';
import {APP_VERSION} from '@wallex-core-client/core/constants/main-constants';
import {getInitialState} from '@wallex-core-client/utils';

export const notificationsStateKey = 'notifications';

export interface INotificationsState {
  readonly unreadTotalNumber: number;
  readonly unreadMessagesNumber: number;
  readonly unreadTransactionNotificationsNumber: number;
  readonly pushNotificationWithTransactionEvent: MessagePayload | null;
  readonly pushNotificationWithMessage: MessagePayload | null;
  readonly pushToken: string;
}

export const blankNotificationsState: INotificationsState = {
  unreadTotalNumber: 0,
  unreadMessagesNumber: 0,
  unreadTransactionNotificationsNumber: 0,
  pushNotificationWithTransactionEvent: null,
  pushNotificationWithMessage: null,
  pushToken: ''
};

export const initialNotificationsState: INotificationsState = getInitialState(APP_VERSION, notificationsStateKey) || blankNotificationsState;

export const selectNotificationsState = createFeatureSelector<INotificationsState>(notificationsStateKey);
