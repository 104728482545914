import {filter, map, shareReplay, switchMap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {select, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {isNil} from 'lodash-es';

import {combineLatest} from 'rxjs';

import {
  AddUser,
  AddUserProfile,
  ClearEmailConfirmation,
  ClearUserState,
  DeleteAvatarAction,
  LoadLastTransactionsAction,
  SetConfirmationCodeValidityTimeStamp,
  SetEmailConfirmation,
  SetIsUserDeletionRequested,
  SetKYCStatus,
  SetReferralCodeActivated,
  UpdateOrganization,
  UpdateTwoFaPending,
  UpdateTwoFaQrCode,
  UpdateTwoFaRequestPayload,
  UpdateUserProfile,
  UploadAvatarAction
} from '@wallex-core/store/user/user.actions';
import {IOrganization, IProfile, IUser} from '@wallex-core-client/core/interfaces/user-profile.interface';
import {SettingsStateFacade} from '@wallex-core/store/settings/settings-state-facade.service';
import {ITwoFaQrCodeResponse} from '@wallex-core-client/core/interfaces/2fa.interface';
import {IUserState, selectUserState} from '@wallex-core/store/user/user-state.model';
import {ICountry} from '@wallex-core-client/core/interfaces/country.interface';
import {KycStatus} from '@wallex-core-client/core/interfaces/auth.interface';

@Injectable({
  providedIn: 'root'
})
export class UserStateFacade {
  constructor(
    private readonly store$: Store<IUserState>,
    private readonly translate: TranslateService,
    private readonly settingsStateFacade: SettingsStateFacade
  ) {}

  readonly intervalInMilliseconds = 1000;

  private readonly state$ = this.store$.pipe(select(selectUserState));

  readonly isPersonalInfoSpecified$ = this.state$.pipe(
    select(state => state.isPersonalInfoSpecified),
    shareReplay(1)
  );

  readonly user$ = this.state$.pipe(
    select(state => state.user),
    shareReplay(1)
  );

  readonly userProfile$ = this.state$.pipe(
    select(state => state.user.profile),
    shareReplay(1)
  );

  readonly individualAccount$ = this.user$.pipe(
    select(user => user.individualAccount),
    shareReplay(1)
  );

  readonly individualAccountFullAddress$ = this.individualAccount$.pipe(
    map(individualAccount =>
      individualAccount && individualAccount.legalCity && individualAccount.legalAddress
        ? `${individualAccount.legalCity}, ${individualAccount.legalAddress}`
        : ''
    ),
    shareReplay(1)
  );

  readonly profileAvatar$ = this.userProfile$.pipe(
    select(userProfile => userProfile.profileImageLink),
    shareReplay(1)
  );

  readonly isIndividualAccount$ = this.user$.pipe(
    select(user => !!user.individualAccId),
    shareReplay(1)
  );

  readonly isBusinessAccount$ = this.user$.pipe(
    select(user => !user.individualAccId),
    shareReplay(1)
  );

  readonly businessAccounts$ = this.user$.pipe(
    select(user => user.businessAccounts),
    shareReplay(1)
  );

  readonly currentOrganization$ = this.state$.pipe(
    select(state => state.currentOrganization),
    shareReplay(1)
  );

  readonly currentOrganizationLegalCountry$ = combineLatest([this.currentOrganization$, this.settingsStateFacade.profileCountries$]).pipe(
    filter(([currentOrganization, profileCountries]) => !!currentOrganization && !!profileCountries.length),
    map(([currentOrganization, profileCountries]) => profileCountries.find((country: ICountry) => country.code === currentOrganization!.legalCountryCode)),
    shareReplay(1)
  );

  readonly currency$ = this.userProfile$.pipe(
    select(profile => profile?.currency),
    shareReplay(1)
  );

  readonly isDeletionRequested$ = this.user$.pipe(
    select(user => !!user.deletionRequestedAt),
    shareReplay(1)
  );

  readonly isTwoFaEnabled$ = this.user$.pipe(select(user => user.isTwoFactorAuthEnabled));

  readonly isTwoFaEnabledTranslated$ = this.isTwoFaEnabled$.pipe(
    map(isTwoFaEnabled => {
      if (isTwoFaEnabled) return this.translate.instant('2fa.activated');
      return this.translate.instant('2fa.off');
    })
  );

  readonly currencySign$ = this.currency$.pipe(
    switchMap(currency =>
      this.settingsStateFacade.currencies$.pipe(
        map(currencies => {
          const resultCurrency = currencies.find((item: any) => item.shortCode === currency);
          if (resultCurrency) return resultCurrency.symbol;
          return '';
        })
      )
    ),
    shareReplay(1)
  );

  readonly confirmationCodeValidityTimestamp$ = this.state$.pipe(
    select(state => state.canResendConfirmationCodeAfter),
    shareReplay(1)
  );

  readonly KYCStatus$ = this.state$.pipe(
    select(state => state.KYCStatus),
    shareReplay(1)
  );

  readonly isKYCVerificationCompleted$ = this.KYCStatus$.pipe(
    map(status => status === KycStatus.approved),
    shareReplay(1)
  );

  readonly KYCStatusTranslated$ = this.KYCStatus$.pipe(
    map(kycStatus => {
      switch (kycStatus) {
        case KycStatus.approved:
          return this.translate.instant(`kycStatuses.verified`);
        case KycStatus.unverified:
          return this.translate.instant(`kycStatuses.unverified`);
        case KycStatus.pending:
          return this.translate.instant(`kycStatuses.pending`);
        case KycStatus.rejected:
          return this.translate.instant(`kycStatuses.rejected`);
      }
    }),
    shareReplay(1)
  );

  readonly confirmationExpiryTimeout$ = this.confirmationCodeValidityTimestamp$.pipe(
    map((expiryDateTimeStamp: number) => {
      if (isNil(expiryDateTimeStamp)) {
        return null;
      }
      const expiryDate = new Date(expiryDateTimeStamp);
      const dateNow = new Date();
      return Math.ceil((expiryDate.getTime() - dateNow.getTime()) / this.intervalInMilliseconds);
    })
  );

  readonly isEmailConfirmed$ = this.state$.pipe(
    select(state => state.isEmailConfirmed),
    shareReplay(1)
  );

  readonly twoFaRequestPayload$ = this.state$.pipe(select(state => state.twoFaRequestPayload));

  readonly twoFaQrCode$ = this.state$.pipe(select(state => state.twoFaQrCode));

  readonly twoFaPending$ = this.state$.pipe(
    select(state => state.twoFaPending),
    shareReplay(1)
  );

  readonly lastTransactions$ = this.state$.pipe(select(state => state.lastTransactions));

  readonly isReferralCodeActivated$ = this.state$.pipe(
    select(state => state.isReferralCodeActivated),
    shareReplay(1)
  );

  public updateUserProfile(userProfile: IProfile): void {
    this.store$.dispatch(new UpdateUserProfile(userProfile));
  }

  public addUserProfile(userProfile: IProfile): void {
    this.store$.dispatch(new AddUserProfile(userProfile));
  }

  public setConfirmationCodeValidityTimestamp(timestamp: number): void {
    this.store$.dispatch(new SetConfirmationCodeValidityTimeStamp(timestamp));
  }

  public setKYCStatus(status: KycStatus): void {
    this.store$.dispatch(new SetKYCStatus(status));
  }

  public clearUserState(): void {
    this.store$.dispatch(new ClearUserState());
  }

  public confirmEmail(): void {
    this.store$.dispatch(new SetEmailConfirmation());
  }

  public clearEmailConfirmation(): void {
    this.store$.dispatch(new ClearEmailConfirmation());
  }

  public updateTwoFaRequestPayload(twoFaRequestPayload: any | null): void {
    this.store$.dispatch(new UpdateTwoFaRequestPayload(twoFaRequestPayload));
  }

  public updateTwoFaQrCode(twoFaQrCode: ITwoFaQrCodeResponse): void {
    this.store$.dispatch(new UpdateTwoFaQrCode(twoFaQrCode));
  }

  public updateTwoFaPending(twoFaPending: boolean): void {
    this.store$.dispatch(new UpdateTwoFaPending(twoFaPending));
  }

  public updateOrganization(organization: IOrganization): void {
    this.store$.dispatch(new UpdateOrganization(organization));
  }

  public setIsUserDeletionRequested(date: string): void {
    this.store$.dispatch(new SetIsUserDeletionRequested(date));
  }

  public loadLastTransactions(): void {
    this.store$.dispatch(new LoadLastTransactionsAction());
  }

  public uploadProfileAvatar(formData: FormData): void {
    this.store$.dispatch(new UploadAvatarAction(formData));
  }

  public deleteAvatar(): void {
    this.store$.dispatch(new DeleteAvatarAction());
  }

  public addUser(userProfile: IUser): void {
    this.store$.dispatch(new AddUser(userProfile));
  }

  public setReferralCodeActivated(): void {
    this.store$.dispatch(new SetReferralCodeActivated());
  }
}
