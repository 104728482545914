import {createFeatureSelector} from '@ngrx/store';

import {IPlan, ISaving, ISavingActivity} from '@wallex-core-client/core/interfaces/savings.interface';
import {IResponsePagination} from '@wallex-core-client/core/interfaces/api-response.interface';
import {APP_VERSION} from '@wallex-core-client/core/constants/main-constants';
import {getInitialState} from '@wallex-core-client/utils';

export const savingsStateKey = 'savings';

export interface ISavingsState {
  readonly plans: IPlan[];
  readonly savings: IResponsePagination<ISaving[]> | null;
  readonly saving: ISaving | null;
  readonly savingActivity: ISavingActivity[] | null;
  readonly archive: IResponsePagination<ISaving[]> | null;
  readonly archiveSaving: ISaving | null;
  readonly savingDeposit: string | null;
}

export const blankSavingsState = {
  plans: [],
  savings: null,
  saving: null,
  savingActivity: null,
  archive: null,
  archiveSaving: null,
  savingDeposit: null
};

export const initialSavingsState: ISavingsState = getInitialState(APP_VERSION, savingsStateKey) || blankSavingsState;

export const selectSavingsState = createFeatureSelector<ISavingsState>(savingsStateKey);
