import {Action} from '@ngrx/store';

import {IPlan, ISaving, ISavingActivity} from '@wallex-core-client/core/interfaces/savings.interface';
import {IResponsePagination} from '@wallex-core-client/core/interfaces/api-response.interface';

export const enum SavingsActionType {
  UPDATE_PLANS = '[Savings] Update Plans',
  UPDATE_PLAN = '[Savings] Update Plan',
  UPDATE_AMOUNT = '[Savings] Update Amount',
  UPDATE_SAVINGS = '[Savings] Update Savings',
  UPDATE_SAVING = '[Savings] Update Saving',
  UPDATE_SAVING_ACTIVITY = '[Savings] Update Saving Activity',
  UPDATE_ARCHIVE = '[Savings] Update Archive',
  UPDATE_ARCHIVE_SAVING = '[Savings] Update Archive Saving',
  UPDATE_SAVING_DEPOSIT = '[Savings] Update Saving Deposit',
  CLEAR_SAVINGS = '[Savings] Clear Savings'
}

export class UpdatePlans implements Action {
  readonly type = SavingsActionType.UPDATE_PLANS;

  constructor(readonly plans: IPlan[]) {}
}

export class UpdateSavings implements Action {
  readonly type = SavingsActionType.UPDATE_SAVINGS;

  constructor(readonly savings: IResponsePagination<ISaving[]>) {}
}

export class UpdateCurrentSaving implements Action {
  readonly type = SavingsActionType.UPDATE_SAVING;

  constructor(readonly saving: ISaving | null) {}
}

export class UpdateSavingActivity implements Action {
  readonly type = SavingsActionType.UPDATE_SAVING_ACTIVITY;

  constructor(readonly savingActivity: ISavingActivity[]) {}
}

export class UpdateArchive implements Action {
  readonly type = SavingsActionType.UPDATE_ARCHIVE;

  constructor(readonly archive: IResponsePagination<ISaving[]>) {}
}

export class UpdateArchiveSaving implements Action {
  readonly type = SavingsActionType.UPDATE_ARCHIVE_SAVING;

  constructor(readonly archiveSaving: ISaving) {}
}

export class UpdateSavingDeposit implements Action {
  readonly type = SavingsActionType.UPDATE_SAVING_DEPOSIT;

  constructor(readonly savingDeposit: string | null) {}
}

export class ClearSavings implements Action {
  readonly type = SavingsActionType.CLEAR_SAVINGS;

  constructor() {}
}

export type SavingsAction =
  | UpdatePlans
  | UpdateSavings
  | UpdateCurrentSaving
  | UpdateSavingActivity
  | UpdateArchive
  | UpdateArchiveSaving
  | UpdateSavingDeposit
  | ClearSavings;
