import {blankCreditsState, initialCreditsState, ICreditsState} from '@wallex-core/store/credits/credits-state.model';
import {CreditsActionType} from '@wallex-core/store/credits/credits.actions';
import {NeoBankingAction} from '@wallex-core/store/action.model';

export function creditsReducer(state = initialCreditsState, action: NeoBankingAction): ICreditsState {
  switch (action.type) {
    case CreditsActionType.ADD_CREDIT_ASSETS:
      return {
        ...state,
        creditAssets: action.assets
      };

    case CreditsActionType.ADD_COLLATERAL_ASSETS:
      return {
        ...state,
        collateralAssets: action.assets
      };

    case CreditsActionType.ADD_CREDIT_PERIODS:
      return {
        ...state,
        creditPeriods: action.periods
      };

    case CreditsActionType.ADD_LTV_GRADATION:
      return {
        ...state,
        ltvGradation: action.ltvGradation
      };

    case CreditsActionType.ADD_ACTIVE_CREDITS:
      return {
        ...state,
        activeCredits: action.credits
      };

    case CreditsActionType.ADD_ARCHIVE_CREDITS:
      return {
        ...state,
        archiveCredits: action.credits
      };

    case CreditsActionType.REQUEST_CREDIT:
      return {
        ...state,
        requestedCredit: action.credit
      };

    case CreditsActionType.CLEAR_REQUESTED_CREDIT:
      return {
        ...state,
        requestedCredit: null
      };

    case CreditsActionType.REPAY_CREDIT:
      return {
        ...state,
        repaidCredit: action.credit
      };

    case CreditsActionType.CLEAR_REPAID_CREDIT:
      return {
        ...state,
        repaidCredit: null
      };

    case CreditsActionType.CLEAR_CREDITS:
      return {...blankCreditsState};

    default:
      return state;
  }
}
