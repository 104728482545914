import {StoreModule, ActionReducer, MetaReducer} from '@ngrx/store';
import {localStorageSync} from 'ngrx-store-localstorage';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {NgModule} from '@angular/core';

import {selectedWalletStateKey} from '@wallex-core/store/selected-wallet/selected-wallet-state.model';
import {notificationsStateKey} from '@wallex-core/store/notifications/notifications-state.model';
import {settingsStateKey} from '@wallex-core/store/settings/settings-state.model';
import {walletsStateKey} from '@wallex-core/store/wallets/wallets-state.model';
import {bannersStateKey} from '@wallex-core/store/banners/banners-state.model';
import {savingsStateKey} from '@wallex-core/store/savings/savings-state.model';
import {creditsStateKey} from '@wallex-core/store/credits/credits-state.model';
import {SettingsEffects} from '@wallex-core/store/settings/settings.effects';
import {assetsStateKey} from '@wallex-core/store/assets/assets-state.model';
import {BannersEffects} from '@wallex-core/store/banners/banners.effects';
import {WalletsEffects} from '@wallex-core/store/wallets/wallets.effects';
import {ratesStateKey} from '@wallex-core/store/rates/rates-state.model';
import {cardsStateKey} from '@wallex-core/store/cards/cards-state.model';
import {AssetsEffects} from '@wallex-core/store/assets/assets.effects';
import {userStateKey} from '@wallex-core/store/user/user-state.model';
import {newsStateKey} from '@wallex-core/store/news/news-state.model';
import {ibanStateKey} from '@wallex-core/store/iban/iban-state.model';
import {RatesEffects} from '@wallex-core/store/rates/rates.effects';
import {appStateKey} from '@wallex-core/store/app/app-state.model';
import {environment} from '@wallex-core/environments/environment';
import {UserEffects} from '@wallex-core/store/user/user.effects';
import {NeoBankingAction} from '@wallex-core/store/action.model';
import {IState, reducers} from '@wallex-core/store/state.model';
import {AppEffects} from '@wallex-core/store/app/app.effects';

const reducerKeys = [
  appStateKey,
  userStateKey,
  walletsStateKey,
  assetsStateKey,
  bannersStateKey,
  settingsStateKey,
  selectedWalletStateKey,
  savingsStateKey,
  ibanStateKey,
  newsStateKey,
  notificationsStateKey,
  creditsStateKey,
  cardsStateKey,
  ratesStateKey
];

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({keys: reducerKeys})(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

let modules = [
  StoreModule.forRoot<IState, NeoBankingAction>(reducers, {
    metaReducers,
    runtimeChecks: {
      strictStateImmutability: true
    }
  }),
  EffectsModule.forRoot([SettingsEffects, AssetsEffects, RatesEffects, AppEffects, UserEffects, WalletsEffects, BannersEffects])
];

if (!environment.production) {
  modules.push(
    StoreDevtoolsModule.instrument({
      name: 'NeoBanking app',
      maxAge: 20
    })
  );
}

@NgModule({
  imports: [...modules],
  providers: []
})
export class StateModule {}
