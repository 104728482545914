<app-success-animation width="97px" height="97px"></app-success-animation>

<p class="success-transaction__title">{{ 'success-transaction.title' | translate }}</p>

<p class="success-transaction__text">
  {{ 'account.success-sending.you-have-successfully-sent' | translate }}
  <span class="body3">
    {{ amount | numberFr : decimalParameter }}
    {{ account.asset }}
    <ng-container *ngIf="!isFiat">&nbsp;({{ currencySign }}{{ amount * rate | numberFr }})</ng-container>.
  </span>
</p>

<div class="button-area">
  <button pButton type="button" data-qa="close-dialog" class="success-transaction__close" label="Close" (click)="close()"></button>
</div>
