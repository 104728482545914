import {map, tail, max} from 'lodash-es';

import {formatDate} from '@angular/common';

import {FIAT_DECIMALS} from '@wallex-core-client/core/constants/main-constants';
import {dotToComma} from '@wallex-core-client/utils';

export const getChartOptions = (currencySign: string | undefined, labelFormat: string, locale: string, fontSize: number) => {
  const fontRegular = getComputedStyle(document.body).getPropertyValue('--font-base-regular');
  const fontMedium = getComputedStyle(document.body).getPropertyValue('--font-base-medium');

  return {
    responsive: true,
    scales: {
      x: {
        display: true,
        position: 'bottom',
        border: {
          display: false
        },
        grid: {
          display: false
        },
        ticks: {
          font: {
            family: fontRegular,
            size: fontSize
          },
          color: '#64686F',
          autoSkip: false,
          callback(value: number, index: number, values: number[]): any {
            const MAX_LABELS = 4;
            const lengthOfValues = values.length;
            const indexLabel = Math.round(lengthOfValues / MAX_LABELS);
            const label: Date = (this as any).getLabelForValue(value);

            if (index === 0) return formatDate(label, labelFormat, locale);

            if (index === lengthOfValues - 1) return formatDate(label, labelFormat, locale);

            if (index % indexLabel === 0) return formatDate(label, labelFormat, locale);
          }
        }
      },
      y: {
        border: {
          display: false,
          dash: [3, 3]
        },
        grid: {
          color: '#e2e3e7'
        },
        display: true,
        position: 'right',
        ticks: {
          font: {
            family: fontRegular,
            size: fontSize
          },
          color: '#64686F',
          callback: (value: number, index: number, values: {value: number}[]) => {
            const MAX_DECIMALS = 6;
            const results = map(tail(values), ({value}, index) => value - values[index].value);

            const decimals = map(results, (number: number) => {
              if (number >= 1) return FIAT_DECIMALS;

              const str = number.toString();
              const dotIndex = str.indexOf('.');
              let finalDecimals = str.substring(dotIndex + 1).match(/^0*/)?.[0].length;

              if (finalDecimals) finalDecimals += 1;
              else finalDecimals = FIAT_DECIMALS;

              return dotIndex !== -1 ? finalDecimals : 0;
            });

            let biggerDecimals = max(decimals);
            let result;

            if (typeof biggerDecimals === 'number' && biggerDecimals <= MAX_DECIMALS) {
              biggerDecimals = biggerDecimals === 1 ? FIAT_DECIMALS : biggerDecimals;
              result = value.toFixed(biggerDecimals);
            } else {
              result = value.toFixed(MAX_DECIMALS);
            }

            return dotToComma(result);
          },
          autoSkip: true,
          maxTicksLimit: 6
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
        displayColors: false,
        backgroundColor: '#fff',
        titleColor: '#64686F',
        titleMarginBottom: 4,
        borderColor: 'rgba(17, 19, 20, 0.08)',
        borderWidth: 1,
        padding: 16,
        titleFont: {
          size: 14,
          family: fontRegular
        },
        bodyFont: {
          size: 16,
          family: fontMedium
        },
        callbacks: {
          title: ([tooltip]: any) => {
            return formatDate(new Date(tooltip.label), 'dd/MM/yyyy HH:mm:ss', locale);
          },
          label: (tooltip: any) => {
            const value = tooltip.raw.toFixed(FIAT_DECIMALS);
            return `${currencySign || ''}${dotToComma(value)}`;
          },
          labelTextColor: () => {
            return '#111314';
          }
        }
      }
    }
  };
};
