import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-dropdown-field',
  templateUrl: './dropdown-field.component.html',
  styleUrls: ['./dropdown-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownFieldComponent),
      multi: true
    }
  ]
})
export class DropdownFieldComponent implements ControlValueAccessor, AfterViewInit {
  @HostBinding('class.dropdown-field') className = true;

  @ContentChildren('dropdownItemTemplate') dropdownItemTemplate!: QueryList<TemplateRef<any>>;
  @ContentChildren('dropdownCurrentItemTemplate') dropdownCurrentItemTemplate!: QueryList<TemplateRef<any>>;
  @ViewChild('defaultItemTemplate') defaultItemTemplate!: TemplateRef<any>;
  @ViewChild('defaultCurrentItemTemplate') defaultCurrentItemTemplate!: TemplateRef<any>;

  public itemTemplate!: TemplateRef<any>;
  public currentItemTemplate!: TemplateRef<any>;

  @Input() inputId!: string;
  @Input() value!: string;
  @Input() optionValue!: string;
  @Input() optionLabel!: string;
  @Input() isFilterEnable!: boolean;
  @Input() placeholder!: string;
  @Input() options!: any[];
  @Input() filterBy: string = '';
  @Input() filterPlaceholder: string = '';
  @Input() filterMatchMode: string = '';
  @Input() isInvalid!: boolean;
  @Input() emptyMessage: string = 'errors.no_results';
  @Output('onChange') onChangeEvent = new EventEmitter<any>();
  @Output() tryAgain = new EventEmitter();

  public isDisabled!: boolean;
  public changed!: (value: string) => void;
  public touched!: () => void;

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.itemTemplate = this.dropdownItemTemplate.first || this.defaultItemTemplate;
    this.currentItemTemplate = this.dropdownCurrentItemTemplate.first || this.defaultCurrentItemTemplate;
  }

  public onChange(event: EventTarget): void {
    const value: string = (<HTMLInputElement>event).value;
    this.onChangeEvent.emit(value);
    this.changed(value);
  }

  public writeValue(value: string): void {
    this.value = value;
    this.cd.detectChanges();
  }

  public registerOnChange(fn: any): void {
    this.changed = fn;
  }

  public registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public onTryAgain(event: Event): void {
    event.preventDefault();
    this.tryAgain.emit();
  }
}
