import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export function isRequiredValidator(): ValidatorFn {
  // @ts-ignore
  return (group: FormGroup): ValidationErrors | null => {
    const control1 = group.controls['referralCode'];
    const control2 = group.controls['referralProgramTerms'];
    return control1?.value.trim() && !control2.value ? {required: true} : null;
  };
}
