import {Pipe, PipeTransform} from '@angular/core';
import {isNull} from 'lodash-es';

@Pipe({
  name: 'minuteSecondsLeft'
})
export class MinuteSecondsLeft implements PipeTransform {
  transform(value: number | null, totalSeconds: number): string {
    if (!isNull(value) && !isNull(totalSeconds)) {
      value++;
      const minutesLeft = (totalSeconds - value) / 60;
      const formattedMinutesLeft = Math.floor(minutesLeft);
      const secondsLeft = Math.round((minutesLeft - formattedMinutesLeft) * 60);
      const formattedSecondsLeft: string = secondsLeft < 10 ? `0${secondsLeft}` : String(secondsLeft);
      return `${formattedMinutesLeft}:${formattedSecondsLeft}`;
    }
    return '';
  }
}
