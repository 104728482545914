import {Inject, Injectable} from '@angular/core';
import {io, Socket} from 'socket.io-client';
import {Observable, Subject} from 'rxjs';

import {filter} from 'rxjs/operators';

import {IWebSocketPayload, WebSocketActionsEnum, WebSocketErrors} from '@wallex-core-client/core/interfaces/web-socket.interface';
import {TransactionState, TransactionTypeList} from '@wallex-core-client/core/interfaces/transaction.interface';
import {SettingsStateFacade} from '@wallex-core/store/settings/settings-state-facade.service';
import {WalletsStateFacade} from '@wallex-core/store/wallets/wallets-state-facade.service';
import {RatesStateFacade} from '@wallex-core/store/rates/rates-state-facade.service';
import {ENVIRONMENT_TOKEN} from '@wallex-core-client/core/constants/main-constants';
import {IEnvironment} from '@wallex-core-client/core/interfaces/common.interface';
import {UserStateFacade} from '@wallex-core/store/user/user-state-facade.service';
import {JwtTokenService} from '@wallex-core/services/jwt-token.service';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private socket!: Socket;
  public message$ = new Subject<IWebSocketPayload>();
  private isConnected = false;
  private readonly CONNECTION_TIMEOUT: number = 3000;

  public get transactionsMessage$(): Observable<IWebSocketPayload> {
    return this.message$.pipe(
      filter(message => {
        return message?.type === WebSocketActionsEnum.TRANSACTION_UPDATED || message?.type === WebSocketActionsEnum.TRANSACTION_CREATED;
      })
    );
  }

  public get transactionUpdatedMessage$(): Observable<IWebSocketPayload> {
    return this.message$.pipe(
      filter(message => {
        return message?.type === WebSocketActionsEnum.TRANSACTION_UPDATED;
      })
    );
  }

  public get savingMessage$(): Observable<IWebSocketPayload> {
    return this.message$.pipe(
      filter(message => {
        return message?.type === WebSocketActionsEnum.TRANSACTION_CREATED;
      }),
      filter(message => {
        return message.data.transaction.type === TransactionTypeList.saving || message.data.transaction?.payload?.savingId;
      })
    );
  }

  public get cardTransactionCreatedMessage$(): Observable<IWebSocketPayload> {
    return this.transactionsMessage$.pipe(
      filter(message => {
        return message.data.transaction.type === TransactionTypeList.topUp && message.data.transaction.state === TransactionState.completed;
      })
    );
  }

  public get cardOrderMessage$(): Observable<IWebSocketPayload> {
    return this.message$.pipe(
      filter(message => message?.type === WebSocketActionsEnum.TRANSACTION_CREATED),
      filter(message => {
        return message.data.transaction.payload?.type === 'card-issuing' && message.data.transaction.payload?.cardId;
      })
    );
  }

  constructor(
    private jwtTokenService: JwtTokenService,
    private userStateFacade: UserStateFacade,
    private walletsStateFacade: WalletsStateFacade,
    private settingsStateFacade: SettingsStateFacade,
    private ratesStateFacade: RatesStateFacade,
    @Inject(ENVIRONMENT_TOKEN) private environment: IEnvironment
  ) {}

  public connect(): void {
    if (!this.isConnected) {
      this.socket = io(this.environment.wsUrl, {
        auth: {
          token: this.jwtTokenService.token?.accessToken
        },
        autoConnect: true
      });

      this.getNewMessage();
    }

    this.isConnected = true;
  }

  public disconnect(): void {
    this.isConnected = false;

    if (this.socket) {
      this.socket.disconnect();
    }
  }

  private getNewMessage(): void {
    this.socket.on<string>('connect', data => {
      console.log('connect');
      this.message$.next(data);
    });

    this.socket.on<string>('error', data => {
      console.log('error');
      this.disconnect();

      if (data.message === WebSocketErrors.NOT_AUTHORIZED) return;

      const timeout = setTimeout(() => {
        this.connect();
        clearTimeout(timeout);
      }, this.CONNECTION_TIMEOUT);
    });

    this.socket.on<string>('userNotification', data => {
      this.message$.next(data);
      console.log(data);

      switch (data.type) {
        case WebSocketActionsEnum.KYC_ACTION:
          this.userStateFacade.setKYCStatus(data.data.kycStatus);
          return;
        case WebSocketActionsEnum.TRANSACTION_CREATED:
          this.walletsStateFacade.loadWallets();
          return;
        case WebSocketActionsEnum.TRANSACTION_UPDATED:
          this.walletsStateFacade.loadWallets();
          return;
        case WebSocketActionsEnum.WALLET_CREATED:
          this.walletsStateFacade.loadWallets();
          this.ratesStateFacade.loadWalletChart(data.data.wallet);
          return;
        case WebSocketActionsEnum.WALLET_UPDATED:
          this.walletsStateFacade.loadWallets();
          return;
        case WebSocketActionsEnum.AUTO_CONVERSION_UPDATED:
          this.settingsStateFacade.loadAutoConversionCurrencies();
          this.settingsStateFacade.loadGeneralSettings();
          return;
        case WebSocketActionsEnum.BALANCE_SEEN_UPDATED:
          this.settingsStateFacade.loadGeneralSettings();
          return;
        case WebSocketActionsEnum.IBAN_ORDER_SENT:
          this.walletsStateFacade.loadWallets();
          return;
        case WebSocketActionsEnum.IBAN_ORDER_UPDATED:
          this.walletsStateFacade.loadWallets();
          return;
      }
    });
  }
}
