import {Pipe, PipeTransform} from '@angular/core';

import {DatePipe} from '@angular/common';

import {
  CARD_TRANSFER_TITLE,
  REWARD_TRANSFER_TITLE,
  transactionStateClass,
  transactionStateIcon,
  transactionStateLabel,
  transactionTypeIcon,
  transparentTransactionTypeIcon
} from '@wallex-core-client/core/constants/transactions-constants';
import {
  accountSign,
  ITransactionDetails,
  ITransactionModel,
  TransactionState,
  TransactionTypeList
} from '@wallex-core-client/core/interfaces/transaction.interface';

@Pipe({
  name: 'transactionTypeIcon'
})
export class TransactionTypeIcon implements PipeTransform {
  transform(transaction: ITransactionModel): transactionTypeIcon {
    if (transaction.title === CARD_TRANSFER_TITLE || transaction.type === TransactionTypeList.topUp) {
      return transactionTypeIcon.topUp;
    }

    if (transaction.type === TransactionTypeList.exchange) {
      return transactionTypeIcon.exchange;
    }

    if (transaction.type === TransactionTypeList.fee) {
      return transactionTypeIcon.fee;
    }

    if (transaction.title === REWARD_TRANSFER_TITLE) {
      return transactionTypeIcon.reward;
    }

    switch (transaction.sign) {
      case accountSign.plus:
        return transactionTypeIcon.receive;
      case accountSign.minus:
        return transactionTypeIcon.send;
      default:
        return transactionTypeIcon.exchange;
    }
  }
}

@Pipe({
  name: 'transparentTransactionTypeIcon'
})
export class TransparentTransactionTypeIcon implements PipeTransform {
  transform(transaction: ITransactionDetails): transparentTransactionTypeIcon {
    if (transaction.title === CARD_TRANSFER_TITLE || transaction.type === TransactionTypeList.topUp) {
      return transparentTransactionTypeIcon.topUp;
    }

    if (transaction.type === TransactionTypeList.exchange) {
      return transparentTransactionTypeIcon.exchange;
    }

    switch (transaction.sign) {
      case accountSign.plus:
        return transparentTransactionTypeIcon.receive;
      case accountSign.minus:
        return transparentTransactionTypeIcon.send;
      default:
        return transparentTransactionTypeIcon.exchange;
    }
  }
}

@Pipe({
  name: 'transactionTitle'
})
export class TransactionTitle implements PipeTransform {
  transform(transaction: ITransactionDetails): string {
    if (transaction.type === TransactionTypeList.topUp) {
      return 'transaction-details.types.topUpped';
    }

    if (transaction.type === TransactionTypeList.exchange) {
      return 'transaction-details.types.exchanged';
    }
    switch (transaction.sign) {
      case accountSign.plus:
        return 'transaction-details.types.received';
      case accountSign.minus:
        return 'transaction-details.types.sent';
      default:
        return 'transaction-details.types.exchanged';
    }
  }
}

@Pipe({
  name: 'transactionStateIcon'
})
export class TransactionStateIcon implements PipeTransform {
  transform(transaction: ITransactionModel): transactionStateIcon | 'none' {
    switch (transaction.state) {
      case TransactionState.pending:
        return transactionStateIcon.pending;
      case TransactionState.failed:
      case TransactionState.rejected:
        return transactionStateIcon.failed;
      case TransactionState.completed:
        return 'none';
      default:
        return 'none';
    }
  }
}

@Pipe({
  name: 'transactionStateClass'
})
export class TransactionStateClass implements PipeTransform {
  transform(transaction: ITransactionDetails): transactionStateClass | 'none' {
    switch (transaction.state) {
      case TransactionState.pending:
        return transactionStateClass.pending;
      case TransactionState.failed:
      case TransactionState.rejected:
        return transactionStateClass.failed;
      case TransactionState.completed:
        return transactionStateClass.success;
      default:
        return 'none';
    }
  }
}

@Pipe({
  name: 'transactionStateLabel'
})
export class TransactionStateLabel implements PipeTransform {
  transform(transaction: ITransactionDetails): transactionStateLabel | 'none' {
    switch (transaction.state) {
      case TransactionState.pending:
        return transactionStateLabel.pending;
      case TransactionState.failed:
      case TransactionState.rejected:
        return transactionStateLabel.failed;
      case TransactionState.completed:
        return transactionStateLabel.success;
      default:
        return 'none';
    }
  }
}

@Pipe({
  name: 'dateTransaction'
})
export class DateTransactionPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(date: string): string | null {
    if (!date) return date;

    const currentDate = new Date();
    const transactionDate = new Date(date);

    if (currentDate.getFullYear() === transactionDate.getFullYear()) {
      return this.datePipe.transform(transactionDate, 'd MMMM');
    }

    return this.datePipe.transform(transactionDate, 'd MMMM, yyyy');
  }
}
