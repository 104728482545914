import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {isNull} from 'lodash-es';

import {JwtTokenService} from '@wallex-core/services/jwt-token.service';
import {Routes} from '@wallex-core/constants/routes.enum';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private readonly jwtTokenService: JwtTokenService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!isNull(this.jwtTokenService.token)) {
      return true;
    }
    this.router.navigate([Routes.signIn]).catch(() => null);
    return false;
  }
}
