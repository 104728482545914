import {Action} from '@ngrx/store';

import {IWallet, IWalletBalance, IFavoriteWallet} from '@wallex-core-client/core/interfaces/wallet.interface';
import {SegmentDistributionsDto} from '@wallex-core-client/core/dtos/balance.dto';

export enum WalletsActionType {
  ADD_WALLET = '[Wallets] Add wallet',
  DELETE_WALLET = '[Wallets] Delete wallet',
  LOAD_WALLETS = '[Wallets] Load Wallets',
  LOAD_WALLETS_SUCCESS = '[Wallets] Wallets loaded successfully',
  LOAD_WALLETS_FAILURE = '[Wallets] Wallets failed to load',
  SET_TOTAL_BALANCE = '[Wallets] Set total balance',
  SET_WALLET_BALANCE = '[Wallets] Set wallet balance',
  CLEAR_WALLETS = '[Wallets] Clear Wallet',
  SET_SEGMENT_DISTRIBUTIONS = '[Wallets] Set Segment Distributions',
  TOGGLE_FAVORITE = '[Wallets] Toggle favorite'
}

export class AddWallet implements Action {
  readonly type = WalletsActionType.ADD_WALLET;

  constructor(readonly wallet: IWallet) {}
}

export class DeleteWallet implements Action {
  readonly type = WalletsActionType.DELETE_WALLET;

  constructor(readonly asset: string) {}
}

export class LoadWallets implements Action {
  readonly type = WalletsActionType.LOAD_WALLETS;

  constructor() {}
}

export class LoadWalletsSuccess implements Action {
  readonly type = WalletsActionType.LOAD_WALLETS_SUCCESS;

  constructor(readonly wallets: IWallet[]) {}
}

export class LoadWalletsFailure implements Action {
  readonly type = WalletsActionType.LOAD_WALLETS_FAILURE;
}

export class SetTotalBalance implements Action {
  readonly type = WalletsActionType.SET_TOTAL_BALANCE;

  constructor(readonly totalBalance: Record<string, number>) {}
}

export class SetWalletBalance implements Action {
  readonly type = WalletsActionType.SET_WALLET_BALANCE;

  constructor(readonly walletBalance: IWalletBalance) {}
}

export class SetSegmentDistributions implements Action {
  readonly type = WalletsActionType.SET_SEGMENT_DISTRIBUTIONS;

  constructor(readonly segmentDistributions: SegmentDistributionsDto) {}
}

export class ToggleFavorite implements Action {
  readonly type = WalletsActionType.TOGGLE_FAVORITE;

  constructor(readonly favoriteWallet: IFavoriteWallet) {}
}

export class ClearWallets implements Action {
  readonly type = WalletsActionType.CLEAR_WALLETS;
}

export type WalletsAction =
  | LoadWallets
  | AddWallet
  | DeleteWallet
  | LoadWalletsSuccess
  | LoadWalletsFailure
  | SetTotalBalance
  | SetWalletBalance
  | ClearWallets
  | SetSegmentDistributions
  | ToggleFavorite;
