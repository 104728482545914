import {blankRatesState, initialRatesState, IRatesState} from '@wallex-core/store/rates/rates-state.model';
import {RatesActionType} from '@wallex-core/store/rates/rates.actions';
import {NeoBankingAction} from '@wallex-core/store/action.model';

export function ratesReducer(state = initialRatesState, action: NeoBankingAction): IRatesState {
  switch (action.type) {
    case RatesActionType.LOAD_RATES_SUCCESS:
      return {
        ...state,
        rates: action.rates
      };

    case RatesActionType.SET_RATE_FOR_ACCOUNT:
      return {
        ...state,
        rates: {...state.rates, [action.asset]: action.rates}
      };

    case RatesActionType.LOAD_WALLET_CHARTS_SUCCESS:
      return {
        ...state,
        walletCharts: action.walletCharts
      };

    case RatesActionType.LOAD_WALLET_CHART_SUCCESS:
      if (action.walletChart && action.walletChart?.data) {
        return {
          ...state,
          walletCharts: [...state.walletCharts, action.walletChart]
        };
      }

      return {...state};

    case RatesActionType.LOAD_MARKET_LIST:
      return {
        ...state,
        marketList: null,
        isMarketListLoading: true,
        isMarketListFailure: false
      };

    case RatesActionType.LOAD_MARKET_LIST_SUCCESS:
      return {
        ...state,
        marketList: action.marketList,
        isMarketListLoading: false,
        isMarketListFailure: false
      };

    case RatesActionType.LOAD_MARKET_LIST_FAILURE:
      return {
        ...state,
        marketList: null,
        isMarketListLoading: false,
        isMarketListFailure: true
      };

    case RatesActionType.LOAD_MARKET_DETAILS:
      return {
        ...state,
        isMarketDetailsLoading: true,
        isMarketDetailsFailure: false,
        marketDetails: null
      };

    case RatesActionType.LOAD_MARKET_DETAILS_SUCCESS:
      return {
        ...state,
        isMarketDetailsLoading: false,
        isMarketDetailsFailure: false,
        marketDetails: action.marketDetails
      };

    case RatesActionType.LOAD_MARKET_DETAILS_FAILURE:
      return {
        ...state,
        isMarketDetailsLoading: false,
        isMarketDetailsFailure: true,
        marketDetails: null
      };

    case RatesActionType.CLEAR_RATES: {
      return {...blankRatesState};
    }

    default:
      return state;
  }
}
