import {shareReplay, map} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {
  SelectWallet,
  RequestTransaction,
  AddTransactions,
  UpdateRequestedFiatTransaction,
  ClearSelectedWallet,
  SetTransactions,
  UpdateTopUpWithCardUrl
} from '@wallex-core/store/selected-wallet/selected-wallet.actions';
import {ITransactionModel, IRequestedCryptoTransaction, RequestedFiatTransaction} from '@wallex-core-client/core/interfaces/transaction.interface';
import {ISelectedWalletState, selectSelectedWalletState} from '@wallex-core/store/selected-wallet/selected-wallet-state.model';
import {IResponsePagination} from '@wallex-core-client/core/interfaces/api-response.interface';
import {IWallet} from '@wallex-core-client/core/interfaces/wallet.interface';

@Injectable({
  providedIn: 'root'
})
export class SelectedWalletStateFacade {
  constructor(private readonly store$: Store<ISelectedWalletState>) {}

  private readonly state$ = this.store$.pipe(select(selectSelectedWalletState));

  readonly walletId$ = this.state$.pipe(
    select(state => state.walletId),
    shareReplay(1)
  );

  readonly requestedCryptoTransaction$ = this.state$.pipe(
    select(state => state.requestedCryptoTransaction),
    shareReplay(1)
  );

  readonly requestedFiatTransaction$ = this.state$.pipe(
    select(state => state.requestedFiatTransaction),
    shareReplay(1)
  );

  readonly transactions$ = this.state$.pipe(
    select(state => state.transactions),
    shareReplay(1)
  );

  readonly transactionsList$ = this.transactions$.pipe(
    select(state => state.data),
    shareReplay(1)
  );

  readonly topUpWithCardUrl$ = this.state$.pipe(
    select(state => state.topUpWithCardUrl),
    shareReplay(1)
  );

  public getTransactionById$(transactionId: string): Observable<ITransactionModel | undefined> {
    return this.transactionsList$.pipe(map(transactions => this.getTransactionById(transactions, transactionId)));
  }

  public getTransactionById(transactions: ITransactionModel[], transactionId: string): ITransactionModel | undefined {
    return transactions.find(transaction => transaction.id === transactionId);
  }

  public selectWallet(wallet: IWallet): void {
    this.store$.dispatch(new SelectWallet(wallet));
  }

  public requestTransaction(transaction: IRequestedCryptoTransaction | null): void {
    this.store$.dispatch(new RequestTransaction(transaction));
  }

  public updateRequestedFiatTransaction(transaction: RequestedFiatTransaction | null): void {
    this.store$.dispatch(new UpdateRequestedFiatTransaction(transaction));
  }

  public addTransactions(transactions: IResponsePagination<ITransactionModel[]>): void {
    this.store$.dispatch(new AddTransactions(transactions));
  }

  public setTransactions(transactions: IResponsePagination<ITransactionModel[]>): void {
    this.store$.dispatch(new SetTransactions(transactions));
  }

  public updateTopUpWithCardUrl(url: string | null): void {
    this.store$.dispatch(new UpdateTopUpWithCardUrl(url));
  }

  public clearSelectedWallet(): void {
    this.store$.dispatch(new ClearSelectedWallet());
  }
}
