import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimerComponent implements OnInit {
  @Input() maxTime: number = -1;
  @Output('timer') timerEvent = new EventEmitter<number>();

  public timer: number = this.maxTime;
  public interval!: any | null;
  private INTERVAL_TIMEOUT: number = 1000;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initializeCountDown();
  }

  private initializeCountDown(): void {
    this.timer = this.maxTime;
    this.timerEvent.emit(this.timer);
    this.cd.detectChanges();

    this.interval = setInterval(() => {
      this.timer -= 1;
      this.timerEvent.emit(this.timer);

      if (!this.timer) this.clearCountDown();
      this.cd.detectChanges();
    }, this.INTERVAL_TIMEOUT);
  }

  private clearCountDown(): void {
    clearInterval(this.interval);
    this.interval = null;
  }
}
