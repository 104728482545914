<div class="switch-overlay__user d-flex align-items-center justify-content-between">
  <div class="d-flex align-items-center">
    <app-avatar width="40px" height="40px" [isIndividualAccount]="true"></app-avatar>
    <p class="switch-overlay__user-name mb-0">Sergey Gladun</p>
  </div>
  <img src="/assets/success-primary.svg" alt="Active account" />
</div>

<div class="switch-overlay__user d-flex align-items-center justify-content-between">
  <div class="d-flex align-items-center">
    <app-avatar width="40px" height="40px" [isIndividualAccount]="true"></app-avatar>
    <p class="switch-overlay__user-name mb-0">Sergey Gladun</p>
  </div>
  <img src="/assets/success-primary.svg" alt="Active account" />
</div>
