import {has, isNil} from 'lodash-es';

export const checkAllKeysTruthy = (obj: any, keys: string[]): boolean => {
  return keys.every((key: string) => has(obj, key) && obj[key]);
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();

  return null;
};

export const isIOS = () => {
  return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform);
};

export const isObjectHasAllValuesDefined = (obj: any): boolean => {
  return Object.values(obj).every((value: any) => !isNil(value));
};
