import {ChangeDetectionStrategy, Component, Inject, NgZone, OnDestroy, OnInit, Optional} from '@angular/core';
import {getToken, Messaging, onMessage, MessagePayload} from '@angular/fire/messaging';
import {EMPTY, Observable, Subject, takeUntil} from 'rxjs';
import {Intercom} from 'ng-intercom';

import {DOCUMENT} from '@angular/common';

import {Router} from '@angular/router';

import {NotificationsStateFacade} from '@wallex-core/store/notifications/notifications-state-facade.service';
import {NotificationPayload, PushNotificationPayload} from '@wallex-core-client/core/dtos/notifications.dto';
import {SettingsStateFacade} from '@wallex-core/store/settings/settings-state-facade.service';
import {BannersStateFacade} from '@wallex-core/store/banners/banners-state-facade.service';
import {WalletsStateFacade} from '@wallex-core/store/wallets/wallets-state-facade.service';
import {ReferenceCurrencyService} from '@wallex-core/services/reference-currency.service';
import {AssetsStateFacade} from '@wallex-core/store/assets/assets-state-facade.service';
import {RatesStateFacade} from '@wallex-core/store/rates/rates-state-facade.service';
import {ENVIRONMENT_TOKEN} from '@wallex-core-client/core/constants/main-constants';
import {DeviceProvider} from '@wallex-core-client/core/constants/device.constants';
import {IEnvironment} from '@wallex-core-client/core/interfaces/common.interface';
import {UserStateFacade} from '@wallex-core/store/user/user-state-facade.service';
import {NotificationsService} from '@wallex-core/services/notifications.service';
import {AppStateFacade} from '@wallex-core/store/app/app-state-facade.service';
import {WebSocketService} from '@wallex-core/services/web-socket.service';
import {areNotificationsSupported} from '@wallex-core-client/utils';
import {WalletService} from '@wallex-core/services/wallet.service';
import {DeviceService} from '@wallex-core/services/device.service';
import {UserService} from '@wallex-core/services/user.service';
import {TwoFaService} from '@wallex-core/services/2fa.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements OnInit, OnDestroy {
  private readonly LIMITED_WIDTH_CLASS = 'limited-width';

  public isMobileMenuOpened = false;
  private notifier$ = new Subject<void>();
  private _messaging: Messaging;
  private vapidKey: string;
  message$: Observable<MessagePayload> = EMPTY;
  broadcastBackgroundPush: BroadcastChannel;
  broadcastBackgroundPushClick: BroadcastChannel;

  constructor(
    private appStateFacade: AppStateFacade,
    private referenceCurrencyService: ReferenceCurrencyService,
    private webSocketService: WebSocketService,
    private twoFaService: TwoFaService,
    private intercom: Intercom,
    private userService: UserService,
    private userStateFacade: UserStateFacade,
    private walletsStateFacade: WalletsStateFacade,
    private settingsStateFacade: SettingsStateFacade,
    private bannersStateFacade: BannersStateFacade,
    private walletService: WalletService,
    private ratesStateFacade: RatesStateFacade,
    private assetsStateFacade: AssetsStateFacade,
    private notificationsStateFacade: NotificationsStateFacade,
    private deviceService: DeviceService,
    private notificationsService: NotificationsService,
    private router: Router,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document,
    @Optional() messaging: Messaging,
    @Inject(ENVIRONMENT_TOKEN) environment: IEnvironment
  ) {
    this._messaging = messaging;
    this.vapidKey = environment.vapidKey;
    this.message$ = new Observable(sub => onMessage(messaging, it => sub.next(it)));
    this.broadcastBackgroundPush = new BroadcastChannel(environment.backgroundNotificationChannel);
    this.broadcastBackgroundPushClick = new BroadcastChannel(environment.backgroundNotificationClickChannel);
  }

  ngOnInit() {
    this.getReferenceCurrencies();
    this.updateIntercom();
    this.getWallets();
    this.loadAssets();
    this.settingsStateFacade.loadGeneralSettings();
    if (areNotificationsSupported()) {
      this.requestPushPermissionAndInitServiceWorker();
    }
    this.bannersStateFacade.loadMainScreenBanners();
    this.webSocketService.connect();
    this.document.body.classList.add(this.LIMITED_WIDTH_CLASS);
  }

  private getReferenceCurrencies(): void {
    this.referenceCurrencyService.getReferenceCurrencies().subscribe(
      currencies => {
        this.settingsStateFacade.addCurrencies(currencies);
      },
      response => {
        this.appStateFacade.handleFatalError(response);
      }
    );
  }

  private loadAssets(): void {
    this.assetsStateFacade.loadAssets();

    this.assetsStateFacade.isAssetsLoadingFailure$.pipe(takeUntil(this.notifier$)).subscribe(isAssetsLoadingFailure => {
      if (!isAssetsLoadingFailure) return;

      const timeout = setTimeout(() => {
        this.loadAssets();
        clearTimeout(timeout);
      }, 1000);
    });
  }

  private getWallets(): void {
    this.walletService.loadWallets().subscribe(
      wallets => {
        this.walletsStateFacade.loadWalletsSuccess(wallets);
        this.ratesStateFacade.loadWalletCharts();
      },
      response => {
        this.appStateFacade.handleFatalError(response);
        this.walletsStateFacade.loadWalletsFailure();
      }
    );
  }

  private updateIntercom(): void {
    this.userService.getUserHash().subscribe(userHash => {
      this.userStateFacade.userProfile$.pipe(takeUntil(this.notifier$)).subscribe(userProfile => {
        if (!userProfile?.firstName || !userProfile?.email) return;

        this.intercom.update({
          user_id: userHash.userId,
          user_hash: userHash.userHash,
          email: userProfile.email,
          name: `${userProfile.firstName} ${userProfile.lastName}`
        });
      });
    });
  }

  public openMobileMenu(event: boolean): void {
    this.isMobileMenuOpened = event;

    if (this.isMobileMenuOpened) {
      this.document.body.classList.add('overflow-hidden');
      return;
    }

    this.document.body.classList.remove('overflow-hidden');
  }

  private async requestPushPermissionAndInitServiceWorker() {
    const permission = await Notification.requestPermission();
    if (permission === 'granted' && this._messaging) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js', {
          type: 'module',
          scope: './'
        })
        .then(serviceWorkerRegistration => {
          console.log('Registered service worker...');
          this.getFirebaseToken(serviceWorkerRegistration);
        })
        .catch(err => {
          console.log('Failed to register service worker....');
          console.log(err);
        });
    }
  }

  private getFirebaseToken(serviceWorkerRegistration: ServiceWorkerRegistration) {
    getToken(this._messaging, {
      serviceWorkerRegistration,
      vapidKey: this.vapidKey
    })
      .then(token => {
        this.registerUserDevice(token);
      })
      .catch(error => {
        console.log('Requesting Firebase token error', error);
        this.getFirebaseToken(serviceWorkerRegistration);
      });
  }

  private registerUserDevice(token: string) {
    this.deviceService.registerUserDevice({pushToken: token, provider: DeviceProvider.FIREBASE}).subscribe(
      res => {
        console.log('Registered user device...');
        this.notificationsStateFacade.setPushToken(token);
        this.subscribeToForegroundPushNotifications();
        this.subscribeToBackgroundPushNotifications();
      },
      error => {
        console.log('Error registering user device...', error);
      }
    );
  }

  private subscribeToForegroundPushNotifications() {
    this.message$.pipe(takeUntil(this.notifier$)).subscribe(message => {
      this.notificationsStateFacade.addPushNotification(message);
    });
  }

  private subscribeToBackgroundPushNotifications() {
    this.broadcastBackgroundPush.onmessage = message => {
      this.notificationsStateFacade.addPushNotification(message.data as unknown as MessagePayload);
    };
    this.broadcastBackgroundPushClick.onmessage = message => {
      this.ngZone.run(() => {
        this.redirectUserOnPushNotificationClick(message.data as unknown as MessagePayload);
      });
    };
  }

  private redirectUserOnPushNotificationClick(messageData: MessagePayload) {
    const redirectUrl = this.notificationsService.getNotificationRedirectUrl(
      messageData as unknown as NotificationPayload,
      (messageData as unknown as PushNotificationPayload).notificationId
    );
    this.router
      .navigate([redirectUrl])
      .then(() => {
        this.notificationsService.markNotificationsRead([(messageData as any).notificationId]).subscribe(
          () => {},
          err => {
            console.log('Error marking push notification as read ' + (messageData as any).notificationId, err);
          }
        );
      })
      .catch(err => console.log('Error redirecting user on push notification click ' + (messageData as any).notificationId, err));
  }

  private closeBroadcastChannels() {
    if (this.broadcastBackgroundPush) {
      this.broadcastBackgroundPush.close();
    }
    if (this.broadcastBackgroundPushClick) {
      this.broadcastBackgroundPushClick.close();
    }
  }

  ngOnDestroy(): void {
    this.notifier$.next();
    this.notifier$.complete();
    this.document.body.classList.remove(this.LIMITED_WIDTH_CLASS);
    this.closeBroadcastChannels();
  }
}
