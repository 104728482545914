import {Component, ChangeDetectionStrategy, ElementRef, HostListener, HostBinding, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {isNull} from 'lodash-es';

@Component({
  selector: 'app-file-field',
  templateUrl: './file-field.component.html',
  styleUrls: ['./file-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileFieldComponent,
      multi: true
    }
  ]
})
export class FileFieldComponent implements ControlValueAccessor {
  @HostBinding('class.file-field') className: boolean = true;

  @Input() inputId!: string;
  public onChange!: Function;
  public touched!: Function;
  public fileTypes!: string;
  private file: File | null = null;

  @Input()
  set acceptFileTypes(fileTypes: string[]) {
    const types = fileTypes.map(fileType => `.${fileType}`);
    this.fileTypes = types.join(', ');
  }

  constructor(public host: ElementRef<HTMLInputElement>) {}

  @HostListener('change', ['$event.target.files'])
  emitFiles(event: FileList): void {
    const file = event && event.item(0);

    if (this.file && isNull(file)) return;

    this.onChange(file);
    this.file = file;
  }

  public writeValue(value: null): void {
    this.host.nativeElement.value = '';
    this.file = null;
  }

  public registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: Function): void {
    this.touched = fn;
  }
}
