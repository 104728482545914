import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Component, OnInit} from '@angular/core';

import {DecimalPipe} from '@angular/common';

import {IWallet} from '@wallex-core-client/core/interfaces/wallet.interface';

@Component({
  selector: 'app-success-transaction-dialog',
  templateUrl: './success-transaction-dialog.component.html',
  styleUrls: ['./success-transaction-dialog.component.scss'],
  providers: [DecimalPipe]
})
export class SuccessTransactionDialogComponent implements OnInit {
  amount!: number;
  account!: IWallet;
  rate!: number;
  isFiat!: boolean;
  currencySign!: string;
  decimalParameter!: string;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.config?.data) {
      this.amount = this.config.data?.amount;
      this.account = this.config.data?.account;
      this.rate = this.config.data?.rate;
      this.isFiat = this.config.data?.isFiat;
      this.currencySign = this.config.data?.currencySign;
      this.decimalParameter = this.config.data?.decimalParameter;
    }
  }

  public close(): void {
    this.ref.close();
  }
}
