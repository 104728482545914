import {MessagePayload} from '@angular/fire/messaging';
import {shareReplay} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';

import {SetUnreadNumber, SetPushToken, ClearData, AddPushNotification, ClearPushNotification} from '@wallex-core/store/notifications/notifications.actions';
import {INotificationsState, selectNotificationsState} from '@wallex-core/store/notifications/notifications-state.model';
import {UnreadCountDto} from '@wallex-core-client/core/dtos/notifications.dto';

@Injectable({
  providedIn: 'root'
})
export class NotificationsStateFacade {
  constructor(private readonly store$: Store<INotificationsState>) {}

  private readonly state$ = this.store$.pipe(select(selectNotificationsState));

  readonly unreadTotalNumber$ = this.state$.pipe(
    select(state => state.unreadTotalNumber),
    shareReplay(1)
  );

  readonly unreadMessagesNumber$ = this.state$.pipe(
    select(state => state.unreadMessagesNumber),
    shareReplay(1)
  );

  readonly unreadTransactionNotificationsNumber$ = this.state$.pipe(
    select(state => state.unreadTransactionNotificationsNumber),
    shareReplay(1)
  );

  readonly pushNotificationWithTransactionEvent$ = this.state$.pipe(
    select(state => state.pushNotificationWithTransactionEvent),
    shareReplay(1)
  );

  readonly pushNotificationWithMessage$ = this.state$.pipe(
    select(state => state.pushNotificationWithMessage),
    shareReplay(1)
  );

  readonly pushToken$ = this.state$.pipe(
    select(state => state.pushToken),
    shareReplay(1)
  );

  public setUnreadNumber(unreadNotificationsNumber: UnreadCountDto): void {
    this.store$.dispatch(new SetUnreadNumber(unreadNotificationsNumber));
  }

  public addPushNotification(notification: MessagePayload): void {
    this.store$.dispatch(new AddPushNotification(notification));
  }

  public clearPushNotification(): void {
    this.store$.dispatch(new ClearPushNotification());
  }

  public setPushToken(token: string): void {
    this.store$.dispatch(new SetPushToken(token));
  }

  public clearNotificationsData(): void {
    this.store$.dispatch(new ClearData());
  }
}
