import {blankNewsState, initialNewsState, INewsState} from '@wallex-core/store/news/news-state.model';
import {NewsActionType} from '@wallex-core/store/news/news.actions';
import {NeoBankingAction} from '@wallex-core/store/action.model';

export function newsReducer(state = initialNewsState, action: NeoBankingAction): INewsState {
  switch (action.type) {
    case NewsActionType.UPDATE_NEWS:
      return {
        ...state,
        news: action.news
      };

    case NewsActionType.CLEAR_NEWS:
      return {...blankNewsState};

    default:
      return state;
  }
}
