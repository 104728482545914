import {ActionReducerMap} from '@ngrx/store';

import {ISelectedWalletState, selectedWalletStateKey} from '@wallex-core/store/selected-wallet/selected-wallet-state.model';
import {INotificationsState, notificationsStateKey} from '@wallex-core/store/notifications/notifications-state.model';
import {selectedWalletReducer} from '@wallex-core/store/selected-wallet/selected-wallet-state.reducer';
import {ISettingsState, settingsStateKey} from '@wallex-core/store/settings/settings-state.model';
import {bannersStateKey, IBannersState} from '@wallex-core/store/banners/banners-state.model';
import {IWalletsState, walletsStateKey} from '@wallex-core/store/wallets/wallets-state.model';
import {ISavingsState, savingsStateKey} from '@wallex-core/store/savings/savings-state.model';
import {ICreditsState, creditsStateKey} from '@wallex-core/store/credits/credits-state.model';
import {notificationsReducer} from '@wallex-core/store/notifications/notifications.reducer';
import {assetsStateKey, IAssetsState} from '@wallex-core/store/assets/assets-state.model';
import {IbanStateInterface, ibanStateKey} from '@wallex-core/store/iban/iban-state.model';
import {IRatesState, ratesStateKey} from '@wallex-core/store/rates/rates-state.model';
import {cardsStateKey, ICardsState} from '@wallex-core/store/cards/cards-state.model';
import {IUserState, userStateKey} from '@wallex-core/store/user/user-state.model';
import {INewsState, newsStateKey} from '@wallex-core/store/news/news-state.model';
import {appStateKey, IAppState} from '@wallex-core/store/app/app-state.model';
import {settingsReducer} from '@wallex-core/store/settings/settings.reducer';
import {bannersReducer} from '@wallex-core/store/banners/banners.reducer';
import {walletsReducer} from '@wallex-core/store/wallets/wallets.reducer';
import {savingsReducer} from '@wallex-core/store/savings/savings.reducer';
import {creditsReducer} from '@wallex-core/store/credits/credits.reducer';
import {assetsReducer} from '@wallex-core/store/assets/assets.reducer';
import {ratesReducer} from '@wallex-core/store/rates/rates.reducer';
import {cardsReducer} from '@wallex-core/store/cards/cards.reducer';
import {userReducer} from '@wallex-core/store/user/user.reducer';
import {newsReducer} from '@wallex-core/store/news/news.reducer';
import {ibanReducer} from '@wallex-core/store/iban/iban.reducer';
import {NeoBankingAction} from '@wallex-core/store/action.model';
import {appReducer} from '@wallex-core/store/app/app.reducer';

export interface IState {
  readonly [appStateKey]: IAppState;
  readonly [walletsStateKey]: IWalletsState;
  readonly [assetsStateKey]: IAssetsState;
  readonly [userStateKey]: IUserState;
  readonly [bannersStateKey]: IBannersState;
  readonly [settingsStateKey]: ISettingsState;
  readonly [selectedWalletStateKey]: ISelectedWalletState;
  readonly [savingsStateKey]: ISavingsState;
  readonly [ratesStateKey]: IRatesState;
  readonly [ibanStateKey]: IbanStateInterface;
  readonly [newsStateKey]: INewsState;
  readonly [notificationsStateKey]: INotificationsState;
  readonly [creditsStateKey]: ICreditsState;
  readonly [cardsStateKey]: ICardsState;
}

export const reducers: ActionReducerMap<IState, NeoBankingAction> = {
  app: appReducer,
  wallets: walletsReducer,
  assets: assetsReducer,
  user: userReducer,
  banners: bannersReducer,
  settings: settingsReducer,
  savings: savingsReducer,
  selectedWallet: selectedWalletReducer,
  rates: ratesReducer,
  iban: ibanReducer,
  news: newsReducer,
  notifications: notificationsReducer,
  credits: creditsReducer,
  cards: cardsReducer
};
