import {select, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';

import {groupBy, maxBy, minBy} from 'lodash-es';
import {filter, map} from 'rxjs/operators';

import {
  ClearSavings,
  UpdateArchive,
  UpdateArchiveSaving,
  UpdateCurrentSaving,
  UpdatePlans,
  UpdateSavingActivity,
  UpdateSavingDeposit,
  UpdateSavings
} from '@wallex-core/store/savings/savings.actions';
import {IPlan, ISaving, ISavingActivity, ITablePlan} from '@wallex-core-client/core/interfaces/savings.interface';
import {ISavingsState, selectSavingsState} from '@wallex-core/store/savings/savings-state.model';
import {IResponsePagination} from '@wallex-core-client/core/interfaces/api-response.interface';

@Injectable({
  providedIn: 'root'
})
export class SavingsStateFacadeService {
  private readonly state$ = this.store$.pipe(select(selectSavingsState));

  constructor(private readonly store$: Store<ISavingsState>) {}

  readonly savingDeposit$ = this.state$.pipe(select(state => state.savingDeposit));

  readonly savings$ = this.state$.pipe(select(state => state.savings));

  readonly saving$ = this.state$.pipe(select(state => state.saving));

  readonly savingActivity$ = this.state$.pipe(select(state => state.savingActivity));

  readonly archive$ = this.state$.pipe(select(state => state.archive));

  readonly archiveSaving$ = this.state$.pipe(select(state => state.archiveSaving));

  readonly plans$ = this.state$.pipe(
    select(state => state.plans),
    filter(plans => Boolean(plans && plans?.length)),
    map(this.plansToTreeNode)
  );

  readonly flexiblePlans$ = this.state$.pipe(
    select(state => state.plans),
    filter(plans => Boolean(plans && plans?.length)),
    map(this.toFlexiblePlans),
    map(this.plansToTreeNode)
  );

  readonly fixedPlans$ = this.state$.pipe(
    select(state => state.plans),
    filter(plans => Boolean(plans && plans?.length)),
    map(this.toFixedPlans),
    map(this.plansToTreeNode)
  );

  readonly comboPlans$ = this.state$.pipe(
    select(state => state.plans),
    filter(plans => Boolean(plans && plans?.length)),
    map(this.toComboPlans),
    map(this.plansToTreeNode)
  );

  public updatePlans(plans: IResponsePagination<IPlan[]>): void {
    this.store$.dispatch(new UpdatePlans(plans.data));
  }

  public updateSavings(savings: IResponsePagination<ISaving[]>): void {
    this.store$.dispatch(new UpdateSavings(savings));
  }

  public updateCurrentSaving(saving: ISaving | null): void {
    this.store$.dispatch(new UpdateCurrentSaving(saving));
  }

  public updateSavingActivity(savingActivity: IResponsePagination<ISavingActivity[]>): void {
    this.store$.dispatch(new UpdateSavingActivity(savingActivity.data));
  }

  public updateArchive(archive: IResponsePagination<ISaving[]>): void {
    this.store$.dispatch(new UpdateArchive(archive));
  }

  public updateArchiveSaving(archiveSaving: ISaving): void {
    this.store$.dispatch(new UpdateArchiveSaving(archiveSaving));
  }

  public updateSavingDeposit(savingDeposit: string | null): void {
    this.store$.dispatch(new UpdateSavingDeposit(savingDeposit));
  }

  public clearSavings(): void {
    this.store$.dispatch(new ClearSavings());
  }

  private toFlexiblePlans(plans: IPlan[]): IPlan[] {
    return plans.filter(plan => !plan.duration);
  }

  private toFixedPlans(plans: IPlan[]): IPlan[] {
    return plans.filter(plan => plan.duration && !plan.bonusPercent);
  }

  private toComboPlans(plans: IPlan[]): IPlan[] {
    return plans.filter(plan => plan.duration && plan.bonusPercent);
  }

  private plansToTreeNode(plans: IPlan[]): ITablePlan[] {
    const changedPlans = plans.map(plan => ({
      ...plan,
      yearPercentage: Number(plan.yearPercentage),
      duration: Number(plan.duration),
      maxAPY: Number(plan.yearPercentage),
      shortcode: plan.asset.shortcode,
      bonusPercent: Number(plan.bonusPercent)
    }));

    const groupedPlans = groupBy(changedPlans, 'title');

    return Object.entries(groupedPlans).map(([title, plans]) => {
      return {
        data: {
          icon: plans[0].asset.icon,
          color: plans[0].asset.color,
          title,
          isParent: true,
          minAPY: minBy(plans, 'yearPercentage')?.yearPercentage,
          maxAPY: maxBy(plans, 'yearPercentage')?.yearPercentage,
          minDuration: minBy(plans, 'duration')?.duration,
          maxDuration: maxBy(plans, 'duration')?.duration,
          minBonusPercent: minBy(plans, 'bonusPercent')?.bonusPercent,
          maxBonusPercent: maxBy(plans, 'bonusPercent')?.bonusPercent
        },
        children: plans.map(plan => ({data: plan}))
      };
    });
  }
}
