import {NavigationEnd, Router} from '@angular/router';
import {filter, map, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {AppStateFacade} from '@wallex-core/store/app/app-state-facade.service';
import {Routes} from '@wallex-core/constants/routes.enum';

@Injectable({
  providedIn: 'root'
})
export class CustomRouterService {
  constructor(private router: Router, private appStateFacade: AppStateFacade) {}

  readonly pageTitle$ = this.router.events.pipe(
    filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd),
    tap(rs => {
      this.appStateFacade.addRouterHistory(rs.urlAfterRedirects);
    })
  );

  readonly isMainScreenActive$ = this.router.events.pipe(
    filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd),
    map(event => this.isMainScreenActive(event.urlAfterRedirects)),
    tap(isMainScreenActive => this.appStateFacade.setMainScreenActive(isMainScreenActive))
  );

  private isMainScreenActive(url: string): boolean {
    return url === '/' || url === Routes.dashboard;
  }
}
