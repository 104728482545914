import {ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanDeactivate, CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {UserStateFacade} from '@wallex-core/store/user/user-state-facade.service';
import {KycService} from '@wallex-core/services/kyc.service';
import {Routes} from '@wallex-core/constants/routes.enum';

@Injectable()
export class KycGuard implements CanDeactivate<any>, CanActivate {
  constructor(private router: Router, private userStateFacade: UserStateFacade, private kycService: KycService) {}

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.isCanToOpenRoute(nextState);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.isCanToOpenRoute(state);
  }

  private isCanToOpenRoute(state?: RouterStateSnapshot): Observable<boolean> {
    return this.userStateFacade.isKYCVerificationCompleted$.pipe(
      map(isKYCVerificationCompleted => {
        if (!state || (state && this.kycService.isUserAllowedToOpenRoute(state.url, isKYCVerificationCompleted))) {
          return true;
        }

        if (this.router.routerState.snapshot.url === '') {
          this.router.navigate([Routes.dashboard]).catch(() => null);
        }

        this.kycService.initKYC();
        return false;
      })
    );
  }
}
