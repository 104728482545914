import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, HostListener, Input, OnDestroy} from '@angular/core';
import {AbstractControl, UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';

import {ICountry} from '@wallex-core-client/core/interfaces/country.interface';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneNumberComponent implements OnDestroy, AfterViewInit {
  @HostBinding('class.phone-country') className: boolean = true;

  @Input() countries!: ICountry[];
  @Input() control!: UntypedFormControl | AbstractControl;
  @Input() defaultCountry?: ICountry | null;
  @Input() isControlInvalid: boolean = false;

  public phone!: string;
  private notifier = new Subject<void>();

  constructor(private el: ElementRef) {}

  @HostListener('window:resize', ['$event.target'])
  onResize() {
    this.setWidth();
  }

  ngAfterViewInit() {
    this.setWidth();
  }

  private setWidth(): void {
    const width = this.el.nativeElement.getBoundingClientRect().width;
    this.el.nativeElement.style.setProperty('--width', `${width}px`);
  }

  public updatePhone(): void {
    if (!this.phone) {
      this.control.patchValue(null);
      return;
    }

    const phone = this.defaultCountry?.phoneRoot + this.phone;
    this.control.patchValue(phone);
  }

  public calcFieldOffset(phoneSuffixElement: HTMLSpanElement): string {
    const {width} = phoneSuffixElement?.getBoundingClientRect();
    return `${width}px`;
  }

  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }
}
