import {format, isToday, isYesterday} from 'date-fns';
import {Pipe, PipeTransform} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'unixToDate'
})
export class UnixToDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(isoString: string, toFormat: string = 'H:mm'): string | null {
    if (!isoString) {
      return null;
    }

    return this.datePipe.transform(new Date(isoString), toFormat);
  }
}

@Pipe({
  name: 'extendedDate'
})
export class ExtendedDateFormatPipe implements PipeTransform {
  private readonly TODAY_TEXT = this.translate.instant('common.today');
  private readonly YESTERDAY_TEXT = this.translate.instant('common.yesterday');

  constructor(private translate: TranslateService, private datePipe: DatePipe) {}

  transform(date: string, toFormat: string, isExtendDates: boolean = false): string | null {
    const formattedDate = this.datePipe.transform(new Date(date), toFormat);

    if (isToday(new Date(date))) {
      return isExtendDates ? this.TODAY_TEXT : `${this.TODAY_TEXT}, ${formattedDate}`;
    }

    if (isYesterday(new Date(date))) {
      return isExtendDates ? this.YESTERDAY_TEXT : `${this.YESTERDAY_TEXT}, ${formattedDate}`;
    }

    if (new Date().getFullYear() === new Date(date).getFullYear()) {
      return formattedDate;
    }

    return this.datePipe.transform(new Date(date), 'd MMMM, yyyy');
  }
}
