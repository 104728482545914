import {blankCardsState, ICardsState, initialCardsState} from '@wallex-core/store/cards/cards-state.model';
import {CardsActionType} from '@wallex-core/store/cards/cards.actions';
import {NeoBankingAction} from '@wallex-core/store/action.model';

export function cardsReducer(state = initialCardsState, action: NeoBankingAction): ICardsState {
  switch (action.type) {
    case CardsActionType.ADD_DELIVERY_METHODS:
      return {
        ...state,
        deliveryMethods: action.deliveryMethods
      };

    case CardsActionType.ADD_CARDS:
      return {
        ...state,
        cards: action.cards
      };

    case CardsActionType.ADD_CARD_ADDRESS:
      return {
        ...state,
        cardAddress: action.cardAddress
      };

    case CardsActionType.ADD_PIN:
      return {
        ...state,
        pin: action.pin
      };

    case CardsActionType.ADD_CARD_ORDER_FEED:
      return {
        ...state,
        cardOrderFee: action.cardOrderFee
      };

    case CardsActionType.ADD_TRANSACTION_HISTORY:
      return {
        ...state,
        transactionHistory: action.transactionHistory
      };

    case CardsActionType.SET_LIMITS_STATUS:
      return {
        ...state,
        limitStatus: action.limitStatus
      };

    case CardsActionType.CLEAR_CARDS:
      return {...blankCardsState};

    default:
      return state;
  }
}
