import {NgModule} from '@angular/core';

import {DecimalPipe} from '@angular/common';

import {NumberFrPipe} from './number-fr.pipe';

@NgModule({
  imports: [],
  declarations: [NumberFrPipe],
  exports: [NumberFrPipe],
  providers: [DecimalPipe]
})
export class NumberFrPipeModule {}
