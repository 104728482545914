<div class="dashboard" [ngClass]="{'menu-opened': isMobileMenuOpened}">
  <app-left-menu (menuItemClicked)="openMobileMenu($event)" class="left-container" [ngClass]="{active: isMobileMenuOpened}"></app-left-menu>

  <div class="main-content">
    <app-header (openMobileMenu)="openMobileMenu($event)" [isMobileMenuOpened]="isMobileMenuOpened"></app-header>
    <div class="content-inner">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
