import {ApplicationRef, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {finalize} from 'rxjs/operators';

import {CookieService} from 'ngx-cookie-service';
import {Subject, takeUntil} from 'rxjs';

import {add} from 'date-fns';

import {LOCALE_COOKIE_KEY} from '@wallex-core-client/core/constants/main-constants';
import {UserStateFacade} from '@wallex-core/store/user/user-state-facade.service';
import {AppStateFacade} from '@wallex-core/store/app/app-state-facade.service';
import {UserService} from '@wallex-core/services/user.service';

@Component({
  selector: 'app-change-language-dialog',
  templateUrl: './change-language-dialog.component.html',
  styleUrls: ['./change-language-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeLanguageDialogComponent implements OnInit, OnDestroy {
  public form!: FormGroup;
  public isFetching = false;
  private notifier$ = new Subject<void>();

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private translate: TranslateService,
    private fb: FormBuilder,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private cookieService: CookieService,
    private userStateFacade: UserStateFacade,
    public appStateFacade: AppStateFacade,
    private appRef: ApplicationRef
  ) {}

  ngOnInit() {
    this.initializeForm();
    this.initializeValues();
  }

  private initializeForm(): void {
    this.form = this.fb.group({
      lang: [this.translate.currentLang, [Validators.required]]
    });
  }

  private initializeValues(): void {
    this.userStateFacade.user$.pipe(takeUntil(this.notifier$)).subscribe(user => {
      this.form.patchValue({lang: user.language});
    });
  }

  public onSubmit(): void {
    this.isFetching = true;

    this.userService
      .updateLanguage(this.form.value.lang)
      .pipe(
        finalize(() => {
          this.isFetching = false;
          this.cd.detectChanges();
        }),
        takeUntil(this.notifier$)
      )
      .subscribe(() => {
        const nextYear = add(new Date(), {years: 1});
        this.cookieService.set(LOCALE_COOKIE_KEY, this.form.value.lang, {expires: nextYear, path: '/'});
        window.location.reload();
      });
  }

  ngOnDestroy() {
    this.notifier$.next();
    this.notifier$.complete();
  }
}
