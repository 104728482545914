import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

import {UserStateFacade} from '@wallex-core/store/user/user-state-facade.service';
import {KycStatus} from '@wallex-core-client/core/interfaces/auth.interface';
import {KycService} from '@wallex-core/services/kyc.service';

@Component({
  selector: 'app-kyc-status',
  templateUrl: './kyc-status.component.html',
  styleUrls: ['./kyc-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KycStatusComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[];
  public kycStatus: KycStatus;
  public kycStatuses = KycStatus;

  constructor(private cdr: ChangeDetectorRef, private kycService: KycService, public userStateFacade: UserStateFacade) {}

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private initSubscriptions() {
    this.subscriptions = [
      this.userStateFacade.KYCStatus$.subscribe(kycStatus => {
        this.kycStatus = kycStatus;
        this.cdr.detectChanges();
      })
    ];
  }

  public initKyc(kycStatus: string): void {
    if (kycStatus === this.kycStatuses.approved) return;
    this.kycService.initKYCOnly();
  }
}
