import {Component, Input} from '@angular/core';

import {DecimalPipe} from '@angular/common';

import {IWallet} from '@wallex-core-client/core/interfaces/wallet.interface';

@Component({
  selector: 'app-total-amount-container',
  templateUrl: './total-amount-container.component.html',
  styleUrls: ['./total-amount-container.component.scss'],
  providers: [DecimalPipe]
})
export class TotalAmountContainerComponent {
  @Input() labelText = 'account.send-dialog.total-amount';
  @Input() totalAmount!: number;
  @Input() account!: IWallet;
  @Input() areFundsInsufficient!: boolean;
  @Input() isExternalAmountError = false;
  @Input() isShowAmountInRefCurrency!: boolean;
  @Input() totalAmountInRefCurrency!: number;
  @Input() currency?: string | null;
}
