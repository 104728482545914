import {blankSavingsState, initialSavingsState, ISavingsState} from '@wallex-core/store/savings/savings-state.model';
import {SavingsActionType} from '@wallex-core/store/savings/savings.actions';
import {NeoBankingAction} from '@wallex-core/store/action.model';

export function savingsReducer(state = initialSavingsState, action: NeoBankingAction): ISavingsState {
  switch (action.type) {
    case SavingsActionType.UPDATE_PLANS:
      return {
        ...state,
        plans: action.plans
      };

    case SavingsActionType.UPDATE_SAVING_DEPOSIT:
      return {
        ...state,
        savingDeposit: action.savingDeposit
      };

    case SavingsActionType.UPDATE_SAVINGS:
      return {
        ...state,
        savings: action.savings
      };

    case SavingsActionType.UPDATE_SAVING:
      return {
        ...state,
        saving: action.saving
      };

    case SavingsActionType.UPDATE_SAVING_ACTIVITY:
      return {
        ...state,
        savingActivity: action.savingActivity
      };

    case SavingsActionType.UPDATE_ARCHIVE:
      return {
        ...state,
        archive: action.archive
      };

    case SavingsActionType.UPDATE_ARCHIVE_SAVING:
      return {
        ...state,
        archiveSaving: action.archiveSaving
      };

    case SavingsActionType.CLEAR_SAVINGS:
      return {...blankSavingsState};

    default:
      return state;
  }
}
