import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dotToComma'
})
export class DotToCommaPipe implements PipeTransform {
  transform(value: number | string | null): string {
    if (!value) return '0';
    return String(value).replace('.', ',');
  }
}
