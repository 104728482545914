import {ChangeDetectionStrategy, Component, HostBinding, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';

import {IbanReceiveInfoInterface} from '@wallex-core-client/core/interfaces/iban.interface';

@Component({
  selector: 'app-receive-info-fiat-dialog',
  templateUrl: './receive-info-fiat-dialog.component.html',
  styleUrls: ['./receive-info-fiat-dialog.component.scss'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReceiveInfoFiatDialogComponent implements OnInit {
  @HostBinding('class.receive-fiat-dialog') className: boolean = true;

  public ibanReceiveInfo!: IbanReceiveInfoInterface;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private translate: TranslateService, private messageService: MessageService) {}

  ngOnInit(): void {
    this.ibanReceiveInfo = this.config.data.receiveInfo;
  }

  public close(event: MouseEvent): void {
    event.preventDefault();
    this.ref.close(false);
  }

  public copy(value: string, title: string): void {
    navigator.clipboard.writeText(value).catch(() => null);
    this.messageService.add({
      severity: 'info',
      detail: this.translate.instant('common.copied', {title}),
      sticky: false
    });
  }
}
