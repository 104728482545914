import {NgModule} from '@angular/core';

import {DatePipe} from '@angular/common';

import {ExtendedDateFormatPipe, UnixToDatePipe} from '@wallex-core-client/pipes';

@NgModule({
  declarations: [UnixToDatePipe, ExtendedDateFormatPipe],
  exports: [UnixToDatePipe, ExtendedDateFormatPipe],
  providers: [DatePipe]
})
export class FormatDatePipeModule {}
