import {shareReplay, map, combineLatestWith} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';

import {IAssetsState, selectAssetsState} from '@wallex-core/store/assets/assets-state.model';
import {WalletsStateFacade} from '@wallex-core/store/wallets/wallets-state-facade.service';
import {assetType, IAsset} from '@wallex-core-client/core/interfaces/asset.interface';
import {ClearAssets, LoadAssets} from '@wallex-core/store/assets/assets.actions';
import {IWallet} from '@wallex-core-client/core/interfaces/wallet.interface';
import {TopUpTypes} from '@wallex-core-client/core/dtos/wallet.dto';

@Injectable({
  providedIn: 'root'
})
export class AssetsStateFacade {
  constructor(private readonly store$: Store<IAssetsState>, private readonly walletsStateFacade: WalletsStateFacade) {}

  private readonly state$ = this.store$.pipe(select(selectAssetsState));

  readonly assets$ = this.state$.pipe(
    select(state => state.assets),
    shareReplay(1)
  );

  readonly allCryptoAssets$ = this.state$.pipe(
    select(state => state.assets),
    map(assets => {
      return assets.filter(asset => asset.assetType === assetType.crypto);
    }),
    shareReplay(1)
  );

  readonly exchangeAssets$ = this.assets$.pipe(
    map(assets => assets.filter(asset => asset.isExchangeSupported)),
    shareReplay(1)
  );

  readonly exchangeCryptoAssets$ = this.exchangeAssets$.pipe(
    map(assets => assets.filter(asset => asset.assetType === assetType.crypto)),
    shareReplay(1)
  );

  readonly assetsWithSupportedTopUpByCard$ = this.assets$.pipe(
    map(assets => assets.filter(asset => asset.availableTopUpTypes.includes(TopUpTypes.CARD))),
    shareReplay(1)
  );

  readonly isBuyCryptoButtonShown$ = this.assetsWithSupportedTopUpByCard$.pipe(
    map(assetsWithSupportedTopUpByCard => {
      return !!assetsWithSupportedTopUpByCard.length;
    }),
    shareReplay(1)
  );

  readonly availableAssets$ = this.assets$.pipe(
    combineLatestWith(this.walletsStateFacade.wallets$),
    map(([assets, wallets]) => {
      if (!assets || !assets.length) {
        return [];
      }
      if (!wallets || !wallets.length) {
        return assets;
      }
      return assets.filter(asset => !wallets.some(wallet => wallet.asset === asset.shortcode));
    }),
    shareReplay(1)
  );

  readonly cryptoAssets$ = this.availableAssets$.pipe(
    map(assets => {
      return assets.filter(asset => asset.assetType === assetType.crypto);
    }),
    shareReplay(1)
  );

  readonly fiatAssets$ = this.availableAssets$.pipe(
    map(assets => {
      return assets.filter(asset => asset.assetType === assetType.fiat);
    }),
    shareReplay(1)
  );

  readonly isAssetsLoadingFailure$ = this.state$.pipe(
    select(state => state.isAssetsLoadingFailure),
    shareReplay(1)
  );

  public getAssetById$(id: string): Observable<IAsset | undefined> {
    return this.assets$.pipe(map(assets => assets.find(asset => asset.id === id)));
  }

  public getAssetByShortCode$(shortCode: string): Observable<IAsset | undefined> {
    return this.assets$.pipe(map(assets => assets.find(asset => asset.shortcode === shortCode)));
  }

  public getAssetsByShortCodes$(shortcodes: string[]): Observable<IAsset[]> {
    return this.assets$.pipe(map(assets => assets.filter(asset => shortcodes.includes(asset.shortcode))));
  }

  public loadAssets(): void {
    this.store$.dispatch(new LoadAssets());
  }

  public clearAssets(): void {
    this.store$.dispatch(new ClearAssets());
  }
}
