import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'cryptoToFiat'
})
export class CryptoToFiatPipe implements PipeTransform {
  constructor() {}

  transform(amount: string | number, rate: number): number {
    const value = typeof amount === 'string' ? parseFloat(amount.replace(',', '.')) : amount;
    return value * rate;
  }
}
