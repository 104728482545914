import {Pipe} from '@angular/core';

import {cropNumber} from '@wallex-core-client/utils';

@Pipe({name: 'cropNumber'})
export class CropNumberPipe {
  constructor() {}

  transform(value: string | number | undefined | null, decimals: number | undefined | null | string) {
    if (!value) return 0;

    if (!decimals) return value;

    return cropNumber(value, decimals, true);
  }
}
