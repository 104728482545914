import {Component, ChangeDetectionStrategy, Input} from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {
  @Input() isIndividualAccount: boolean | null = true;
  @Input() profileAvatar?: null | string;
  @Input() width: string = '40px';
  @Input() height: string = '40px';

  constructor() {}
}
