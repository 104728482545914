import {IBannersState, initialBannersState} from '@wallex-core/store/banners/banners-state.model';
import {BannersActionType} from '@wallex-core/store/banners/banners.actions';
import {NeoBankingAction} from '../action.model';

export function bannersReducer(state = initialBannersState, action: NeoBankingAction): IBannersState {
  switch (action.type) {
    case BannersActionType.LOAD_MAIN_SCREEN_BANNERS_SUCCESS:
      return {
        ...state,
        mainScreenBanners: action.banners
      };

    case BannersActionType.ADD_BANNERS_TO_AUTH_SCREEN:
      return {
        ...state,
        authScreenBanners: action.banners
      };

    case BannersActionType.DELETE_ALL_BANNERS:
      return initialBannersState;

    default:
      return state;
  }
}
