import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';

import {
  AddCountries,
  LoadAutoConversionCurrencies,
  LoadAutoConversionCurrenciesFailure,
  LoadGeneralSettings,
  LoadGeneralSettingsFailure,
  LoadCountries,
  LoadCountriesFailure,
  SettingsActionType,
  UpdateAutoConversionCurrencies,
  UpdateGeneralSettings
} from '@wallex-core/store/settings/settings.actions';
import {IGeneralSettings} from '@wallex-core-client/core/interfaces/general-settings.interface';
import {IAppCountries} from '@wallex-core-client/core/interfaces/country.interface';
import {AppStateFacade} from '@wallex-core/store/app/app-state-facade.service';
import {IAsset} from '@wallex-core-client/core/interfaces/asset.interface';
import {SettingsService} from '@wallex-core/services/settings.service';

@Injectable()
export class SettingsEffects {
  constructor(private actions$: Actions, private settingsService: SettingsService, private appStateFacade: AppStateFacade) {}

  readonly loadAutoConversionCurrencies$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadAutoConversionCurrencies>(SettingsActionType.LOAD_AUTO_CONVERSION_CURRENCIES),
      switchMap(() => this.settingsService.getAutoConversionCurrencies()),
      mergeMap((autoConversionCurrencies: IAsset[]) => [new UpdateAutoConversionCurrencies(autoConversionCurrencies)]),
      catchError(response => {
        this.appStateFacade.handleFatalError(response);
        return [new LoadAutoConversionCurrenciesFailure()];
      })
    )
  );

  readonly loadGeneralSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadGeneralSettings>(SettingsActionType.LOAD_GENERAL_SETTINGS),
      switchMap(() => this.settingsService.getGeneralSettings()),
      mergeMap((settings: IGeneralSettings) => [new UpdateGeneralSettings(settings)]),
      catchError(response => {
        this.appStateFacade.handleFatalError(response);
        return [new LoadGeneralSettingsFailure()];
      })
    )
  );

  readonly loadCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadCountries>(SettingsActionType.LOAD_COUNTRIES),
      switchMap(() =>
        this.settingsService.getCountries().pipe(
          mergeMap((response: IAppCountries) => [new AddCountries(response)]),
          catchError(response => {
            this.appStateFacade.handleFatalError(response);
            return [new LoadCountriesFailure()];
          })
        )
      )
    )
  );
}
