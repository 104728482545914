import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent {
  public defaultContainerWidth = '172px';
  @Input() width: string = this.defaultContainerWidth;
  @Input() label!: string;
  @Input() icon!: string;
  @Input() disabled: boolean = false;
  @Output() onClick: EventEmitter<void> = new EventEmitter();

  onButtonClick() {
    this.onClick.emit();
  }
}
