import {TranslateModule} from '@ngx-translate/core';
import {DropdownModule} from 'primeng/dropdown';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';

import {NumberOnlyDirectiveModule} from '@wallex-core-client/directives';
import {PhoneNumberComponent} from '@wallex-core-client/ui';

@NgModule({
  declarations: [PhoneNumberComponent],
  imports: [DropdownModule, FormsModule, TranslateModule, CommonModule, NumberOnlyDirectiveModule],
  exports: [PhoneNumberComponent]
})
export class PhoneNumberModule {}
