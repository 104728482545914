import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {MinAmountDirectiveModule, NumberOnlyDirectiveModule} from '@wallex-core-client/directives';
import {NumberFrPipeModule, StringToNumberPipeModule} from '@wallex-core-client/pipes';
import {AmountInputComponent} from '@wallex-core-client/ui';

@NgModule({
  imports: [CommonModule, TranslateModule, StringToNumberPipeModule, NumberOnlyDirectiveModule, MinAmountDirectiveModule, NumberFrPipeModule],
  declarations: [AmountInputComponent],
  exports: [AmountInputComponent]
})
export class AmountInputModule {}
