import {NgModule} from '@angular/core';

import {TransactionStateCardComponent} from './transaction-state-card.component';

@NgModule({
  declarations: [TransactionStateCardComponent],
  exports: [TransactionStateCardComponent],
  imports: []
})
export class TransactionStateCardModule {}
