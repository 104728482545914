import {IAsset} from '@wallex-core-client/core/interfaces/asset.interface';

export interface IRateResponse {
  [currency: string]: {
    [asset: string]: number;
  };
}

export class IRateModel {
  asset: string;
  rate: number;
}

export interface IExchangeRate {
  rate: number;
  fee: number;
  assetPair: string;
  baseAsset: string;
  expireTime: number;
  storeKey: string;
}

export interface IWalletChartResponse {
  timestamp: number;
  price: number;
}

export interface IWalletChart {
  data: IWalletChartResponse[];
  asset: string;
}

export interface ITransformedWalletChart {
  asset: string;
  timestamps: Date[];
  prices: number[];
  diffRate: string;
  isGrowing: boolean;
}

export interface IPriceChange {
  asset: string;
  diffRate: number;
}

export interface IMarketListResponse {
  rates: Record<string, IRateModel>;
  charts: IWalletChart[];
  priceChanges: Record<string, IPriceChange>;
  assets: IAsset[];
}

export interface IMarketList {
  rate: IRateModel;
  chart?: ITransformedWalletChart;
  priceChange: IPriceChange;
  asset: IAsset;
  isEarner: boolean;
}

export interface IMarketDetailsResponse {
  rate: Record<string, IRateModel>;
  dayChart: IWalletChart[];
  weekChart: IWalletChart[];
  monthChart: IWalletChart[];
  threeMonthChart: IWalletChart[];
  yearChart: IWalletChart[];
  asset: IAsset;
  isError?: boolean;
}

export interface IMarketDetails {
  rate: IRateModel;
  dayChart?: ITransformedWalletChart;
  weekChart?: ITransformedWalletChart;
  monthChart?: ITransformedWalletChart;
  threeMonthChart?: ITransformedWalletChart;
  yearChart?: ITransformedWalletChart;
}

export enum ChartInterval {
  EVERY_MINUTE = 'every_minute',
  EVERY_FIVE_MINUTES = 'every_five_minutes',
  EVERY_TEN_MINUTES = 'every_ten_minutes',
  EVERY_FIFTEEN_MINUTES = 'every_fifteen_minutes',
  EVERY_THIRTY_MINUTES = 'every_thirty_minutes',
  HOURLY = 'hourly',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly'
}

export enum ChartColors {
  INCREASE = '#16BC5A',
  DECREASE = '#EE6B6D'
}

export enum ChartGradient {
  NEGATIVE_STOP1 = 'rgba(204, 48, 48, 0.5)',
  NEGATIVE_STOP2 = 'rgba(204, 48, 48, 0.35)',
  NEGATIVE_STOP3 = 'rgba(204, 48, 48, 0.2)',
  NEGATIVE_STOP4 = 'rgba(204, 48, 48, 0.05)',
  NEGATIVE_STOP5 = 'rgba(204, 48, 48, 0)',

  POSITIVE_STOP1 = 'rgba(22, 188, 90, 0.5)',
  POSITIVE_STOP2 = 'rgba(22, 188, 90, 0.35)',
  POSITIVE_STOP3 = 'rgba(22, 188, 90, 0.2)',
  POSITIVE_STOP4 = 'rgba(22, 188, 90, 0.05)',
  POSITIVE_STOP5 = 'rgba(22, 188, 90, 0)'
}
