import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, forkJoin} from 'rxjs';
import {format} from 'date-fns';

import {IBirthFields, IMonth} from '@wallex-core-client/core/interfaces/settings-helper.interface';
import {DATE_FORMAT, ENVIRONMENT_TOKEN} from '@wallex-core-client/core/constants/main-constants';
import {IAsset} from '@wallex-core-client/core/interfaces/asset.interface';

import {map} from 'rxjs/operators';

import {TranslateService} from '@ngx-translate/core';

import {IAutoConversionPayload, IAutoConversionStatus} from '@wallex-core-client/core/interfaces/auto-conversion.interface';
import {IAppCountries, ICountry, ICountryResponse} from '@wallex-core-client/core/interfaces/country.interface';
import {IGeneralSettings} from '@wallex-core-client/core/interfaces/general-settings.interface';
import {IEnvironment} from '@wallex-core-client/core/interfaces/common.interface';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private host!: string;

  constructor(private http: HttpClient, @Inject(ENVIRONMENT_TOKEN) private environment: IEnvironment, private translate: TranslateService) {
    this.host = environment.serverBaseUri;
  }

  public getGeneralSettings(): Observable<IGeneralSettings> {
    return this.http.get<IGeneralSettings>(`${this.host}/settings/auto-conversion`);
  }

  public getAutoConversionCurrencies(): Observable<IAsset[]> {
    return this.http.get<IAsset[]>(`${this.host}/settings/auto-conversion/currencies`);
  }

  public changeAutoConversion(payload: IAutoConversionPayload): Observable<IAutoConversionStatus> {
    return this.http.patch<IAutoConversionStatus>(`${this.host}/settings/auto-conversion`, payload);
  }

  public changeBalancesVisibility(isBalanceSeen: boolean): Observable<IGeneralSettings> {
    return this.http.patch<IGeneralSettings>(`${this.host}/settings/balance/seen`, {isBalanceSeen});
  }

  public getCountries(): Observable<IAppCountries> {
    return forkJoin([this.getAllCountries(), this.getProfileCountries()]).pipe(
      map(([countries, profileCountriesCodes]) => ({
        countries,
        profileCountriesCodes
      }))
    );
  }

  public getAllCountries(): Observable<ICountry[]> {
    return this.http.get<ICountryResponse[]>(`${this.host}/countries`).pipe(map(response => this.fromCountriesDTO(response)));
  }

  public getProfileCountries(): Observable<string[]> {
    return this.http.get<string[]>(`${this.host}/countries/allowed`);
  }

  private fromCountriesDTO(countries: ICountryResponse[]): ICountry[] {
    return countries
      .map(country => {
        const countryItem: ICountry = {
          code: country.cca3Code,
          flag: country.flags.svg,
          name: country.name,
          phoneRoot: country.phoneRoot,
          phoneSuffixes: country.phoneSuffixes
        };
        return countryItem;
      })
      .sort(this.sortCountries);
  }

  private sortCountries = (a: ICountry, b: ICountry) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  };

  public getBirthFields(birthDate: string | null): IBirthFields | null {
    if (!birthDate) return null;

    const result = format(new Date(birthDate), DATE_FORMAT).split('/');

    return {day: result[0], month: result[1], year: result[2]};
  }

  public fieldToBirth(day: string, month: string, year: string): string {
    return `${year}-${month}-${day}`;
  }

  public get getMonths$(): Observable<IMonth[]> {
    return this.translate.get('primeng').pipe(
      map(translations => {
        return Object.values(translations.monthNames).map((el: any, index: number) => {
          return {title: el, id: String(++index).padStart(2, '0')};
        });
      })
    );
  }
}
