import {Pipe, PipeTransform} from '@angular/core';

/**
 * @deprecated this functionality will be removed in the future,
 * use {@link SafeHtmlPipe} pipe
 */
@Pipe({
  name: 'imgSrcToBackground'
})
export class ImgSrcToBackground implements PipeTransform {
  transform(src: string): string {
    if (src !== 'none') {
      return `url('${src}')`;
    }
    return 'none';
  }
}
