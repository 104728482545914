import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-default-dialog',
  templateUrl: './default-dialog.component.html',
  styleUrls: ['./default-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultDialogComponent implements OnInit {
  public message!: string;
  public okayButtonLabel!: string;
  public isShowCloseButton!: boolean;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    this.message = this.config.data?.message;
    this.okayButtonLabel = this.config.data?.okayButtonLabel;
    this.isShowCloseButton = this.config.data?.isShowCloseButton ?? true;
  }

  public close(): void {
    this.ref.close(false);
  }

  public confirm(): void {
    this.ref.close(true);
  }
}
