import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'numberLimit'
})
export class NumberLimitPipe implements PipeTransform {
  transform(value: number, limit: number = 2): string | number {
    if (!value) return value;
    const maxValue = Math.pow(10, limit);
    return value < maxValue ? value : `${maxValue - 1}+`;
  }
}
