import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function maxFileSizeValidator(maxSizeInMB: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const file = control.value;

    if (!file) return null;

    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    if (control.value.size >= maxSizeInBytes) {
      return {maxFileSizeError: true};
    }

    return null;
  };
}

export function acceptedFileTypesValidator(acceptedTypes: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const file = control.value;

    if (!file) return null;

    const fileType = file.name.split('.').pop().toLowerCase();
    const fileTypes = acceptedTypes.map(type => type.toLowerCase());

    if (!fileTypes.includes(fileType)) {
      return {fileTypeError: true};
    }

    return null;
  };
}
