import {format, isThisYear, isToday, isYesterday} from 'date-fns';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dayGrouping'
})
export class DayGroupingPipe implements PipeTransform {
  transform(dateValue: string): string {
    const date = new Date(dateValue);

    let formattedDate = format(date, 'd MMMM');
    let datePrefix = '';

    switch (true) {
      case isToday(date):
        datePrefix = 'Today, ';
        break;
      case isYesterday(date):
        datePrefix = 'Yesterday, ';
        break;
      case !isThisYear(date):
        formattedDate = format(date, 'd MMMM yyyy');
        break;
    }

    return `${datePrefix + formattedDate}`;
  }
}
