<p-toast class="toast-message" [ngClass]="{waiting: appStateFacade.isWaiting}"></p-toast>

<div class="header">
  <div class="header__container">
    <ng-container [ngTemplateOutlet]="titleContainer" [ngTemplateOutletContext]="{$implicit: '--desktop'}"></ng-container>

    <div class="header__logo" (click)="toDashboard()">
      <img class="desktop-logo" src="/assets/logo.svg" alt="" width="105" height="24" />
      <img class="mobile-logo" src="/assets/logo-icon.svg" alt="" width="42" height="36" />
    </div>

    <div class="change-message" [ngClass]="{waiting: appStateFacade.isWaiting}" *ngIf="appStateFacade.fieldChanged$ | async">
      {{ appStateFacade.changeStatusText }}
    </div>

    <div class="user-profile">
      <!-- Buy crypto button -->
      <button *ngIf="assetsStateFacade.isBuyCryptoButtonShown$ | async" class="buy-crypto-mobile" (click)="openBuyCrypto()">
        {{ 'buy_crypto.short_title' | translate }}
        <img src="/assets/icons/bitcoin.svg" width="14" height="14" />
      </button>
      <button *ngIf="assetsStateFacade.isBuyCryptoButtonShown$ | async" class="buy-crypto-desktop" (click)="openBuyCrypto()">
        {{ 'buy_crypto.title' | translate }}
      </button>

      <!-- additional-solutions-button -->
      <img
        *ngIf="isAdditionalSolutionsMenuShown"
        class="additional-solutions-button"
        src="/assets/buttons/additional-solutions.svg"
        width="20"
        height="20"
        alt="additional solutions"
        (click)="additionalSolutions.toggle($event)"
      />

      <img *ngIf="(assetsStateFacade.isBuyCryptoButtonShown$ | async) || isAdditionalSolutionsMenuShown" src="/assets/line.svg" width="1" height="26" />
      <!-- rewards-button -->
      <img class="rewards-button" src="/assets/buttons/rewards.svg" width="20" height="20" alt="rewards" (click)="rewards.toggle($event)" />

      <app-notification-bell></app-notification-bell>

      <button class="reset-btn overlay-btn" data-qa="profile" type="button" (click)="op.toggle($event)">
        <app-avatar
          [profileAvatar]="userStateFacade.profileAvatar$ | async"
          [isIndividualAccount]="userStateFacade.isIndividualAccount$ | async"
          width="40px"
          height="40px"
        ></app-avatar>
      </button>

      <app-burger-animation class="menu-btn" (click)="onOpenMenu()" [ngClass]="{active: isMobileMenuOpened}"></app-burger-animation>
    </div>
  </div>

  <div class="header__container--mobile">
    <ng-container [ngTemplateOutlet]="titleContainer" [ngTemplateOutletContext]="{$implicit: '--mobile'}"></ng-container>

    <div class="header__container-navs" *ngIf="appStateFacade.isExchangeButtonVisible$ | async">
      <button appKycGuard (onGuardPassed)="toExchange()" [label]="'exchange.title' | translate" type="button" pButton class="button-sm button--mobile"></button>
      <button appKycGuard (onGuardPassed)="toSwap()" [label]="'main_screen.swap' | translate" type="button" pButton class="semi-button button--mobile"></button>
    </div>
  </div>
</div>

<p-overlayPanel styleClass="header__overlay additional-solutions-overlay" #additionalSolutions [style]="{'min-width': '330px'}">
  <ng-template pTemplate>
    <app-additional-solutions></app-additional-solutions>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel styleClass="header__overlay rewards-overlay" #rewards [style]="{'min-width': '242px'}">
  <ng-template pTemplate>
    <app-rewards (buttonClicked)="rewards.hide()"></app-rewards>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel styleClass="header__overlay user-overlay" #op [style]="{'min-width': '330px'}">
  <ng-template pTemplate>
    <app-individual-overlay
      *ngIf="userStateFacade.isIndividualAccount$ | async"
      [userProfile]="userStateFacade.userProfile$ | async"
      [email]="(userStateFacade.user$ | async)?.email"
      [isIndividualAccount]="userStateFacade.isIndividualAccount$ | async"
      [businessAccounts]="userStateFacade.businessAccounts$ | async"
      [profileAvatar]="userStateFacade.profileAvatar$ | async"
      (toSettings)="toSettings(op)"
      (openLogout)="openLogout(op)"
    ></app-individual-overlay>

    <app-business-overlay
      *ngIf="userStateFacade.isBusinessAccount$ | async"
      [user]="userStateFacade.user$ | async"
      [currentOrganization]="userStateFacade.currentOrganization$ | async"
      [profileAvatar]="userStateFacade.profileAvatar$ | async"
      (toSettings)="toSettings(op)"
      (openLogout)="openLogout(op)"
    ></app-business-overlay>

    <button class="reset-btn change-language" type="button" (click)="onChangeLanguage()">
      <img src="/assets/planet.svg" height="24" width="24" alt="Language switcher" />
      <p class="change-language__text" *ngIf="currentLanguage | async as lang">{{ lang.name }} ({{ lang.code | uppercase }})</p>
    </button>
  </ng-template>
</p-overlayPanel>

<ng-container *ngIf="appStateFacade.headerMessage$ | async as message">
  <p-dialog
    header="Header"
    [(visible)]="isShowInfoHint"
    [dismissableMask]="true"
    [modal]="true"
    role="section"
    [style]="{width: '564px'}"
    [draggable]="false"
    [resizable]="false"
    styleClass="popup-dialog info-dialog"
  >
    <ng-template pTemplate="header">
      <h1 class="popup-dialog__title">{{ message.title }}</h1>
    </ng-template>
    <app-header-message-text [message]="message"></app-header-message-text>
    <ng-template pTemplate="footer">
      <p-button (click)="isShowInfoHint = false" [label]="'common.okay' | translate"></p-button>
    </ng-template>
  </p-dialog>
</ng-container>

<ng-template #titleContainer let-selector>
  <div class="title-container" [ngClass]="[selector]">
    <div class="back-button" *ngIf="(appStateFacade.title$ | async) && (appStateFacade.canGoBack$ | async)" (click)="appStateFacade.goBack()"></div>
    <div class="title">
      {{ appStateFacade.title$ | async }}
      <button class="info-circle" type="button" *ngIf="appStateFacade.headerMessage$ | async" (click)="isShowInfoHint = true">
        <img width="20" height="20" src="assets/info-circle.svg" alt="Info" />
      </button>
    </div>
  </div>
</ng-template>
