import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {finalize} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {SKIP_LOADER_INTERCEPTOR} from '@wallex-core-client/core/constants/main-constants';
import {AppStateFacade} from '@wallex-core/store/app/app-state-facade.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private activeRequests: number = 0;

  constructor(private readonly appStateFacade: AppStateFacade) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(SKIP_LOADER_INTERCEPTOR.title)) {
      return next.handle(req);
    }

    this.activeRequests++;
    this.onStart();

    return next.handle(req).pipe(
      finalize(() => {
        this.activeRequests--;
        if (this.activeRequests === 0) {
          this.onEnd();
        }
      })
    );
  }

  private onStart(): void {
    this.appStateFacade.setLoadingStatus(true);
  }

  private onEnd(): void {
    this.appStateFacade.setLoadingStatus(false);
  }
}
