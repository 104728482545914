import {mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {from, of} from 'rxjs';

import {AppActionType, GoBack, GoBackSuccess} from '@wallex-core/store/app/app.actions';
import {AppStateFacade} from '../app/app-state-facade.service';

@Injectable()
export class AppEffects {
  constructor(private readonly actions$: Actions, private readonly appStateFacade: AppStateFacade, private readonly router: Router) {}

  readonly goBack$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GoBack>(AppActionType.GO_BACK),
      withLatestFrom(this.appStateFacade.lastAndCurrentVisitedRoute$),
      switchMap(([, routes]) => {
        if (routes?.length) {
          return from(this.router.navigate([routes[0].split('?')[0]], {state: {lastVisitedRoute: routes[1]}}));
        }
        return of(history.back());
      }),
      mergeMap(() => [new GoBackSuccess()])
    )
  );
}
