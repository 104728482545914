import {catchError, filter, mergeMap, switchMap, throttleTime, withLatestFrom} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {forkJoin, of} from 'rxjs';

import {
  LoadMarketDetails,
  LoadMarketDetailsFailure,
  LoadMarketDetailsSuccess,
  LoadMarketList,
  LoadMarketListFailure,
  LoadMarketListSuccess,
  LoadRatesFailure,
  LoadRatesSuccess,
  LoadWalletChart,
  LoadWalletChartFailure,
  LoadWalletCharts,
  LoadWalletChartsFailure,
  LoadWalletChartsSuccess,
  LoadWalletChartSuccess,
  RatesActionType
} from '@wallex-core/store/rates/rates.actions';
import {ChartInterval, IMarketDetailsResponse, IMarketListResponse} from '@wallex-core-client/core/interfaces/rate.interface';
import {WalletsStateFacade} from '@wallex-core/store/wallets/wallets-state-facade.service';
import {RatesStateFacade} from '@wallex-core/store/rates/rates-state-facade.service';
import {UserStateFacade} from '@wallex-core/store/user/user-state-facade.service';
import {assetType} from '@wallex-core-client/core/interfaces/asset.interface';
import {WalletsActionType} from '@wallex-core/store/wallets/wallets.actions';
import {RatesService} from '@wallex-core/services/rate.service';
import {getPreviousDateISO} from '@wallex-core-client/utils';

@Injectable()
export class RatesEffects {
  private readonly REJECT_PERIOD = 1000;

  constructor(
    private actions$: Actions,
    private userStateFacade: UserStateFacade,
    private ratesService: RatesService,
    private walletsStateFacade: WalletsStateFacade,
    private ratesStateFacade: RatesStateFacade
  ) {}

  readonly loadRates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RatesActionType.LOAD_RATES, WalletsActionType.LOAD_WALLETS_SUCCESS),
      throttleTime(this.REJECT_PERIOD),
      withLatestFrom(this.userStateFacade.currency$, this.walletsStateFacade.wallets$),
      filter(([_, currency, wallets]) => wallets && Boolean(wallets.length)),
      switchMap(([_, currency, wallets]) => this.ratesService.getRatesForUserAccounts(currency, wallets)),
      mergeMap(rates => [new LoadRatesSuccess(rates)]),
      catchError(() => of(new LoadRatesFailure()))
    )
  );

  readonly loadWalletCharts$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadWalletCharts>(RatesActionType.LOAD_WALLET_CHARTS),
      throttleTime(this.REJECT_PERIOD),
      withLatestFrom(this.userStateFacade.currency$, this.walletsStateFacade.cryptoWallets$),
      switchMap(([_, currency, wallets]) => {
        return this.ratesService.getWalletCharts(currency, wallets, ChartInterval.HOURLY, 1);
      }),
      mergeMap(walletCharts => [new LoadWalletChartsSuccess(walletCharts)]),
      catchError(() => of(new LoadWalletChartsFailure()))
    )
  );

  readonly updateWalletCharts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RatesActionType.LOAD_RATES),
      throttleTime(this.REJECT_PERIOD),
      withLatestFrom(this.userStateFacade.currency$, this.walletsStateFacade.cryptoWallets$),
      switchMap(([_, currency, wallets]) => {
        return this.ratesService.getWalletCharts(currency, wallets, ChartInterval.HOURLY, 1);
      }),
      mergeMap(walletCharts => [new LoadWalletChartsSuccess(walletCharts)]),
      catchError(() => of(new LoadWalletChartsFailure()))
    )
  );

  readonly loadWalletChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadWalletChart>(RatesActionType.LOAD_WALLET_CHART),
      withLatestFrom(this.userStateFacade.currency$),
      filter(([_]) => _.wallet.assetType === assetType.crypto),
      switchMap(([_, currency]) => this.ratesService.getWalletChart(_.wallet.asset, currency, ChartInterval.HOURLY, getPreviousDateISO(1))),
      mergeMap(walletChart => [new LoadWalletChartSuccess(walletChart)]),
      catchError(() => of(new LoadWalletChartFailure()))
    )
  );

  readonly loadMarketList$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadMarketList>(RatesActionType.LOAD_MARKET_LIST),
      withLatestFrom(this.userStateFacade.currency$),
      switchMap(([_, currency]) => {
        const shortcodes = _.assets.map(asset => ({asset: asset.shortcode}));
        return forkJoin({
          rates: this.ratesService.getRatesForUserAccounts(currency, shortcodes),
          charts: this.ratesService.getWalletCharts(currency, shortcodes, ChartInterval.EVERY_FIFTEEN_MINUTES, 1),
          priceChanges: this.ratesService.getPriceChanges(currency, shortcodes),
          assets: of(_.assets),
          isError: of(false)
        }).pipe(catchError(() => of({isError: true})));
      }),
      mergeMap(marketList => {
        if (marketList.isError) return [new LoadMarketListFailure()];

        const result = this.ratesStateFacade.transformMarketList(marketList as IMarketListResponse);
        return [new LoadMarketListSuccess(result)];
      })
    )
  );

  readonly loadMarketDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadMarketDetails>(RatesActionType.LOAD_MARKET_DETAILS),
      switchMap(({currency, asset}) => {
        const shortcode = [{asset: asset.shortcode}];

        return forkJoin({
          rate: this.ratesService.getRatesForUserAccounts(currency, shortcode),
          dayChart: this.ratesService.getWalletCharts(currency, shortcode, ChartInterval.EVERY_FIFTEEN_MINUTES, 1),
          weekChart: this.ratesService.getWalletCharts(currency, shortcode, ChartInterval.HOURLY, 7),
          monthChart: this.ratesService.getWalletCharts(currency, shortcode, ChartInterval.DAILY, 30),
          threeMonthChart: this.ratesService.getWalletCharts(currency, shortcode, ChartInterval.DAILY, 90),
          yearChart: this.ratesService.getWalletCharts(currency, shortcode, ChartInterval.WEEKLY, 365),
          asset: of(asset),
          isError: of(false)
        }).pipe(catchError(() => of({isError: true})));
      }),
      mergeMap(chartDetails => {
        if (chartDetails.isError) return [new LoadMarketDetailsFailure()];

        const result = this.ratesStateFacade.transformChartDetails(chartDetails as IMarketDetailsResponse);

        if (!result.rate.rate || !result.dayChart || !result.weekChart || !result.monthChart || !result.threeMonthChart || !result.yearChart) {
          return [new LoadMarketDetailsFailure()];
        }

        return [new LoadMarketDetailsSuccess(result)];
      })
    )
  );
}
