import {Routes} from '@wallex-core/constants/routes.enum';

export const MENU_ITEMS = [
  {label: 'dashboard.menu.dashboard', icon: 'dashboard-menuitem', routerLink: ['./'], routerLinkActiveOptions: {exact: true}},
  {
    label: 'dashboard.menu.accounts',
    icon: 'accounts-menuitem',
    routerLink: [Routes.all_accounts],
    routerLinkActiveOptions: {exact: true}
  },
  {
    label: 'dashboard.menu.exchange',
    icon: 'exchange-menuitem',
    routerLink: [Routes.exchange],
    routerLinkActiveOptions: {exact: true}
  },
  {label: 'dashboard.menu.savings', icon: 'savings-menuitem', routerLink: [Routes.savings], routerLinkActiveOptions: {exact: true}},
  {
    label: 'dashboard.menu.wx-credit',
    icon: 'credit-menuitem',
    routerLink: [Routes.credits],
    routerLinkActiveOptions: {exact: true}
  },
  {
    label: 'dashboard.menu.markets',
    icon: 'markets-menuitem',
    routerLink: [Routes.markets],
    routerLinkActiveOptions: {exact: true}
  }
];
