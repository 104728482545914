import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {Intercom} from 'ng-intercom';

import {
  ILoginResponse,
  IRegisterPayload,
  IRegisterResponse,
  IUserCredentials,
  IUserUpdateResponse,
  KycStatus
} from '@wallex-core-client/core/interfaces/auth.interface';
import {APP_VERSION, ENVIRONMENT_TOKEN, SKIP_LOADER_INTERCEPTOR} from '@wallex-core-client/core/constants/main-constants';
import {SelectedWalletStateFacade} from '@wallex-core/store/selected-wallet/selected-wallet-state-facade.service';
import {NotificationsStateFacade} from '@wallex-core/store/notifications/notifications-state-facade.service';
import {SavingsStateFacadeService} from '@wallex-core/store/savings/savings-state-facade.service';
import {SettingsStateFacade} from '@wallex-core/store/settings/settings-state-facade.service';
import {BannersStateFacade} from '@wallex-core/store/banners/banners-state-facade.service';
import {WalletsStateFacade} from '@wallex-core/store/wallets/wallets-state-facade.service';
import {CreditsStateFacade} from '@wallex-core/store/credits/credits-state-facade.service';
import {AssetsStateFacade} from '@wallex-core/store/assets/assets-state-facade.service';
import {RatesStateFacade} from '@wallex-core/store/rates/rates-state-facade.service';
import {CardsStateFacade} from '@wallex-core/store/cards/cards-state-facade.service';
import {IEnvironment} from '@wallex-core-client/core/interfaces/common.interface';
import {IbanStateFacade} from '@wallex-core/store/iban/iban-state-facade.service';
import {NewsStateFacade} from '@wallex-core/store/news/news-state-facade.service';
import {UserStateFacade} from '@wallex-core/store/user/user-state-facade.service';
import {IUser} from '@wallex-core-client/core/interfaces/user-profile.interface';
import {AppStateFacade} from '@wallex-core/store/app/app-state-facade.service';
import {checkAllKeysTruthy, clearStorage} from '@wallex-core-client/utils';
import {WebSocketService} from '@wallex-core/services/web-socket.service';
import {JwtTokenService} from '@wallex-core/services/jwt-token.service';
import {RoutePaths, Routes} from '@wallex-core/constants/routes.enum';
import {UserService} from '@wallex-core/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private jwtTokenService: JwtTokenService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private appStateFacade: AppStateFacade,
    private assetsStateFacade: AssetsStateFacade,
    private bannersStateFacade: BannersStateFacade,
    private ibanStateFacade: IbanStateFacade,
    private ratesStateFacade: RatesStateFacade,
    private cardsStateFacade: CardsStateFacade,
    private newsStateFacade: NewsStateFacade,
    private savingsStateFacade: SavingsStateFacadeService,
    private selectedWalletStateFacade: SelectedWalletStateFacade,
    private settingsStateFacade: SettingsStateFacade,
    private walletsStateFacade: WalletsStateFacade,
    private intercom: Intercom,
    private webSocketService: WebSocketService,
    private userStateFacade: UserStateFacade,
    private notificationsStateFacade: NotificationsStateFacade,
    private creditsStateFacade: CreditsStateFacade,
    @Inject(ENVIRONMENT_TOKEN) environment: IEnvironment
  ) {
    this.host = environment.serverBaseUri;
    this.refreshTokenEndpoint = `${environment.serverBaseUri}/auth/refresh`;
  }

  public refreshTokenEndpoint!: string;
  private host!: string;

  public logouted$ = new Subject<void>();

  public loginCredentials$ = new BehaviorSubject<IUserCredentials | null>(null);

  private readonly pageNavigated$ = this.router.events.pipe(filter(rs => rs instanceof NavigationEnd && !rs.url.includes(RoutePaths.auth)));

  readonly refreshTokenIfNeeded$ = this.pageNavigated$.pipe(
    switchMap(() => {
      if (!this.jwtTokenService?.token) {
        return of(null);
      }
      const accessExpirationDate = this.jwtTokenService.token.accessExpiresIn;
      if (accessExpirationDate > new Date().getTime()) {
        return this.getRefreshedToken(this.jwtTokenService.token.refreshToken);
      }
      return of(null);
    }),
    tap(result => {
      if (result?.token) {
        this.jwtTokenService.token = result.token;
      }
    })
  );

  public confirmEmail(code: string): Observable<ILoginResponse> {
    return this.http.get<ILoginResponse>(`${this.host}/auth/confirm?token=${code}`).pipe(
      tap(result => {
        this.userStateFacade.confirmEmail();
        this.jwtTokenService.token = result.token;
      })
    );
  }

  public resendCode(email: string): Observable<IRegisterResponse> {
    return this.http.post<IRegisterResponse>(`${this.host}/auth/confirm/resend`, {email});
  }

  public login(userData: IUserCredentials, token2fa?: string): Observable<ILoginResponse> {
    return this.http.post<ILoginResponse>(`${this.host}/auth/login`, {...userData, token2fa}).pipe(
      tap(result => {
        this.jwtTokenService.token = result.token;
        this.router.navigate([Routes.dashboard]).catch(() => null);
      })
    );
  }

  public registerUser(payload: IRegisterPayload): Observable<IUser> {
    return this.http.post<IRegisterResponse>(`${this.host}/auth/register`, payload).pipe(
      tap(registerResponse => {
        this.userStateFacade.setConfirmationCodeValidityTimestamp(registerResponse.canResendAfter);
      }),
      map(registerResponse => {
        const user = this.transformRegisterResponse(registerResponse);
        this.userStateFacade.addUser(user);
        this.router.navigate([Routes.confirmation]).catch(() => null);
        return user;
      })
    );
  }

  private transformRegisterResponse({id, email}: IRegisterResponse) {
    return {id, email} as unknown as IUser;
  }

  public updateUserCredentials(payload: IUserCredentials, token: string): Observable<IUserUpdateResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`
      })
    };
    return this.http.patch<IUserUpdateResponse>(`${this.host}/user`, payload, httpOptions);
  }

  private getRefreshedToken(refreshToken: string): Observable<ILoginResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${refreshToken}`,
        [SKIP_LOADER_INTERCEPTOR.title]: SKIP_LOADER_INTERCEPTOR.value
      })
    };
    return this.http.post<ILoginResponse>(this.refreshTokenEndpoint, {}, httpOptions);
  }

  public changeEmail(email: string): Observable<any> {
    return this.http.post<any>(`${this.host}/auth/change-email`, {email});
  }

  public forgotPassword(data: {email: string}): Observable<void> {
    return this.http.post<void>(`${this.host}/auth/forgot-password`, data);
  }

  public redirectUser(user: IUser, isFromAuth: boolean = true): void {
    if (!user.emailConfirmedAt) {
      this.router.navigate([Routes.confirmation]).catch(() => null);
      return;
    }

    if (user.kycStatus === KycStatus.approved) {
      isFromAuth && this.router.navigate([Routes.dashboard]).catch(() => null);
      return;
    }

    const isBusinessAccount = !user.individualAccId;

    if (isBusinessAccount) {
      if (!checkAllKeysTruthy(user.businessAccounts[0], ['legalCountryCode', 'legalCity', 'legalAddress', 'postalCode'])) {
        this.router.navigate([Routes.businessAddress]).catch(() => null);
      } else if (
        !checkAllKeysTruthy(user.businessAccounts[0], ['businessTypeId', 'businessCategoryId', 'companyUrl', 'phone', 'registrationNumber', 'formationDate'])
      ) {
        this.router.navigate([Routes.companyDetails]).catch(() => null);
      } else if (!checkAllKeysTruthy(user.profile, ['firstName', 'lastName', 'birthDate', 'email', 'postalCode', 'city', 'addressLine1', 'addressLine2'])) {
        this.router.navigate([Routes.businessPerson]).catch(() => null);
      } else if (!checkAllKeysTruthy(user.profile, ['organizationRole'])) {
        this.router.navigate([Routes.businessRole]).catch(() => null);
      } else if (isFromAuth) {
        this.router.navigate([Routes.dashboard]).catch(() => null);
      }
      return;
    }

    if (!checkAllKeysTruthy(user.profile, ['firstName', 'lastName', 'birthDate'])) {
      this.router.navigate([Routes.personalInfo]).catch(() => null);
    } else if (isFromAuth) {
      this.router.navigate([Routes.dashboard]).catch(() => null);
    }
  }

  public logoutFromApp(): void {
    this.logouted$.next();
    this.router.navigate([Routes.signIn]).catch(() => null);

    this.intercom.shutdown();
    this.webSocketService.disconnect();
    this.clearStore();
    this.jwtTokenService.clearToken();
  }

  public clearStore() {
    this.walletsStateFacade.clearWallets();
    this.settingsStateFacade.clearSettings();
    this.newsStateFacade.clearNews();
    this.cardsStateFacade.clearCards();
    this.selectedWalletStateFacade.clearSelectedWallet();
    this.savingsStateFacade.clearSavings();
    this.ratesStateFacade.clearRates();
    this.ibanStateFacade.clearIban();
    this.bannersStateFacade.clearBanners();
    this.assetsStateFacade.clearAssets();
    this.appStateFacade.clearAllRouterHistory();
    this.userStateFacade.clearUserState();
    this.notificationsStateFacade.clearNotificationsData();
    this.creditsStateFacade.clearCredits();

    clearStorage(APP_VERSION);
  }
}
