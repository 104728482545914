import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {NotificationsStateFacade} from '@wallex-core/store/notifications/notifications-state-facade.service';
import {NotificationsService} from '@wallex-core/services/notifications.service';
import {Routes} from '@wallex-core/constants/routes.enum';

@Component({
  selector: 'app-notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationBellComponent implements OnInit, OnDestroy {
  value: number;
  subscriptions: Subscription[];

  constructor(
    private notificationsStateFacade: NotificationsStateFacade,
    private notificationsService: NotificationsService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
    this.getTotalUnreadNotificationsNumber();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private initSubscriptions() {
    this.subscriptions = [
      this.notificationsStateFacade.unreadTotalNumber$.subscribe(res => {
        this.value = res;
        this.cdr.detectChanges();
      })
    ];
  }

  private getTotalUnreadNotificationsNumber() {
    this.notificationsService.getUnreadNotificationsCount().subscribe(
      res => {},
      err => {}
    );
  }

  openNotifications() {
    this.router.navigate([Routes.notifications]).catch(err => console.log(err));
  }
}
