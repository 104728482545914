import {IEnvironment} from '../../../../core/interfaces/common.interface';
import {mainConstants} from '../../../../core/constants/main-constants';

export const getThisState = (stateName: string) => {
  try {
    const serializedState = localStorage.getItem(stateName);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const getItem = (itemName: string) => {
  return getThisState(itemName);
};

export const saveItem = (key: string, data: any) => {
  const serializedState = JSON.stringify(data);
  localStorage.setItem(key, serializedState);
};

export const clearStorage = (version: string) => {
  localStorage.clear();
  saveItem(mainConstants.VERSION_KEY, version);
};

export const getInitialState = (version: string, itemName: string): any => {
  let appVersion = getItem(mainConstants.VERSION_KEY);

  if (!appVersion) return null;

  const [major, minor, patch] = version.split('.');
  const [appMajor, appMinor, appPatch] = appVersion.split('.');

  if (major !== appMajor || minor !== appMinor) return null;

  return getThisState(itemName);
};
