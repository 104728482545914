import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

import {CookieService} from 'ngx-cookie-service';

import {TranslateService} from '@ngx-translate/core';

import {LOCALE_COOKIE_KEY} from '@wallex-core-client/core/constants/main-constants';
import {UserStateFacade} from '@wallex-core/store/user/user-state-facade.service';
import {UserService} from '@wallex-core/services/user.service';
import {AuthService} from '@wallex-core/services/auth.service';
import {Routes} from '@wallex-core/constants/routes.enum';

@Injectable()
export class PersonalInfoGuard implements CanActivate {
  constructor(
    private router: Router,
    private userStateFacade: UserStateFacade,
    private authService: AuthService,
    private userService: UserService,
    private cookieService: CookieService,
    private translate: TranslateService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.userService.getUser().pipe(
      switchMap(user => {
        const language = this.cookieService.get(LOCALE_COOKIE_KEY);

        if (language === user.language) return of(user);

        this.translate.use(language);
        return this.userService.updateLanguage(language).pipe(
          map(() => {
            const updatedUser = {...user, language};
            this.userStateFacade.addUser(updatedUser);

            return updatedUser;
          })
        );
      }),
      map(user => {
        this.authService.redirectUser(user, false);
        return true;
      }),
      catchError(() => {
        void this.router.navigate([Routes.signIn]);
        return of(true);
      })
    );
  }
}
