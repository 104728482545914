<form [formGroup]="form" class="form" (ngSubmit)="onSubmit()" id="language-form">
  <label class="language" *ngFor="let item of appStateFacade.locales$ | async" [ngClass]="{active: item.code === form.value.lang}">
    <input class="clear-field" type="radio" formControlName="lang" [value]="item.code" />
    <p class="language__text">
      <span class="language__flag">{{ item.flag }}</span> {{ item.name }}
    </p>
    <img class="language__check" src="/assets/check-light.svg" width="24" height="24" alt="Checked" />
  </label>
</form>

<div class="button-area">
  <button pButton type="submit" [label]="'edit_profile.change' | translate" form="language-form" [disabled]="isFetching"></button>
</div>

<button class="circle-close reset-btn" (click)="ref.close()">
  <img src="/assets/close-circle.svg" alt="" width="44" height="44" />
</button>
