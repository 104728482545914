export enum CreditAssetType {
  credit = 'CREDIT',
  collateral = 'COLLATERAL'
}

export enum LtvGradationType {
  low = 'low',
  medium = 'medium',
  high = 'high'
}

export enum LtvGradationTypeExtended {
  low = 'low',
  medium = 'medium',
  high = 'high',
  liquidation = 'liquidation'
}

export interface ICreditAsset {
  id: string;
  type: CreditAssetType;
  shortcode: string;
  createdAt: string;
  updatedAt: string;
}

export interface ICreditPeriod {
  id: number;
  title: string;
  duration: number;
  aprValue: string;
  allowedRefMinAmount: string;
  refAsset: string;
  createdAt: string;
  updatedAt: string;
}

export interface ILtvGradation {
  createdAt: string;
  id: number;
  maxValue: number;
  minValue: number;
  type: LtvGradationType;
  updatedAt: string;
}

export interface ITakeCreditPayload {
  creditAsset: string;
  creditAmount: string;
  collateralAsset: string;
  collateralAmount: string;
  periodId: number;
}

export enum CreditStatus {
  active = 'ACTIVE',
  failed = 'FAILED',
  closed = 'PAID',
  not_active = 'NOT_ACTIVE'
}

export enum CreditStatusForUi {
  repaid = 'repaid',
  liquidated = 'liquidated',
  active = 'active'
}

export interface ICredit {
  id: string;
  title: string;
  creditAmount: string;
  collateralAmount: string;
  ltv: string;
  ltvDate: string;
  initialLtv: string;
  aprValue: string;
  maturityDate: string;
  closeDate: string;
  status: CreditStatus;
  isAutoTopUp: boolean;
  autoTopUpValue: number;
  creditAsset: string;
  collateralAsset: string;
  userId: string;
  accountId: string;
  createdAt: string;
  updatedAt: string;
}

export interface IRepayInfo {
  amount: string;
  asset: string;
  aprValue: string;
  accruedInterestAmount: string;
  total: string;
}

export interface IRequestedCredit {
  creditAmount: string;
  creditAssetShortcode: string;
}
