import {Action} from '@ngrx/store';

import {ITransactionModel, IRequestedCryptoTransaction, RequestedFiatTransaction} from '@wallex-core-client/core/interfaces/transaction.interface';
import {IResponsePagination} from '@wallex-core-client/core/interfaces/api-response.interface';
import {IWallet} from '@wallex-core-client/core/interfaces/wallet.interface';

export enum SelectedWalletActionType {
  SELECT_WALLET = '[SelectedWallet] Select wallet',
  REQUEST_TRANSACTION = '[SelectedWallet] Request transaction',
  DESELECT_REQUESTED_TRANSACTION = '[SelectedWallet] Deselect Requested Transaction',
  ADD_TRANSACTIONS = '[SelectedWallet] Add transactions',
  SET_TRANSACTIONS = '[SelectedWallet] Set Transactions',
  UPDATE_REQUESTED_FIAT_TRANSACTION = '[SelectedWallet] Update Requested Fiat Transaction',
  UPDATE_TOP_UP_WITH_CARD_URL = '[SelectedWallet] Update Top With Card Url',
  CLEAR_SELECTED_WALLET = '[SelectedWallet] Clear Selected Wallet'
}

export class SelectWallet implements Action {
  readonly type = SelectedWalletActionType.SELECT_WALLET;

  constructor(readonly wallet: IWallet) {}
}

export class RequestTransaction implements Action {
  readonly type = SelectedWalletActionType.REQUEST_TRANSACTION;

  constructor(readonly transaction: IRequestedCryptoTransaction | null) {}
}

export class UpdateRequestedFiatTransaction implements Action {
  readonly type = SelectedWalletActionType.UPDATE_REQUESTED_FIAT_TRANSACTION;

  constructor(readonly transaction: RequestedFiatTransaction | null) {}
}

export class AddTransactions implements Action {
  readonly type = SelectedWalletActionType.ADD_TRANSACTIONS;

  constructor(readonly transactions: IResponsePagination<ITransactionModel[]>) {}
}

export class UpdateTopUpWithCardUrl implements Action {
  readonly type = SelectedWalletActionType.UPDATE_TOP_UP_WITH_CARD_URL;

  constructor(readonly url: string | null) {}
}

export class SetTransactions implements Action {
  readonly type = SelectedWalletActionType.SET_TRANSACTIONS;

  constructor(readonly transactions: IResponsePagination<ITransactionModel[]>) {}
}

export class ClearSelectedWallet implements Action {
  readonly type = SelectedWalletActionType.CLEAR_SELECTED_WALLET;
}

export type SelectedWalletAction =
  | SelectWallet
  | RequestTransaction
  | AddTransactions
  | UpdateRequestedFiatTransaction
  | ClearSelectedWallet
  | UpdateTopUpWithCardUrl
  | SetTransactions;
