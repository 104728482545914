import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {IConfig, IEnvironment} from '@wallex-core-client/core/interfaces/common.interface';
import {ENVIRONMENT_TOKEN} from '@wallex-core-client/core/constants/main-constants';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private host!: string;

  constructor(@Inject(ENVIRONMENT_TOKEN) private environment: IEnvironment, private http: HttpClient) {
    this.host = environment.serverBaseUri;
  }

  public getAppConfiguration(): Observable<IConfig> {
    return this.http.get<IConfig>(`${this.host}/application/configurations`);
  }
}
