import {createFeatureSelector} from '@ngrx/store';

import {SegmentDistributionsDto} from '@wallex-core-client/core/dtos/balance.dto';
import {APP_VERSION} from '@wallex-core-client/core/constants/main-constants';
import {IWallet} from '@wallex-core-client/core/interfaces/wallet.interface';
import {getInitialState} from '@wallex-core-client/utils';

export const walletsStateKey = 'wallets';

export interface IWalletsState {
  readonly wallets: IWallet[];
  readonly selectedWallet?: string | null;
  readonly totalBalance: Record<string, number>;
  readonly isWalletsLoadingFailure: boolean;
  readonly socketWallet: null | IWallet;
  readonly segmentDistributions: null | SegmentDistributionsDto;
}

export const blankWalletsState = {
  wallets: [],
  selectedWallet: null,
  totalBalance: {},
  isWalletsLoadingFailure: false,
  socketWallet: null,
  segmentDistributions: null
};

export const initialWalletsState: IWalletsState = getInitialState(APP_VERSION, walletsStateKey) || blankWalletsState;

export const selectWalletsState = createFeatureSelector<IWalletsState>(walletsStateKey);
