export enum transactionTypeIcon {
  send = '/assets/transactions/send.svg',
  receive = '/assets/transactions/receive.svg',
  exchange = '/assets/transactions/exchange.svg',
  topUp = '/assets/transactions/topUp.svg',
  fee = '/assets/transactions/fee.svg',
  reward = '/assets/transactions/reward.svg'
}

export enum transparentTransactionTypeIcon {
  send = '/assets/transactions/transparent/send.svg',
  receive = '/assets/transactions/transparent/receive.svg',
  exchange = '/assets/transactions/transparent/exchange.svg',
  topUp = '/assets/transactions/transparent/topUp.svg'
}

export enum transactionStateIcon {
  pending = '/assets/transactions/pending.svg',
  failed = '/assets/transactions/failed.svg',
  success = '/assets/transactions/success.svg'
}

export enum transactionStateClass {
  pending = 'pending-transaction',
  failed = 'failed-transaction',
  success = 'success-transaction'
}

export enum transactionStateLabel {
  pending = 'transaction-details.state.pending',
  failed = 'transaction-details.state.failed',
  success = 'transaction-details.state.success'
}

export const CARD_TRANSFER_TITLE = 'Card transfer';
export const REWARD_TRANSFER_TITLE = 'Receive from referral program';
export const REWARD_TRANSFER_SOURCE = 'Referral program';
