import {select, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';

import {map, shareReplay} from 'rxjs/operators';

import {IbanStateInterface, selectIbanState} from '@wallex-core/store/iban/iban-state.model';
import {ClearIbanAction, UpdateKYCData} from '@wallex-core/store/iban/iban.actions';
import {IKYCData} from '@wallex-core-client/core/interfaces/kyc-data.interface';
import {isObjectHasAllValuesDefined} from '@wallex-core-client/utils';

@Injectable({
  providedIn: 'root'
})
export class IbanStateFacade {
  constructor(private readonly store$: Store<IbanStateInterface>) {}

  private readonly state$ = this.store$.pipe(select(selectIbanState));

  readonly kycData$ = this.state$.pipe(select(state => state.kycData));

  readonly isKycDataFull$ = this.kycData$.pipe(
    map(
      kycData =>
        !!kycData && isObjectHasAllValuesDefined(kycData) && isObjectHasAllValuesDefined(kycData.address) && isObjectHasAllValuesDefined(kycData.document)
    ),
    shareReplay(1)
  );

  public updateKYCData(kycData: IKYCData): void {
    this.store$.dispatch(new UpdateKYCData(kycData));
  }

  public clearIban(): void {
    this.store$.dispatch(new ClearIbanAction());
  }
}
