import {Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input} from '@angular/core';

import {IOrganization, IUser} from '@wallex-core-client/core/interfaces/user-profile.interface';

@Component({
  selector: 'app-business-overlay',
  templateUrl: './business-overlay.component.html',
  styleUrls: ['./business-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessOverlayComponent implements OnInit {
  @Output('toSettings') toSettingsEvent = new EventEmitter<void>();
  @Output('openLogout') openLogoutEvent = new EventEmitter<void>();
  @Input() user!: IUser | null;
  @Input() currentOrganization!: IOrganization | null;
  @Input() profileAvatar: string | null;

  constructor() {}

  ngOnInit(): void {}
}
