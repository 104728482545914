import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';

import {BannersActionType, LoadMainScreenBanners, LoadMainScreenBannersFailure, LoadMainScreenBannersSuccess} from '@wallex-core/store/banners/banners.actions';
import {IBanner} from '@wallex-core-client/core/interfaces/banner.interface';
import {BannerService} from '@wallex-core/services/banners.service';

@Injectable()
export class BannersEffects {
  constructor(private actions$: Actions, private bannerService: BannerService) {}

  readonly loadMainScreenBanners$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadMainScreenBanners>(BannersActionType.LOAD_MAIN_SCREEN_BANNERS),
      switchMap(() => this.bannerService.getBanners()),
      mergeMap((banners: IBanner[]) => [new LoadMainScreenBannersSuccess(banners)]),
      catchError(() => [new LoadMainScreenBannersFailure()])
    )
  );
}
