import {initialIbanState, IbanStateInterface, blankIbanState} from '@wallex-core/store/iban/iban-state.model';
import {IbanActionType} from '@wallex-core/store/iban/iban.actions';
import {NeoBankingAction} from '@wallex-core/store/action.model';

export function ibanReducer(state = initialIbanState, action: NeoBankingAction): IbanStateInterface {
  switch (action.type) {
    case IbanActionType.UPDATE_KYC_DATA:
      return {
        ...state,
        kycData: action.kycData
      };

    case IbanActionType.CLEAR_IBAN:
      return {...blankIbanState};

    default:
      return state;
  }
}
