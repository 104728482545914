import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {of} from 'rxjs';

import {AssetsActionType, LoadAssets, LoadAssetsFailure, LoadAssetsSuccess} from '@wallex-core/store/assets/assets.actions';
import {AssetService} from '@wallex-core/services/asset.service';

@Injectable()
export class AssetsEffects {
  constructor(private readonly actions$: Actions, private readonly assetService: AssetService) {}

  readonly loadAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadAssets>(AssetsActionType.LOAD_ASSETS),
      switchMap(action => this.assetService.loadAssets()),
      mergeMap(assets => [new LoadAssetsSuccess(assets)]),
      catchError(error => of(new LoadAssetsFailure()))
    )
  );
}
