import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {IWallet} from '@wallex-core-client/core/interfaces/wallet.interface';
import {IAsset} from '@wallex-core-client/core/interfaces/asset.interface';
import {getSubIcon} from '@wallex-core-client/utils';

@Component({
  selector: 'app-coin-icon',
  templateUrl: './coin-icon.component.html',
  styleUrls: ['./coin-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoinIconComponent {
  @Input() account!: IWallet | IAsset;
  @Input() size?: 'md' | 'large' | 'small' = 'md';
  @Input() isNetworkIconShown = false;

  getNetworkIcon(): string {
    return getSubIcon((this.account as IAsset).shortcode);
  }
}
