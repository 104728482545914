import {createFeatureSelector} from '@ngrx/store';

import {ICredit, ICreditAsset, ICreditPeriod, ILtvGradation, IRequestedCredit} from '@wallex-core-client/core/interfaces/credits.interface';
import {IPaginatedList} from '@wallex-core-client/core/interfaces/api-response.interface';
import {APP_VERSION} from '@wallex-core-client/core/constants/main-constants';
import {getInitialState} from '@wallex-core-client/utils';

export const creditsStateKey = 'credits';

export interface ICreditsState {
  readonly creditAssets: ICreditAsset[];
  readonly collateralAssets: ICreditAsset[];
  readonly creditPeriods: ICreditPeriod[];
  readonly ltvGradation: ILtvGradation[];
  readonly activeCredits: IPaginatedList<ICredit>;
  readonly archiveCredits: IPaginatedList<ICredit>;
  readonly requestedCredit: IRequestedCredit | null;
  readonly repaidCredit: IRequestedCredit | null;
}

export const blankCreditsState: ICreditsState = {
  creditAssets: [],
  collateralAssets: [],
  creditPeriods: [],
  ltvGradation: [],
  activeCredits: {total: 0, data: []},
  archiveCredits: {total: 0, data: []},
  requestedCredit: null,
  repaidCredit: null
};

export const initialCreditsState: ICreditsState = getInitialState(APP_VERSION, creditsStateKey) || blankCreditsState;

export const selectCreditsState = createFeatureSelector<ICreditsState>(creditsStateKey);
