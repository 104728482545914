import {map, shareReplay} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {
  AddCardAddress,
  AddCardOrderFee,
  AddCards,
  AddDeliveryMethods,
  AddPin,
  AddTransactionHistory,
  ClearCardsAction,
  SetLimitsStatus
} from '@wallex-core/store/cards/cards.actions';
import {ICard, ICardAddress, ICardTransactionHistory, IDeliveryMethod, LimitStatuses} from '@wallex-core-client/core/interfaces/cards.interface';
import {ICardsState, selectCardsState} from '@wallex-core/store/cards/cards-state.model';

@Injectable({
  providedIn: 'root'
})
export class CardsStateFacade {
  constructor(private readonly store$: Store<ICardsState>) {}

  private readonly state$ = this.store$.pipe(select(selectCardsState));

  readonly deliveryMethods$ = this.state$.pipe(
    select(state => state.deliveryMethods),
    shareReplay(1)
  );

  readonly cards$ = this.state$.pipe(
    select(state => state.cards),
    shareReplay(1)
  );

  readonly card$ = this.state$.pipe(
    select(state => state.cards),
    map(cards => cards?.[0] || null),
    shareReplay(1)
  );

  readonly pin$ = this.state$.pipe(
    select(state => state.pin),
    shareReplay(1)
  );

  readonly cardOrderFee$ = this.state$.pipe(
    select(state => state.cardOrderFee),
    shareReplay(1)
  );

  readonly cardAddress$ = this.state$.pipe(
    select(state => state.cardAddress),
    shareReplay(1)
  );

  readonly transactionHistory$ = this.state$.pipe(
    select(state => state.transactionHistory),
    shareReplay(1)
  );

  readonly limitStatus$ = this.state$.pipe(
    select(state => state.limitStatus),
    shareReplay(1)
  );

  public getCardById$(id: string): Observable<ICard | undefined> {
    return this.cards$.pipe(
      map(cards => {
        return cards?.find(card => card.id === id);
      })
    );
  }

  public getCardByAsset$(asset: string): Observable<ICard | undefined> {
    return this.cards$.pipe(
      map(cards => {
        return cards?.find(card => card.asset === asset);
      })
    );
  }

  public addDeliveryMethods(deliveryMethods: IDeliveryMethod[]): void {
    this.store$.dispatch(new AddDeliveryMethods(deliveryMethods));
  }

  public addCardAddress(cardAddress: ICardAddress): void {
    this.store$.dispatch(new AddCardAddress(cardAddress));
  }

  public addCardOrderFee(cardOrderFee: string): void {
    this.store$.dispatch(new AddCardOrderFee(cardOrderFee));
  }

  public addCards(cards: ICard[]): void {
    this.store$.dispatch(new AddCards(cards));
  }

  public addPin(pin: string): void {
    this.store$.dispatch(new AddPin(pin));
  }

  public addTransactionHistory(transactionHistory: ICardTransactionHistory): void {
    this.store$.dispatch(new AddTransactionHistory(transactionHistory));
  }

  public setLimitStatus(limitStatus: LimitStatuses): void {
    this.store$.dispatch(new SetLimitsStatus(limitStatus));
  }

  public clearCards(): void {
    this.store$.dispatch(new ClearCardsAction());
  }
}
