import {createFeatureSelector} from '@ngrx/store';

import {ICard, ICardAddress, ICardTransactionHistory, IDeliveryMethod, LimitStatuses} from '@wallex-core-client/core/interfaces/cards.interface';
import {APP_VERSION} from '@wallex-core-client/core/constants/main-constants';
import {getInitialState} from '@wallex-core-client/utils';

export const cardsStateKey = 'cards';

export interface ICardsState {
  readonly deliveryMethods: IDeliveryMethod[] | null;
  readonly cards: ICard[] | null;
  readonly cardAddress: ICardAddress | null;
  readonly cardOrderFee: string | null;
  readonly pin: string | null;
  readonly transactionHistory: ICardTransactionHistory | null;
  readonly limitStatus: LimitStatuses;
}

export const blankCardsState = {
  deliveryMethods: null,
  cards: null,
  cardAddress: null,
  cardOrderFee: null,
  pin: null,
  transactionHistory: null,
  limitStatus: LimitStatuses.NOT_APPLIED
};

export const initialCardsState: ICardsState = getInitialState(APP_VERSION, cardsStateKey) || blankCardsState;

export const selectCardsState = createFeatureSelector<ICardsState>(cardsStateKey);
