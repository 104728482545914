<p-toast class="toast-message waiting"></p-toast>

<div class="receive-dialog-container">
  <qr-code [value]="address" [size]="194"></qr-code>
  <div class="tip">
    <span class="secondary">{{ 'account.receive-dialog.send-only' | translate : {asset: assetName} }}</span>
  </div>

  <div class="account-address">
    <span>{{ address }}</span>
    <div class="copy-button" (click)="copyAddressToClipboard()"></div>
  </div>
</div>
<div class="button-area">
  <a href="#" class="subheader2" (click)="close($event)">{{ 'common.cancel' | translate }}</a>
</div>
