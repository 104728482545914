import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, switchMap} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {TranslateService} from '@ngx-translate/core';
import {DialogService} from 'primeng/dynamicdialog';

import {ReceiveInfoCryptoDialogComponent} from '@wallex-core/features/account/account/receive-info-crypto-dialog/receive-info-crypto-dialog.component';
import {ReceiveInfoFiatDialogComponent} from '@wallex-core/features/account/account/receive-info-fiat-dialog/receive-info-fiat-dialog.component';
import {TopUpWithCardDialogComponent} from '@wallex-core/features/account/account/top-up-with-card-dialog/top-up-with-card-dialog.component';
import {ReceiveDialogComponent} from '@wallex-core/features/account/account/receive-dialog/receive-dialog.component';
import {defaultPopupConfig, ENVIRONMENT_TOKEN} from '@wallex-core-client/core/constants/main-constants';
import {IbanReceiveInfoInterface} from '@wallex-core-client/core/interfaces/iban.interface';
import {WalletsStateFacade} from '@wallex-core/store/wallets/wallets-state-facade.service';
import {IEnvironment} from '@wallex-core-client/core/interfaces/common.interface';
import {IWallet} from '@wallex-core-client/core/interfaces/wallet.interface';
import {BalanceService} from '@wallex-core/services/balance.service';
import {TopUpTypes} from '@wallex-core-client/core/dtos/wallet.dto';

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  constructor(
    private http: HttpClient,
    private balanceService: BalanceService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private walletsStateFacade: WalletsStateFacade,
    @Inject(ENVIRONMENT_TOKEN) environment: IEnvironment
  ) {
    this.host = environment.serverBaseUri;
  }

  private host!: string;

  public loadWallets(): Observable<IWallet[]> {
    return this.http.get<IWallet[]>(`${this.host}/wallet`).pipe(switchMap((wallets: IWallet[]) => this.mapWalletsWithBalances(wallets)));
  }

  private mapWalletsWithBalances(wallets: IWallet[]): Observable<IWallet[]> {
    return this.balanceService.getTotalBalance().pipe(
      map(totalBalance => {
        const mappedWallets: IWallet[] = [];
        for (const wallet of wallets) {
          const mappedWallet: IWallet = {...wallet, balancesInRefCurrencies: {}, isAvailable: true, balance: 0};
          for (const refCurrency in totalBalance) {
            const walletBalances = totalBalance[refCurrency].walletsBalance.find(balance => balance.asset === wallet.asset);
            if (walletBalances) {
              mappedWallet.balancesInRefCurrencies[refCurrency] = {
                asset: walletBalances.asset,
                value: parseFloat(walletBalances.value),
                refCurrencyValue: parseFloat(walletBalances?.refCurrencyValue)
              };
              mappedWallet.balance = parseFloat(walletBalances.value);
            }
          }
          mappedWallets.push(mappedWallet);
        }
        return mappedWallets;
      })
    );
  }

  public createWallet(asset: string): Observable<IWallet> {
    return this.http.post<IWallet>(`${this.host}/wallet`, {asset}).pipe(
      map((wallet: IWallet) => {
        return {...wallet, balance: 0, isAvailable: true, balancesInRefCurrencies: {}};
      })
    );
  }

  public toggleFavorite(walletId: string, isFavorite: boolean): Observable<null> {
    return this.http.put<null>(`${this.host}/wallet/favorite/${walletId}`, {isFavorite}).pipe(
      tap(() => {
        this.walletsStateFacade.toggleFavorite({walletId, isFavorite});
      })
    );
  }

  public receive(account: IWallet, ibanReceiveInfo: IbanReceiveInfoInterface) {
    const dialogHeader = `${this.translate.instant('account.receive')} ${account!.title}`;

    if (account!.availableTopUpTypes.length === 1) {
      this.showReceiveInfo(account, dialogHeader, account!.availableTopUpTypes[0], ibanReceiveInfo);
      return;
    }

    const ref = this.dialogService.open(ReceiveDialogComponent, {
      ...defaultPopupConfig(this.translate.instant('account.receive'), true, true),
      data: {
        availableTopUpTypes: account!.availableTopUpTypes
      }
    });

    ref.onClose.subscribe((result: TopUpTypes) => {
      if (!result) return;

      this.showReceiveInfo(account, dialogHeader, result, ibanReceiveInfo);
    });
  }

  public showReceiveInfo(account: IWallet, title: string, receiveOption: TopUpTypes, ibanReceiveInfo: IbanReceiveInfoInterface): void {
    switch (receiveOption) {
      case TopUpTypes.CARD:
        this.dialogService.open(TopUpWithCardDialogComponent, {
          ...defaultPopupConfig(title, true, true),
          data: {
            account: account
          }
        });
        break;
      case TopUpTypes.WIRE:
        this.dialogService.open(ReceiveInfoFiatDialogComponent, {
          ...defaultPopupConfig(title),
          data: {
            receiveInfo: ibanReceiveInfo
          }
        });
        break;
      case TopUpTypes.CRYPTO:
        this.dialogService.open(ReceiveInfoCryptoDialogComponent, {
          ...defaultPopupConfig(title),
          data: {
            account: account
          }
        });
        break;
    }
  }
}
