<input
  class="radio__field clear-field"
  type="radio"
  [checked]="isChecked"
  [id]="inputId"
  [name]="name"
  [disabled]="isDisabled"
  [value]="value"
  (input)="onChange($event)"
  (blur)="touched()"
/>
<label class="radio__circle" [for]="inputId" aria-hidden="true"></label>
