import {blankSettingsState, initialSettingsState, ISettingsState} from './settings-state.model';
import {SettingsActionType} from './settings.actions';
import {NeoBankingAction} from '../action.model';

export function settingsReducer(state = initialSettingsState, action: NeoBankingAction): ISettingsState {
  switch (action.type) {
    case SettingsActionType.ADD_CURRENCIES:
      return {
        ...state,
        currencies: action.currencies
      };

    case SettingsActionType.ADD_COUNTRIES: {
      return {
        ...state,
        countries: action.appCountries.countries,
        profileCountriesCodes: action.appCountries.profileCountriesCodes
      };
    }

    case SettingsActionType.UPDATE_GENERAL_SETTINGS: {
      return {
        ...state,
        generalSettings: action.settings
      };
    }

    case SettingsActionType.UPDATE_AUTO_CONVERSION_STATUS: {
      return {
        ...state,
        generalSettings: {
          ...state.generalSettings,
          autoConversionAsset: action.autoConversionStatus.autoConversionAsset,
          autoConversionEnabled: action.autoConversionStatus.autoConversionEnabled,
          userId: action.autoConversionStatus.userId
        }
      };
    }

    case SettingsActionType.UPDATE_AUTO_CONVERSION_CURRENCIES: {
      return {
        ...state,
        autoConversionCurrencies: action.autoConversionCurrencies
      };
    }

    case SettingsActionType.UPDATE_BALANCE_VISIBILITY: {
      return {
        ...state,
        generalSettings: {...state.generalSettings, isBalanceSeen: action.isBalanceSeen}
      };
    }

    case SettingsActionType.CLEAR_SETTINGS: {
      return {
        ...blankSettingsState,
        countries: state.countries,
        profileCountriesCodes: state.profileCountriesCodes
      };
    }

    default:
      return state;
  }
}
