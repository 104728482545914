import {blankNotificationsState, initialNotificationsState, INotificationsState} from '@wallex-core/store/notifications/notifications-state.model';
import {isTransactionEventNotification} from '../../../../utils/src/lib/utils/notification.utils';
import {NotificationsActionType} from '@wallex-core/store/notifications/notifications.actions';
import {NotificationPayload} from '@wallex-core-client/core/dtos/notifications.dto';
import {NeoBankingAction} from '@wallex-core/store/action.model';

export function notificationsReducer(state = initialNotificationsState, action: NeoBankingAction): INotificationsState {
  switch (action.type) {
    case NotificationsActionType.SET_UNREAD_NUMBER:
      return {
        ...state,
        unreadTotalNumber: action.unreadNotificationsNumber.total,
        unreadMessagesNumber: action.unreadNotificationsNumber.messages,
        unreadTransactionNotificationsNumber: action.unreadNotificationsNumber.transactions
      };
    case NotificationsActionType.SET_PUSH_TOKEN:
      return {
        ...state,
        pushToken: action.token
      };
    case NotificationsActionType.ADD_PUSH_NOTIFICATION:
      let unreadTransactionNotificationsNumber = state.unreadTransactionNotificationsNumber;
      let unreadMessagesNumber = state.unreadMessagesNumber;
      const isTransactionEvent = isTransactionEventNotification((action.notification.data as unknown as NotificationPayload).type);
      if (isTransactionEvent) {
        unreadTransactionNotificationsNumber++;
      } else {
        unreadMessagesNumber++;
      }
      return {
        ...state,
        pushNotificationWithMessage: !isTransactionEvent ? action.notification : null,
        pushNotificationWithTransactionEvent: isTransactionEvent ? action.notification : null,
        unreadMessagesNumber,
        unreadTransactionNotificationsNumber,
        unreadTotalNumber: state.unreadTotalNumber + 1
      };

    case NotificationsActionType.CLEAR_PUSH_NOTIFICATION:
      return {
        ...state,
        pushNotificationWithMessage: null,
        pushNotificationWithTransactionEvent: null
      };

    case NotificationsActionType.CLEAR_DATA:
      return {
        ...blankNotificationsState
      };

    default:
      return state;
  }
}
