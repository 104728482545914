import {Action} from '@ngrx/store';

import {INew} from '@wallex-core-client/core/interfaces/news.interface';

export enum NewsActionType {
  UPDATE_NEWS = '[News] Update News',
  CLEAR_NEWS = '[News] Clear News'
}
export class UpdateNews implements Action {
  readonly type = NewsActionType.UPDATE_NEWS;

  constructor(readonly news: INew[]) {}
}

export class ClearNewsAction implements Action {
  readonly type = NewsActionType.CLEAR_NEWS;
}

export type NewsAction = UpdateNews | ClearNewsAction;
