import {cloneDeep} from 'lodash-es';

import {emptyWalletState, initialSelectedWalletState, ISelectedWalletState} from '@wallex-core/store/selected-wallet/selected-wallet-state.model';
import {SelectedWalletActionType} from '@wallex-core/store/selected-wallet/selected-wallet.actions';
import {Writable} from '@wallex-core-client/core/interfaces/common.interface';
import {NeoBankingAction} from '@wallex-core/store/action.model';

export function selectedWalletReducer(state = initialSelectedWalletState, action: NeoBankingAction): ISelectedWalletState {
  switch (action.type) {
    case SelectedWalletActionType.SELECT_WALLET:
      const walletState: Partial<Writable<ISelectedWalletState>> = cloneDeep(emptyWalletState);
      delete walletState['requestedCryptoTransaction'];
      delete walletState['requestedFiatTransaction'];

      return {
        ...state,
        ...(walletState as ISelectedWalletState),
        walletId: action.wallet.id,
        asset: action.wallet.asset
      };

    case SelectedWalletActionType.REQUEST_TRANSACTION:
      return {
        ...state,
        requestedCryptoTransaction: action.transaction
      };

    case SelectedWalletActionType.UPDATE_REQUESTED_FIAT_TRANSACTION:
      return {
        ...state,
        requestedFiatTransaction: action.transaction
      };

    case SelectedWalletActionType.ADD_TRANSACTIONS: {
      return {
        ...state,
        transactions: {
          data: state.transactions.data.concat(action.transactions.data),
          count: action.transactions.count
        }
      };
    }

    case SelectedWalletActionType.SET_TRANSACTIONS: {
      return {
        ...state,
        transactions: {
          data: action.transactions.data,
          count: action.transactions.count
        }
      };
    }

    case SelectedWalletActionType.UPDATE_TOP_UP_WITH_CARD_URL:
      return {
        ...state,
        topUpWithCardUrl: action.url
      };

    case SelectedWalletActionType.CLEAR_SELECTED_WALLET: {
      return {...emptyWalletState};
    }

    default:
      return state;
  }
}
