import {TranslateService} from '@ngx-translate/core';
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {DialogService} from 'primeng/dynamicdialog';

import {ENVIRONMENT_TOKEN, POPUP_WIDTH} from '@wallex-core-client/core/constants/main-constants';
import {KycDialogComponent} from '@wallex-core/shared/kyc-dialog/kyc-dialog.component';
import {IEnvironment} from '@wallex-core-client/core/interfaces/common.interface';
import {UserStateFacade} from '@wallex-core/store/user/user-state-facade.service';
import {KycTokenDto} from '@wallex-core-client/core/dtos/kyc.dto';
import {Routes} from '@wallex-core/constants/routes.enum';

@Injectable({
  providedIn: 'root'
})
export class KycService {
  constructor(
    private http: HttpClient,
    private userStateFacade: UserStateFacade,
    private router: Router,
    private translate: TranslateService,
    private dialogService: DialogService,
    @Inject(ENVIRONMENT_TOKEN) environment: IEnvironment
  ) {
    this.host = environment.serverBaseUri;
  }

  private host!: string;
  private allowedRoutesForUnverifiedKYCUser = [
    Routes.dashboard,
    Routes.settings,
    Routes.twoFaSuccess,
    Routes.twoFaSetup,
    Routes.twoFaConfirm,
    Routes.twoFaConfirmOperation,
    Routes.signIn,
    Routes.signUp,
    Routes.companyDetails,
    Routes.businessPerson,
    Routes.businessRole,
    Routes.confirmation,
    Routes.businessAddress,
    Routes.personalInfo,
    Routes.all_accounts,
    Routes.savings,
    Routes.news,
    Routes.cards,
    Routes.markets,
    Routes.notifications,
    Routes.credits,
    Routes.referralProgram
  ];

  getKYCToken(): Observable<string> {
    return this.http.post<KycTokenDto>(`${this.host}/kyc/token`, {}).pipe(map(tokenData => tokenData.token));
  }

  isUserAllowedToOpenRoute(url: string, isKYCVerificationCompleted: boolean): boolean {
    return isKYCVerificationCompleted || this.allowedRoutesForUnverifiedKYCUser.some(route => route === url);
  }

  public initKYC(): void {
    this.dialogService.open(KycDialogComponent, {
      data: {
        secondTry: true
      },
      styleClass: 'no-x-button kyc-dialog',
      header: this.translate.instant('auth.KYC.verification_kyc'),
      width: POPUP_WIDTH
    });
  }

  public initKYCOnly(secondTry: boolean = false): void {
    this.dialogService.open(KycDialogComponent, {
      data: {
        secondTry
      },
      styleClass: 'kyc-dialog close-icon',
      header: this.translate.instant('auth.KYC.verification_kyc'),
      width: '564px'
    });
  }
}
