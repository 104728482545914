import {filter, map, shareReplay} from 'rxjs/operators';
import {isNil, isNull, nth, takeRight} from 'lodash-es';
import {TranslateService} from '@ngx-translate/core';
import {Inject, Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';

import {BehaviorSubject, combineLatest} from 'rxjs';

import {HttpStatusCode} from '@angular/common/http';

import {
  AddRouterHistory,
  ClearAllRouterHistory,
  ClearError,
  ClearUserServerError,
  GoBack,
  MarkServerError,
  SetLoadingStatus,
  SetMainScreenActive,
  SetPageTitle,
  UpdateRouterHistory
} from './app.actions';
import {IConfig, IEnvironment, IHeaderMessage} from '@wallex-core-client/core/interfaces/common.interface';
import {IErrorDisplayOptions} from '@wallex-core-client/core/interfaces/error-display.interface';
import {ENVIRONMENT_TOKEN} from '@wallex-core-client/core/constants/main-constants';
import {IbanProviders} from '@wallex-core-client/core/interfaces/iban.interface';
import {ServerErrorDto} from '@wallex-core-client/core/dtos/server-error.dto';
import {emptyAppConfig, IAppState, selectAppState} from './app-state.model';

@Injectable({
  providedIn: 'root'
})
export class AppStateFacade {
  constructor(private readonly store$: Store<IAppState>, private translate: TranslateService, @Inject(ENVIRONMENT_TOKEN) private environment: IEnvironment) {}

  public readonly config$ = new BehaviorSubject<IConfig>(emptyAppConfig);

  public isExchangeButtonVisible$ = new BehaviorSubject<boolean>(false);
  public fieldChanged$ = new BehaviorSubject<boolean>(false);
  public isToastShown$ = new BehaviorSubject<boolean>(false);
  public changeStatusText!: string;
  public isWaiting!: boolean;
  private timeout!: any;
  public headerMessage$ = new BehaviorSubject<IHeaderMessage | null>(null);

  private readonly state$ = this.store$.pipe(select(selectAppState));

  readonly routerHistory$ = this.state$.pipe(
    select(state => state.routerHistory),
    shareReplay(1)
  );

  readonly canGoBack$ = this.routerHistory$.pipe(
    map(routerHistory => routerHistory.length > 1),
    shareReplay(1)
  );

  readonly lastAndCurrentVisitedRoute$ = this.routerHistory$.pipe(
    map(routerHistory => takeRight(routerHistory, 2)),
    shareReplay(1)
  );

  readonly lastVisitedRoute$ = this.routerHistory$.pipe(
    map(routerHistory => nth(routerHistory, -2)),
    shareReplay(1)
  );

  readonly title$ = this.state$.pipe(
    select(state => state.title),
    shareReplay(1)
  );

  readonly isMainScreenActive$ = this.state$.pipe(
    select(state => state.isMainScreenActive),
    shareReplay(1)
  );

  private readonly appStatus$ = this.state$.pipe(select(state => state.appStatus));

  readonly errorData$ = this.appStatus$.pipe(select(status => status.errorData));

  readonly userErrorMessages$ = this.errorData$.pipe(
    filter(
      errorData =>
        !isNull(errorData) && !isNil(errorData.error) && this.isUserError(errorData.error.statusCode as number) && Array.isArray(errorData?.error?.messages)
    ),
    map(errorData => {
      const serverError = errorData!.error as ServerErrorDto;
      const errorMessages: Record<string, string[]> = {};
      for (const message of serverError.messages) {
        errorMessages[message.type] = message.text;
      }
      return errorMessages;
    })
  );

  readonly serverErrorMessage$ = this.errorData$.pipe(
    filter(errorData => !isNull(errorData)),
    map(errorData => {
      if (errorData?.error?.status === HttpStatusCode.InternalServerError) {
        return {title: this.translate.instant('errors.server_error'), message: this.translate.instant('errors.try_again_later')};
      }

      if (errorData?.error?.status === 0) {
        return {title: this.translate.instant('errors.no-internet'), message: this.translate.instant('errors.check-connection')};
      }

      if (errorData?.error?.error?.message) {
        return {
          title: this.translate.instant('common.err'),
          message: errorData.error.error.message
        };
      }
      return {title: '', message: ''};
    })
  );

  readonly showSpinner$ = this.appStatus$.pipe(select(status => status.isLoading));

  readonly isIbanCreationSupported$ = this.config$.pipe(
    map(config => config.providers.iban !== IbanProviders.VOID),
    shareReplay(1)
  );

  readonly locales$ = this.config$.pipe(
    map(config => config.locales),
    shareReplay(1)
  );

  readonly providers$ = this.config$.pipe(
    select(config => config.providers),
    shareReplay(1)
  );

  readonly modules$ = this.config$.pipe(
    select(config => config.modules),
    shareReplay(1)
  );

  readonly isReferralProgramEnabled$ = this.modules$.pipe(
    select(modules => modules.isReferralProgramModuleIncluded),
    shareReplay(1)
  );

  readonly cardProvider$ = this.providers$.pipe(
    select(providers => providers.card),
    shareReplay(1)
  );

  readonly ibanProvider$ = this.providers$.pipe(
    select(providers => providers.iban),
    shareReplay(1)
  );

  readonly documents$ = this.config$.pipe(
    select(config => config.documents),
    shareReplay(1)
  );

  readonly termsAndConditions$ = this.documents$.pipe(
    select(documents => documents.termsandconditions),
    shareReplay(1)
  );

  readonly privacyPolicy$ = this.documents$.pipe(
    select(documents => documents.privacypolicy),
    shareReplay(1)
  );

  readonly savingsTermsAndConditions$ = this.documents$.pipe(
    select(documents => documents.savingstermsandconditions),
    shareReplay(1)
  );

  readonly creditTermsAndConditions$ = this.documents$.pipe(
    select(documents => documents.credittermsandconditions),
    shareReplay(1)
  );

  readonly feeSchedule$ = this.documents$.pipe(
    select(documents => documents.feeschedule),
    shareReplay(1)
  );

  readonly referralProgramTerms$ = this.documents$.pipe(
    select(documents => documents.referralprogram),
    shareReplay(1)
  );

  readonly referralProgramInfoLink$ = this.config$.pipe(
    select(config => config.friendInviteLink),
    shareReplay(1)
  );

  public isUserError(statusCode: number): boolean {
    return statusCode > 399 && statusCode < 500;
  }

  public setLoadingStatus(loading: boolean): void {
    this.store$.dispatch(new SetLoadingStatus(loading));
  }

  public handleFatalError(error: any, options: IErrorDisplayOptions = {}): void {
    this.store$.dispatch(new MarkServerError({error, displayOptions: options}));
    this.store$.dispatch(new ClearError());
  }

  public clearUserServerError(name: string): void {
    this.store$.dispatch(new ClearUserServerError(name));
  }

  public addRouterHistory(route: string): void {
    this.store$.dispatch(new AddRouterHistory(route));
  }

  public updateRouterHistory(routes: string[]): void {
    this.store$.dispatch(new UpdateRouterHistory(routes));
  }

  public clearAllRouterHistory(): void {
    this.store$.dispatch(new ClearAllRouterHistory());
  }

  public setPageTitle(title: string): void {
    this.store$.dispatch(new SetPageTitle(title));
  }

  public goBack(): void {
    this.store$.dispatch(new GoBack());
  }

  public setMainScreenActive(isActive: boolean): void {
    this.store$.dispatch(new SetMainScreenActive(isActive));
  }

  public changeFieldStatus(isWaiting: boolean, text: string): void {
    clearInterval(this.timeout);

    this.isWaiting = isWaiting;
    this.changeStatusText = text;
    this.fieldChanged$.next(true);

    this.timeout = setTimeout(() => {
      this.fieldChanged$.next(false);
      clearTimeout(this.timeout);
    }, 5000);
  }

  public showToast(isInfo: boolean, text: string): void {
    this.isWaiting = isInfo;
    this.changeStatusText = text;
    this.isToastShown$.next(true);
  }
}
