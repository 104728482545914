import {shareReplay, map, distinctUntilChanged, filter} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {isEqual, map as lodashMap, find, orderBy} from 'lodash-es';

import {
  IMarketDetails,
  IMarketDetailsResponse,
  IMarketList,
  IMarketListResponse,
  IRateModel,
  ITransformedWalletChart,
  IWalletChart
} from '@wallex-core-client/core/interfaces/rate.interface';
import {
  LoadRates,
  SetRateForAccount,
  ClearRates,
  LoadRatesSuccess,
  LoadWalletCharts,
  LoadWalletChart,
  LoadMarketList,
  LoadMarketDetails
} from '@wallex-core/store/rates/rates.actions';
import {IRatesState, selectRatesState} from '@wallex-core/store/rates/rates-state.model';
import {IWallet} from '@wallex-core-client/core/interfaces/wallet.interface';
import {IAsset} from '@wallex-core-client/core/interfaces/asset.interface';

@Injectable({
  providedIn: 'root'
})
export class RatesStateFacade {
  constructor(private readonly store$: Store<IRatesState>) {}

  private readonly state$ = this.store$.pipe(select(selectRatesState));

  readonly rates$ = this.state$.pipe(
    select(state => state.rates),
    shareReplay(1)
  );

  readonly walletCharts$ = this.state$.pipe(
    select(state => state.walletCharts),
    filter(walletCharts => Boolean(walletCharts.length)),
    distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
    map(this.transformCharts),
    shareReplay(1)
  );

  readonly marketList$ = this.state$.pipe(
    select(state => state.marketList),
    map(marketList => marketList?.filter(data => data.rate.rate && data.priceChange.diffRate)),
    shareReplay(1)
  );

  readonly topEarnersMarketList$ = this.marketList$.pipe(
    map(marketList => orderBy(marketList, item => item.priceChange.diffRate, 'desc')),
    shareReplay(1)
  );

  readonly topLosersMarketList$ = this.marketList$.pipe(
    map(marketList => orderBy(marketList, item => item.priceChange.diffRate, 'asc')),
    shareReplay(1)
  );

  readonly isMarketListLoading$ = this.state$.pipe(
    select(state => state.isMarketListLoading),
    shareReplay(1)
  );

  readonly isMarketListFailure$ = this.state$.pipe(
    select(state => state.isMarketListFailure),
    shareReplay(1)
  );

  readonly marketDetails$ = this.state$.pipe(
    select(state => state.marketDetails),
    shareReplay(1)
  );

  readonly isMarketDetailsLoading$ = this.state$.pipe(
    select(state => state.isMarketDetailsLoading),
    shareReplay(1)
  );

  readonly isMarketDetailsFailure$ = this.state$.pipe(
    select(state => state.isMarketDetailsFailure),
    shareReplay(1)
  );

  public getRatesForAsset(asset: string): Observable<number> {
    return this.rates$.pipe(
      map(rates => {
        return rates[asset]?.rate;
      })
    );
  }

  public loadRates(): void {
    this.store$.dispatch(new LoadRates());
  }

  public setRateForAccount(asset: string, rate: IRateModel): void {
    this.store$.dispatch(new SetRateForAccount(asset, rate));
  }

  public setRates(rates: Record<string, IRateModel>): void {
    this.store$.dispatch(new LoadRatesSuccess(rates));
  }

  public clearRates(): void {
    this.store$.dispatch(new ClearRates());
  }

  public loadWalletCharts(): void {
    this.store$.dispatch(new LoadWalletCharts());
  }

  public loadWalletChart(wallet: IWallet): void {
    this.store$.dispatch(new LoadWalletChart(wallet));
  }

  public loadMarketList(assets: IAsset[]): void {
    this.store$.dispatch(new LoadMarketList(assets));
  }

  public loadMarketDetails(currency: string, asset: IAsset): void {
    this.store$.dispatch(new LoadMarketDetails(currency, asset));
  }

  public getWalletChartByAsset$(asset: string): Observable<ITransformedWalletChart | undefined> {
    return this.walletCharts$.pipe(
      map(walletCharts => {
        return walletCharts.find(walletChart => walletChart.asset === asset);
      })
    );
  }

  private transformCharts(walletCharts: IWalletChart[]): ITransformedWalletChart[] {
    return walletCharts.map(walletChart => {
      const timestamps = lodashMap(walletChart.data, item => new Date(item.timestamp * 1000));
      const prices = lodashMap(walletChart.data, 'price');
      const initialPrice = prices[0];
      const finalPrice = prices[prices.length - 1];
      const diffRate = ((finalPrice - initialPrice) / initialPrice) * 100;

      return {
        asset: walletChart.asset,
        timestamps,
        prices,
        diffRate: diffRate.toFixed(2),
        isGrowing: diffRate > 0
      };
    });
  }

  public transformMarketList({rates, charts, priceChanges, assets}: IMarketListResponse): IMarketList[] {
    return lodashMap(assets, asset => ({
      rate: rates[asset.shortcode],
      chart: find(this.transformCharts(charts), {asset: asset.shortcode}),
      priceChange: priceChanges[asset.shortcode],
      asset,
      isEarner: priceChanges[asset.shortcode].diffRate > 0
    }));
  }

  public transformChartDetails(marketDetails: IMarketDetailsResponse): IMarketDetails {
    const charts = [marketDetails.dayChart, marketDetails.weekChart, marketDetails.monthChart, marketDetails.threeMonthChart, marketDetails.yearChart];
    const [dayChart, weekChart, monthChart, threeMonthChart, yearChart] = this.transformCharts(charts.flat());

    return {
      rate: marketDetails.rate[marketDetails.asset.shortcode],
      dayChart,
      weekChart,
      monthChart,
      threeMonthChart,
      yearChart
    };
  }
}
