import {createFeatureSelector} from '@ngrx/store';

import {defaultBanners} from '@wallex-core-client/core/constants/banners-constants';
import {IBanner} from '@wallex-core-client/core/interfaces/banner.interface';

export const bannersStateKey = 'banners';

export interface IBannersState {
  readonly mainScreenBanners: IBanner[];
  readonly authScreenBanners: IBanner[];
}

export const initialBannersState: IBannersState = {
  mainScreenBanners: defaultBanners,
  authScreenBanners: []
};

export const selectBannersState = createFeatureSelector<IBannersState>(bannersStateKey);
