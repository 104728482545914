<div class="amount-input__wrapper">
  <input
    type="text"
    class="form-control"
    appNumberOnly
    [id]="inputId"
    [maxAmountLength]="maxlength"
    [appMinAmount]="account?.decimals"
    [placeholder]="inputPlaceholder"
    [disabled]="isDisabled"
    [value]="value"
    [ngClass]="{'control-invalid': isControlInvalid, 'bigger-padding-right': shortcode}"
    (input)="onChange($event)"
    (blur)="touched(); blur.emit()"
  />
  <span *ngIf="shortcode" class="shortcode">{{ shortcode }}</span>
  <a class="amount-input__put-all" href="#" (click)="putAllFunds($event)" *ngIf="isAllButtonShown && account">{{ 'account.send-dialog.all' | translate }}</a>
  <span class="amount-input__asset" *ngIf="!isAllButtonShown && account && !isAssetHidden">{{ account.asset }}</span>
</div>

<ng-container *ngIf="isRateShown && value">
  <span class="secondary" *ngIf="rate"> ≈ {{ rate * (value | stringToNumber) | numberFr }} {{ currency }} </span>
</ng-container>
