import {TopUpTypes} from '@wallex-core-client/core/dtos/wallet.dto';

export interface IAsset {
  id: string;
  title: string;
  shortcode: string;
  contractAddress: string;
  baseCurrency: string;
  decimals: number;
  color: string;
  icon: string;
  isExchangeSupported: boolean;
  availableTopUpTypes: TopUpTypes[];
  availableTransferTypes: TopUpTypes[];
  assetType: assetType;
}

export interface IAssetFee {
  fee: string;
}

export enum assetType {
  crypto = 'crypto',
  fiat = 'fiat'
}
