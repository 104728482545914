import {Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-links-overlay',
  templateUrl: './links-overlay.component.html',
  styleUrls: ['./links-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinksOverlayComponent implements OnInit {
  @Output('toSettings') toSettingsEvent = new EventEmitter<void>();
  @Output('openLogout') openLogoutEvent = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
