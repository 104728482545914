import {createFeatureSelector} from '@ngrx/store';

import {IMarketDetails, IMarketList, IRateModel, IWalletChart} from '@wallex-core-client/core/interfaces/rate.interface';

import {APP_VERSION} from '@wallex-core-client/core/constants/main-constants';
import {getInitialState} from '@wallex-core-client/utils';

export const ratesStateKey = 'rates';

export interface IRatesState {
  readonly rates: Record<string, IRateModel>;
  readonly walletCharts: IWalletChart[];
  readonly marketList: IMarketList[] | null;
  readonly isMarketListLoading: boolean;
  readonly isMarketListFailure: boolean;
  readonly marketDetails: IMarketDetails | null;
  readonly isMarketDetailsLoading: boolean;
  readonly isMarketDetailsFailure: boolean;
}

export const blankRatesState = {
  rates: {},
  walletCharts: [],
  marketList: null,
  isMarketListLoading: false,
  isMarketListFailure: false,
  marketDetails: null,
  isMarketDetailsLoading: false,
  isMarketDetailsFailure: false
};

export const initialRatesState: IRatesState = getInitialState(APP_VERSION, ratesStateKey) || blankRatesState;

export const selectRatesState = createFeatureSelector<IRatesState>(ratesStateKey);
