import {Injectable} from '@angular/core';
import {isNull} from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() {}

  public setItem(key: string, value: any) {
    localStorage.setItem(key, btoa(JSON.stringify(value)));
  }

  public getItem(key: string): any | null {
    const item = localStorage.getItem(key);
    return isNull(item) ? null : JSON.parse(atob(item));
  }

  public removeItem(key: string) {
    localStorage.removeItem(key);
  }
}
