<div class="receive-dialog__options" [formGroup]="form">
  <label class="receive-dialog__option" *ngFor="let item of availableTopUpTypes" [for]="item" [ngClass]="{checked: form.value['receiveOption'] === item}">
    <span class="receive-dialog__option-text">{{ getOptionTitle(item) }}</span>
    <app-radio-button
      formControlName="receiveOption"
      name="receive"
      [inputId]="item"
      [value]="item"
      [isChecked]="form.value['receiveOption'] === item"
    ></app-radio-button>
  </label>
</div>

<div class="button-area">
  <button pButton [label]="buttonLabel | translate" (click)="receive()"></button>
</div>

<button class="circle-close reset-btn" (click)="ref.close()">
  <img src="/assets/close-circle.svg" alt="" width="44" height="44" />
</button>
