import {ChangeDetectionStrategy, Component, HostBinding, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';

import {TopUpTypes} from '@wallex-core-client/core/dtos/wallet.dto';

@Component({
  selector: 'app-receive-dialog',
  templateUrl: './receive-dialog.component.html',
  styleUrls: ['./receive-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReceiveDialogComponent implements OnInit {
  @HostBinding('class.receive-dialog') className: boolean = true;

  public form!: UntypedFormGroup;
  public availableTopUpTypes!: TopUpTypes[];
  public buttonLabel: string;

  constructor(public ref: DynamicDialogRef, private fb: UntypedFormBuilder, private config: DynamicDialogConfig, private translate: TranslateService) {}

  ngOnInit() {
    this.initializeValues();
  }

  private initializeValues(): void {
    this.availableTopUpTypes = this.config.data.availableTopUpTypes;
    this.buttonLabel = this.config.data.buttonLabel || 'account.receive';
    const isFormDisabled = this.config.data.isFormDisabled || false;
    const selectedOption = this.config.data.selectedOption || this.availableTopUpTypes[0];
    this.form = this.fb.group({
      receiveOption: [{value: selectedOption, disabled: isFormDisabled}, Validators.required]
    });
  }

  public getOptionTitle(topUpType: string): string {
    return this.translate.instant(`account.receive-dialog.${topUpType}`);
  }

  public receive(): void {
    this.ref.close(this.form.value.receiveOption);
  }
}
