export enum RoutePaths {
  dashboard = 'dashboard',
  auth = 'auth',
  signIn = 'sign-in',
  signUp = 'sign-up',
  confirmation = 'confirmation',
  exchange = 'exchange',
  markets = 'markets',
  cards = 'cards',
  newCard = 'new-card',
  limits = 'limits',
  transactionHistory = 'transaction-history',
  topUp = 'top-up',
  personalInfo = 'personal-info',
  kyc = 'kyc',
  settings = 'settings',
  twoFaSetup = '2fa-setup',
  twoFaConfirm = '2fa-confirm',
  twoFaSuccess = '2fa-success',
  twoFaAuth = '2fa-auth',
  businessAddress = 'business-address',
  companyDetails = 'company-details',
  businessPerson = 'business-person',
  businessRole = 'business-role',
  savings = 'savings',
  all_accounts = 'all-accounts',
  account = 'account',
  send = 'send',
  sendFiat = 'send-fiat',
  confirmFiat = 'confirm-fiat',
  confirmSend = 'confirm-send',
  topUpWithCard = 'top-up-with-card',
  successSend = 'success-send',
  transactionDetails = 'transaction-details',
  services = 'services',
  transfer = 'transfer',
  news = 'news',
  support = 'support',
  passwordRecovery = 'password-recovery',
  resetPassword = 'reset-password',
  newSaving = 'new-saving',
  confirm = 'confirm',
  archive = 'archive',
  savingDetails = 'details',
  deposit = 'deposit',
  notifications = 'notifications',
  messageDetails = 'message-details',
  buyCrypto = 'buy-crypto',
  credits = 'credits',
  newCredit = 'new-credit',
  details = 'details',
  repay = 'repay',
  adjust = 'adjust'
}

export class Routes {
  static signIn: string = `/${RoutePaths.auth}/${RoutePaths.signIn}`;
  static signUp: string = `/${RoutePaths.auth}/${RoutePaths.signUp}`;
  static kyc: string = `/${RoutePaths.auth}/${RoutePaths.kyc}`;
  static personalInfo: string = `/${RoutePaths.auth}/${RoutePaths.personalInfo}`;
  static confirmation: string = `/${RoutePaths.auth}/${RoutePaths.confirmation}`;
  static resetPassword: string = `/${RoutePaths.auth}/${RoutePaths.resetPassword}`;
  static passwordRecovery: string = `/${RoutePaths.auth}/${RoutePaths.passwordRecovery}`;
  static exchange: string = `/${RoutePaths.dashboard}/${RoutePaths.exchange}`;
  static markets: string = `/${RoutePaths.dashboard}/${RoutePaths.markets}`;
  static savings: string = `/${RoutePaths.dashboard}/${RoutePaths.savings}`;
  static cards: string = `/${RoutePaths.dashboard}/${RoutePaths.cards}`;
  static newCard: string = `/${RoutePaths.dashboard}/${RoutePaths.cards}/${RoutePaths.newCard}`;
  static limits: string = `/${RoutePaths.dashboard}/${RoutePaths.cards}/${RoutePaths.limits}`;
  static transactionHistory: string = `/${RoutePaths.dashboard}/${RoutePaths.cards}/${RoutePaths.transactionHistory}`;
  static topUp: string = `/${RoutePaths.dashboard}/${RoutePaths.cards}/${RoutePaths.topUp}`;
  static settings: string = `/${RoutePaths.dashboard}/${RoutePaths.settings}`;
  static referralProgram: string = `/${RoutePaths.dashboard}/${RoutePaths.settings}?tab=2`;
  static twoFaAuth: string = `/${RoutePaths.auth}/${RoutePaths.twoFaAuth}`;
  static businessAddress: string = `/${RoutePaths.auth}/${RoutePaths.businessAddress}`;
  static companyDetails: string = `/${RoutePaths.auth}/${RoutePaths.companyDetails}`;
  static businessPerson: string = `/${RoutePaths.auth}/${RoutePaths.businessPerson}`;
  static businessRole: string = `/${RoutePaths.auth}/${RoutePaths.businessRole}`;
  static twoFaSetup: string = `/${RoutePaths.dashboard}/${RoutePaths.settings}/${RoutePaths.twoFaSetup}`;
  static twoFaConfirm: string = `/${RoutePaths.dashboard}/${RoutePaths.settings}/${RoutePaths.twoFaConfirm}`;
  // twoFaConfirmOperation - page where we confirm our operations (for example - send crypto, send fiat)
  static twoFaConfirmOperation: string = `/${RoutePaths.dashboard}/${RoutePaths.settings}/${RoutePaths.twoFaConfirm}?confirm=true`;
  static twoFaSuccess: string = `/${RoutePaths.dashboard}/${RoutePaths.settings}/${RoutePaths.twoFaSuccess}`;
  static all_accounts: string = `/${RoutePaths.dashboard}/${RoutePaths.all_accounts}`;
  static account: string = `/${RoutePaths.dashboard}/${RoutePaths.account}`;
  static send: string = `/${RoutePaths.send}`;
  static sendFiat: string = `/${RoutePaths.sendFiat}`;
  static transactionDetails: string = `/${RoutePaths.transactionDetails}`;
  static confirm_send: string = `/${RoutePaths.confirmSend}`;
  static transfer: string = `/${RoutePaths.dashboard}/${RoutePaths.services}/${RoutePaths.transfer}`;
  static news: string = `/${RoutePaths.dashboard}/${RoutePaths.news}`;
  static support: string = `/${RoutePaths.dashboard}/${RoutePaths.services}/${RoutePaths.support}`;
  static services: string = `/${RoutePaths.dashboard}/${RoutePaths.services}`;
  static dashboard: string = `/${RoutePaths.dashboard}`;
  static confirm: string = `/${RoutePaths.dashboard}/${RoutePaths.savings}/${RoutePaths.newSaving}/${RoutePaths.confirm}`;
  static archive: string = `/${RoutePaths.dashboard}/${RoutePaths.savings}/${RoutePaths.archive}`;
  static savingDetails: string = `/${RoutePaths.dashboard}/${RoutePaths.savings}/${RoutePaths.savingDetails}`;
  static archiveDetails: string = `/${RoutePaths.dashboard}/${RoutePaths.savings}/${RoutePaths.archive}/${RoutePaths.savingDetails}`;
  static notifications: string = `/${RoutePaths.dashboard}/${RoutePaths.notifications}`;
  static messageDetails: string = `/${RoutePaths.messageDetails}`;
  static buyCrypto: string = `/${RoutePaths.dashboard}/${RoutePaths.buyCrypto}`;
  static credits: string = `/${RoutePaths.dashboard}/${RoutePaths.credits}`;
  static newCredit: string = `/${RoutePaths.dashboard}/${RoutePaths.credits}/${RoutePaths.newCredit}`;
  static creditDetails: string = `/${RoutePaths.dashboard}/${RoutePaths.credits}/${RoutePaths.details}`;
  static repayCredit: string = `/${RoutePaths.dashboard}/${RoutePaths.credits}/${RoutePaths.repay}`;
  static adjustCredit: string = `/${RoutePaths.dashboard}/${RoutePaths.credits}/${RoutePaths.adjust}`;

  static getTitle(route: string) {
    return `${route}.title`;
  }
}
