<div class="header__overlay-rewards">
  <div
    class="d-flex align-items-center reward-item"
    *ngIf="referralAmount"
    [ngClass]="{separator: !bonusesAmount && isReferralProgramEnabled, 'no-padding-bottom': !bonusesAmount && !isReferralProgramEnabled}"
  >
    <div class="rewards__icon-and-text">
      <img class="rewards__icon" src="/assets/icons/bonus.svg" width="21" height="20" />
      <span class="rewards__text">{{ 'rewards.bonus' | translate }}</span>
    </div>
    <span class="rewards__text">{{ symbol }}{{ referralAmount }}</span>
  </div>
  <div class="d-flex align-items-center reward-item" *ngIf="bonusesAmount" [ngClass]="isReferralProgramEnabled ? 'separator' : 'no-padding-bottom'">
    <div class="rewards__icon-and-text">
      <img class="rewards__icon" src="/assets/icons/rewards.svg" width="21" height="20" />
      <span class="rewards__text">{{ 'rewards.rewards' | translate }}</span>
    </div>
    <span class="rewards__text">{{ symbol }}{{ bonusesAmount }}</span>
  </div>

  <button name="referral-info" class="referral-info" *ngIf="isReferralProgramEnabled" (click)="openReferralInfo()">
    <img class="rewards__icon" src="/assets/icons/invite.svg" width="17" height="11" />
    <span>{{ 'rewards.invite-a-friend' | translate }}</span>
  </button>
</div>
