import {UserType} from './user-profile.interface';

export interface IRegisterPayload {
  email: string;
  password: string;
  cca3Code: string;
  userType: UserType;
  companyName?: string;
  token?: string;
}

export interface IRegisterResponse {
  id: string;
  email: string;
  canResendAfter: number;
}

export interface ILoginResponse {
  userId: string;
  token: ITokenData;
}

export interface IUserCredentials {
  email: string;
  password: string;
}

export interface IUserUpdateResponse {
  id: string;
  email: string;
  emailConfirmationSentAt: string;
  emailConfirmedAt: string;
  resetPasswordSentAt: string;
  createdAt: string;
  updatedAt: string;
  kycStatus: KycStatus;
  sumsubApplicantId: string | null;
  isTwoFactorAuthEnabled: boolean;
}

export interface ITokenData {
  accessExpiresIn: number;
  refreshExpiresIn: number;
  accessToken: string;
  refreshToken: string;
}

export enum KycStatus {
  pending = 'PENDING',
  approved = 'APPROVED',
  unverified = 'UNVERIFIED',
  rejected = 'REJECTED'
}
