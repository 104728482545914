import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {SegmentDistributionDto, TotalBalanceRefResponseDto} from '@wallex-core-client/core/dtos/balance.dto';
import {IWallet, IWalletBalance} from '@wallex-core-client/core/interfaces/wallet.interface';
import {WalletsStateFacade} from '@wallex-core/store/wallets/wallets-state-facade.service';
import {ENVIRONMENT_TOKEN} from '@wallex-core-client/core/constants/main-constants';
import {IEnvironment} from '@wallex-core-client/core/interfaces/common.interface';
import {UserStateFacade} from '@wallex-core/store/user/user-state-facade.service';
import {RatesService} from '@wallex-core/services/rate.service';

@Injectable({
  providedIn: 'root'
})
export class BalanceService {
  private host!: string;

  constructor(
    private http: HttpClient,
    private walletsStateFacade: WalletsStateFacade,
    private userStateFacade: UserStateFacade,
    private ratesService: RatesService,
    @Inject(ENVIRONMENT_TOKEN) environment: IEnvironment
  ) {
    this.host = environment.serverBaseUri;
  }

  public getTotalBalance(): Observable<Record<string, TotalBalanceRefResponseDto>> {
    return this.http.get<Record<string, TotalBalanceRefResponseDto>>(`${this.host}/balance/total`).pipe(
      tap(response => {
        const totalBalance: Record<string, number> = {};
        const segmentDistributions: Record<string, SegmentDistributionDto> = {};

        for (const refCurrency in response) {
          totalBalance[refCurrency] = parseFloat(response[refCurrency].totalBalance);
          segmentDistributions[refCurrency] = response[refCurrency].segmentDistribution;
        }

        this.walletsStateFacade.setTotalBalance(totalBalance);
        this.walletsStateFacade.setSegmentDistributions(segmentDistributions);
        return response;
      })
    );
  }

  public getBalance(wallet: IWallet): Observable<IWalletBalance> {
    return forkJoin({
      balance: this.http.get<Record<string, number>>(`${this.host}/balance/${wallet.asset}`).pipe(map(balance => balance[wallet.asset])),
      rate: this.ratesService.loadRate(wallet.asset)
    }).pipe(
      map(result => {
        return {...result, walletId: wallet.id};
      })
    );
  }

  public getBalanceInRefCurrency(wallet: IWallet): Observable<number> {
    return this.userStateFacade.currency$.pipe(
      map(currency => {
        for (const asset in wallet.balancesInRefCurrencies) {
          if (asset.toUpperCase() === currency) {
            return wallet.balancesInRefCurrencies[asset]?.refCurrencyValue;
          }
        }
        return 0;
      })
    );
  }
}
