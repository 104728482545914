import {isNil, find} from 'lodash-es';

import {blankUserState, initialUserState, IUserState} from '@wallex-core/store/user/user-state.model';
import {IOrganization, IUser} from '@wallex-core-client/core/interfaces/user-profile.interface';
import {UserActionType} from '@wallex-core/store/user/user.actions';
import {NeoBankingAction} from '@wallex-core/store/action.model';

export function userReducer(state = initialUserState, action: NeoBankingAction): IUserState {
  switch (action.type) {
    case UserActionType.ADD_USER:
      return {
        ...state,
        isPersonalInfoSpecified: isPersonalInfoSpecified(action.user),
        user: {...action.user},
        currentOrganization: getCurrentOrganization(action.user)
      };

    case UserActionType.UPDATE_USER_PROFILE:
      return {
        ...state,
        user: {...state.user, profile: action.profile}
      };

    case UserActionType.ADD_USER_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          profile: action.profile
        },
        isPersonalInfoSpecified: true
      };

    case UserActionType.SET_CONFIRMATION_CODE_VALIDITY_TIME_STAMP:
      return {
        ...state,
        canResendConfirmationCodeAfter: action.timeStamp
      };

    case UserActionType.SET_KYC_STATUS:
      return {
        ...state,
        KYCStatus: action.status
      };

    case UserActionType.SET_EMAIL_CONFIRMATION:
      return {
        ...state,
        isEmailConfirmed: true,
        canResendConfirmationCodeAfter: 0
      };

    case UserActionType.CLEAR_EMAIL_CONFIRMATION:
      return {
        ...state,
        isEmailConfirmed: false
      };

    case UserActionType.UPDATE_TWO_FA_REQUEST_PAYLOAD:
      return {
        ...state,
        twoFaRequestPayload: action.twoFaRequestPayload
      };

    case UserActionType.UPDATE_TWO_FA_QR_CODE:
      return {
        ...state,
        twoFaQrCode: action.twoFaQrCode
      };

    case UserActionType.UPDATE_TWO_FA_PENDING:
      return {
        ...state,
        twoFaPending: action.twoFaPending
      };

    case UserActionType.UPDATE_ORGANIZATION:
      return {
        ...state,
        currentOrganization: action.currentOrganization
      };

    case UserActionType.SET_IS_USER_DELETION_REQUESTED:
      return {
        ...state,
        user: {
          ...state.user,
          deletionRequestedAt: action.date
        }
      };

    case UserActionType.SET_LAST_TRANSACTIONS:
      return {
        ...state,
        lastTransactions: action.lastTransactions
      };

    case UserActionType.LOAD_LAST_TRANSACTIONS_FAILURE:
      return {
        ...state,
        lastTransactions: []
      };

    case UserActionType.UPLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            profileImageLink: action.avatarLink
          }
        }
      };

    case UserActionType.DELETE_AVATAR_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            profileImageLink: null
          }
        }
      };

    case UserActionType.SET_REFERRAL_CODE_ACTIVATED:
      return {
        ...state,
        isReferralCodeActivated: true
      };

    case UserActionType.CLEAR_USER_STATE:
      return {...blankUserState};

    default:
      return state;
  }
}

const isPersonalInfoSpecified = (user: Partial<IUser>): boolean => {
  return !isNil(user?.profile?.lastName) && !isNil(user?.profile?.firstName);
};

const getCurrentOrganization = (user: IUser): IOrganization | null => {
  if (!user.currentAccountId || !user.businessAccounts.length) return null;

  return find(user.businessAccounts, {id: user.currentAccountId}) as unknown as IOrganization;
};
