import {AfterViewInit, Component, ContentChild, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef} from '@angular/core';

import {PAGINATION_FIRST_PAGE} from '@wallex-core-client/core/constants/main-constants';
import {isIOS} from '@wallex-core-client/utils';

@Component({
  selector: 'app-infinite-scroll',
  templateUrl: './infinite-scroll.component.html',
  styleUrls: ['./infinite-scroll.component.scss']
})
export class InfiniteScrollComponent implements OnInit, AfterViewInit {
  @ContentChild('body', {static: false}) bodyTemplateRef!: TemplateRef<any>;
  @Input() page: number = PAGINATION_FIRST_PAGE;
  @Input() count!: number;
  @Input() set containerHeight(containerHeight: string) {
    this.setContainerHeight(containerHeight);
  }
  @Input() data!: any;
  @Input() isLoading!: boolean;
  @Input() isScrollOffsetEnabled: boolean = false;
  @Output() loadNext = new EventEmitter<number>();

  private readonly threshold: number = 30;

  constructor(private readonly elRef: ElementRef) {}

  ngOnInit(): void {}

  @HostListener('scroll', []) onHostScroll() {
    const {scrollTop, scrollHeight, clientHeight} = this.elRef.nativeElement;
    const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);

    if (this.count === this.data.length) return;

    if (!this.isLoading && distanceFromBottom < this.threshold) {
      this.page += 1;
      this.loadNext.emit(this.page);
    }
  }

  private setContainerHeight(containerHeight: string): void {
    this.elRef.nativeElement.style.height = containerHeight;
  }

  @HostListener('window:resize', ['$event']) onResize(): void {
    this.setScrollOffset();
  }

  ngAfterViewInit() {
    this.setScrollOffset();
    if (isIOS() && this.isContentOverflown()) {
      this.elRef.nativeElement.classList.add('ios-overflown');
    }
  }

  isContentOverflown() {
    const {scrollHeight, clientHeight} = this.elRef.nativeElement;
    return scrollHeight > clientHeight;
  }

  public setScrollOffset() {
    if (!this.isScrollOffsetEnabled) return;
    const container = this.elRef.nativeElement;
    container.style.marginRight = container.scrollHeight > container.clientHeight ? '-6px' : '0';
  }
}
