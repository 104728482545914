import fromExponential from 'from-exponential';

import {commaToDot, numberToString, stringToNumber} from './number-replacer.utils';

export const toFixedHard = (number: number, x: number) => {
  const s = String(number);
  const [a, b = ''] = s.split('.');
  return parseFloat(a + '.' + b.substring(0, x));
};

export const getMinAmount = (decimals: number): number => {
  return 1 / Math.pow(10, decimals);
};

export const getDecimalParameter = (decimals: number | undefined | null): string => {
  if (decimals) {
    return `1.0-${decimals}`;
  }
  return '1.0-0';
};

export const toPercent = (value: string | number): number => {
  const percents = 100;
  return +value / percents;
};

export const cropNumber = (value?: string | number | null, decimals?: number | null | string, isWithComma = false) => {
  if (!value || !decimals) return '0';

  const COMMA_LENGTH = 1;

  let number: any = fromExponential(stringToNumber(value));
  number = numberToString(number);

  const lengthOfWholePart = number.split(',')[0].length + COMMA_LENGTH;
  const lengthOfCroppedNumber = +decimals + lengthOfWholePart;

  if (number.length > lengthOfCroppedNumber) {
    number = number.split('').slice(0, lengthOfCroppedNumber).join('');

    if (!stringToNumber(number)) return 0;
  }

  if (isWithComma) return number;
  return commaToDot(number);
};
