import {differenceInDays, differenceInSeconds, format, parse, startOfMonth, subDays, subMonths} from 'date-fns';

import {DATE_FORMAT, DATE_FORMAT_FROM_BACKEND} from '@wallex-core-client/core/constants/main-constants';

export const diffDates = (firstDate: string, secondDate: string): number => {
  const specifiedFirstDate = parse(firstDate, DATE_FORMAT, new Date());
  const specifiedSecondDate = parse(secondDate, DATE_FORMAT, new Date());
  const duration = differenceInDays(specifiedSecondDate, specifiedFirstDate);

  return Math.ceil(duration);
};

export const diffDateInSeconds = (firstDate: number | Date, secondDate: Date | number): number => {
  const duration = differenceInSeconds(firstDate, secondDate);
  return Math.floor(duration);
};

export const getPreviousDateISO = (days: number) => {
  const currentDate = new Date();
  const previousDate = subDays(currentDate, days);
  return previousDate.toISOString();
};

export const dateToFormat = (date: string | undefined | null, fromFormat = DATE_FORMAT_FROM_BACKEND, toFormat = DATE_FORMAT): string | null => {
  if (!date) return null;

  const parsedDate = parse(date, fromFormat, new Date());
  return format(parsedDate, toFormat);
};

export const previousMonth = (date: string | Date) => {
  const currentDate = new Date(date);
  const previousMonth = subMonths(currentDate, 1);
  const firstDayOfPreviousMonth = startOfMonth(previousMonth);

  return format(firstDayOfPreviousMonth, DATE_FORMAT_FROM_BACKEND);
};

export const isSameMonthAndYear = (firstDate: string | Date, secondDate: string | Date): boolean => {
  const formattedFirstDate = new Date(firstDate);
  const formattedSecondDate = new Date(secondDate);

  return formattedFirstDate.getFullYear() === formattedSecondDate.getFullYear() && formattedFirstDate.getMonth() === formattedSecondDate.getMonth();
};

export const firstDateOfMonth = (date: string | Date): string => {
  const requestedDate = new Date(date);
  const firstDayOfMonth = startOfMonth(requestedDate);

  return format(firstDayOfMonth, DATE_FORMAT_FROM_BACKEND);
};

export const isDateDifferent = (currentDate: string, previousDate: string): boolean => {
  const currentItemDate = format(new Date(currentDate), DATE_FORMAT);
  const previousItemDate = format(new Date(previousDate), DATE_FORMAT);
  return currentItemDate !== previousItemDate;
};

export const daysToMonths = (duration: string | number | undefined) => (Number(duration) / 30).toFixed(0);
