import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {DisabledAmountInputComponent} from '@wallex-core-client/ui';
import {CropNumberPipeModule} from '@wallex-core-client/pipes';

@NgModule({
  declarations: [DisabledAmountInputComponent],
  imports: [CropNumberPipeModule, CommonModule],
  exports: [DisabledAmountInputComponent]
})
export class DisabledAmountInputModule {}
