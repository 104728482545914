import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Pipe({
  name: 'numberFr'
})
export class NumberFrPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number | string | null, digitsInfo = '1.2-2', locale = 'fr'): string | null {
    return this.decimalPipe.transform(value, digitsInfo, locale);
  }
}
