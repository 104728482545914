<p class="header__overlay-switch-text" *ngIf="businessAccounts && businessAccounts.length > 1">
  {{ 'header.overlay.set_active_account' | translate }}
</p>
<div class="header__overlay-user subheader" (click)="openOverlay(switchOP, $event)">
  <div class="d-flex align-items-center">
    <app-avatar [profileAvatar]="profileAvatar" [isIndividualAccount]="true" width="40px" height="40px"></app-avatar>
    <div>
      <div class="user-name-and-kys-status">
        <p class="header__overlay-username">{{ userProfile?.firstName }} {{ userProfile?.lastName }}</p>
        <app-kyc-status></app-kyc-status>
      </div>
      <p class="header__overlay-email text-truncate">{{ email }}</p>
    </div>
  </div>
  <img *ngIf="businessAccounts && businessAccounts.length > 1" class="mr-0 ml-1" src="/apps/neobanking/src/assets/arrow-right.svg" alt="switch account" />
</div>
<app-links-overlay (toSettings)="toSettingsEvent.emit()" (openLogout)="openLogoutEvent.emit()"></app-links-overlay>

<p-overlayPanel styleClass="header__overlay switch-overlay" #switchOP [style]="{'min-width': '330px'}">
  <ng-template pTemplate>
    <app-accounts-switch-overlay></app-accounts-switch-overlay>
  </ng-template>
</p-overlayPanel>
