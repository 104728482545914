import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MenuItem} from 'primeng/api';
import {Subscription} from 'rxjs';

import {ENVIRONMENT_TOKEN, MENU_ITEMS_TOKEN} from '@wallex-core-client/core/constants/main-constants';
import {referralProgramMenuItem} from '@wallex-core/constants/optional-menu-items.constants';
import {IEnvironment} from '@wallex-core-client/core/interfaces/common.interface';
import {AppStateFacade} from '@wallex-core/store/app/app-state-facade.service';
import {Routes} from '@wallex-core/constants/routes.enum';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit, OnDestroy {
  public routes = Routes;
  public items: MenuItem[];
  @Output() menuItemClicked = new EventEmitter<boolean>();
  subscriptions: Subscription[];

  constructor(
    private translate: TranslateService,
    private appStateFacade: AppStateFacade,
    @Inject(ENVIRONMENT_TOKEN) private environment: IEnvironment,
    @Inject(MENU_ITEMS_TOKEN) private menuItems: MenuItem[]
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
  }

  private initSubscriptions() {
    this.subscriptions = [
      this.appStateFacade.isReferralProgramEnabled$.subscribe(isReferralProgramEnabled => {
        let menuItems: MenuItem[] = [...this.menuItems];
        if (isReferralProgramEnabled) {
          menuItems.push(referralProgramMenuItem);
        }
        this.initMenuItems(menuItems);
      })
    ];
  }

  public initMenuItems(menuItems: MenuItem[]): void {
    this.items = menuItems.map(menuItem => ({
      ...menuItem,
      label: this.translate.instant(menuItem.label!),
      command: () => {
        this.menuItemClicked.emit(false);
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
