import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {NumberLimitPipeModule} from '@wallex-core-client/pipes';
import {BadgeComponent} from '@wallex-core-client/ui';

@NgModule({
  declarations: [BadgeComponent],
  imports: [CommonModule, NumberLimitPipeModule],
  exports: [BadgeComponent]
})
export class BadgeModule {}
