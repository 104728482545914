import {ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, switchMap} from 'rxjs';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';

import {UserStateFacade} from '@wallex-core/store/user/user-state-facade.service';
import {TwoFaService} from '@wallex-core/services/2fa.service';
import {Routes} from '@wallex-core/constants/routes.enum';

@Injectable()
export class TwoFaGuard implements CanDeactivate<any>, CanActivate {
  constructor(private router: Router, private userStateFacade: UserStateFacade, private twoFaService: TwoFaService) {}

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.userStateFacade.isKYCVerificationCompleted$.pipe(
      switchMap(isKYCVerificationCompleted => {
        return this.userStateFacade.isTwoFaEnabled$.pipe(map(isTwoFaEnabled => [isKYCVerificationCompleted, isTwoFaEnabled]));
      }),
      map(([isKYCVerificationCompleted, isTwoFaEnabled]) => {
        if (!isKYCVerificationCompleted) return true;

        if (!nextState || (nextState && this.twoFaService.isUserAllowedToOpenRoute(nextState.url, isTwoFaEnabled))) {
          return true;
        }

        this.twoFaService.initTwoFa();
        return false;
      })
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.userStateFacade.isTwoFaEnabled$.pipe(
      map(isTwoFaEnabled => {
        if (!state || (state && this.twoFaService.isUserAllowedToOpenRoute(state.url, isTwoFaEnabled))) {
          return true;
        }

        if (this.router.routerState.snapshot.url === '') {
          this.router.navigate([Routes.dashboard]).catch(() => null);
        }

        this.twoFaService.initTwoFa();
        return false;
      })
    );
  }
}
