import {NgModule} from '@angular/core';

import {AsyncPipe, NgClass, NgIf} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

import {CropNumberPipeModule, NumberFrPipeModule} from '@wallex-core-client/pipes';
import {TotalAmountContainerComponent} from './total-amount-container.component';

@NgModule({
  declarations: [TotalAmountContainerComponent],
  exports: [TotalAmountContainerComponent],
  imports: [CropNumberPipeModule, NgIf, TranslateModule, AsyncPipe, NgClass, NumberFrPipeModule]
})
export class TotalAmountContainerModule {}
