import {DomSanitizer} from '@angular/platform-browser';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'safe'})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(data: string | null, isResource: boolean = false) {
    if (!data) return;
    if (isResource) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(data);
    }

    return this.sanitizer.bypassSecurityTrustHtml(data);
  }
}
