import {Component, ChangeDetectionStrategy, Output, EventEmitter, Input} from '@angular/core';

import {IOrganization, IProfile} from '@wallex-core-client/core/interfaces/user-profile.interface';

@Component({
  selector: 'app-individual-overlay',
  templateUrl: './individual-overlay.component.html',
  styleUrls: ['./individual-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndividualOverlayComponent {
  @Output('toSettings') toSettingsEvent = new EventEmitter<void>();
  @Output('openLogout') openLogoutEvent = new EventEmitter<void>();
  @Input() isIndividualAccount!: boolean | null;
  @Input() businessAccounts!: IOrganization[] | null;
  @Input() userProfile!: IProfile | null;
  @Input() email?: string;
  @Input() profileAvatar: string | null;

  public openOverlay(overlay: any, event: any): void {
    if (this.isIndividualAccount && this.businessAccounts && this.businessAccounts.length > 1) {
      overlay.toggle(event);
    }
  }
}
