import {MenuModule} from 'primeng/menu';
import {NgModule} from '@angular/core';

import {CommonModule, NgOptimizedImage} from '@angular/common';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';

import {ToastModule} from 'primeng/toast';

import {ReactiveFormsModule} from '@angular/forms';

import {AccountsSwitchOverlayComponent} from '@wallex-core/features/dashboard/header/accounts-switch-overlay/accounts-switch-overlay.component';
import {AdditionalSolutionsComponent} from '@wallex-core/features/dashboard/header/additional-solutions/additional-solutions.component';
import {HeaderMessageTextComponent} from '@wallex-core/features/dashboard/header/header-message-text/header-message-text.component';
import {IndividualOverlayComponent} from '@wallex-core/features/dashboard/header/individual-overlay/individual-overlay.component';
import {NotificationBellComponent} from '@wallex-core/features/dashboard/header/notification-bell/notification-bell.component';
import {BusinessOverlayComponent} from '@wallex-core/features/dashboard/header/business-overlay/business-overlay.component';
import {LinksOverlayComponent} from '@wallex-core/features/dashboard/header/links-overlay/links-overlay.component';
import {ChangeLanguageDialogComponent} from './header/change-language-dialog/change-language-dialog.component';
import {KycStatusComponent} from '@wallex-core/features/dashboard/header/kyc-status/kyc-status.component';
import {RewardsComponent} from '@wallex-core/features/dashboard/header/rewards/rewards.component';
import {DashboardComponent} from '@wallex-core/features/dashboard/dashboard/dashboard.component';
import {LeftMenuComponent} from '@wallex-core/features/dashboard/left-menu/left-menu.component';
import {BurgerAnimationComponent} from './header/burger-animation/burger-animation.component';
import {KycGuardDirectiveModule} from '@wallex-core/directives/kyc-guard/kyc-guard.module';
import {HeaderComponent} from '@wallex-core/features/dashboard/header/header.component';
import {AvatarModule, BadgeModule} from '@wallex-core-client/ui';

@NgModule({
  declarations: [
    DashboardComponent,
    LeftMenuComponent,
    HeaderComponent,
    AccountsSwitchOverlayComponent,
    BusinessOverlayComponent,
    HeaderMessageTextComponent,
    IndividualOverlayComponent,
    LinksOverlayComponent,
    AdditionalSolutionsComponent,
    RewardsComponent,
    BurgerAnimationComponent,
    KycStatusComponent,
    ChangeLanguageDialogComponent,
    NotificationBellComponent
  ],
  imports: [
    MenuModule,
    AvatarModule,
    OverlayPanelModule,
    CommonModule,
    TranslateModule,
    ButtonModule,
    DialogModule,
    NgOptimizedImage,
    KycGuardDirectiveModule,
    ToastModule,
    ReactiveFormsModule,
    BadgeModule
  ]
})
export class DashboardModule {}
