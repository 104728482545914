import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {TranslateModule} from '@ngx-translate/core';

import {CropNumberPipeModule, NumberFrPipeModule} from '@wallex-core-client/pipes';
import {FeeContainerComponent} from './fee-container.component';

@NgModule({
  declarations: [FeeContainerComponent],
  exports: [FeeContainerComponent],
  imports: [CommonModule, CropNumberPipeModule, TranslateModule, NumberFrPipeModule]
})
export class FeeContainerModule {}
