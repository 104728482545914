import {combineLatestWith} from 'rxjs/operators';
import {pipe, map, BehaviorSubject} from 'rxjs';

import {IWallet} from '@wallex-core-client/core/interfaces/wallet.interface';
import {IAsset} from '@wallex-core-client/core/interfaces/asset.interface';

export const searchAssets = (searchTerm$: BehaviorSubject<string>) =>
  pipe(
    combineLatestWith(searchTerm$),
    map(([assets, searchTerm]) => {
      if (!searchTerm || !searchTerm.trim().length) {
        return assets as IAsset[];
      }
      const assetsFilter = (asset: IAsset) => {
        return (
          asset.title.toLowerCase().indexOf(searchTerm.trim().toLowerCase()) !== -1 ||
          asset.shortcode.toLowerCase().indexOf(searchTerm.trim().toLowerCase()) !== -1
        );
      };
      return (assets as IAsset[]).filter(assetsFilter);
    })
  );

export const searchWallets = (searchTerm$: BehaviorSubject<string>) =>
  pipe(
    combineLatestWith(searchTerm$),
    map(([wallets, searchTerm]) => {
      if (!searchTerm || !searchTerm.trim().length) {
        return wallets as IWallet[];
      }
      const walletsFilter = (wallet: IWallet) => {
        return (
          wallet.title.toLowerCase().indexOf(searchTerm.trim().toLowerCase()) !== -1 ||
          wallet.asset.toLowerCase().indexOf(searchTerm.trim().toLowerCase()) !== -1
        );
      };
      return (wallets as IWallet[]).filter(walletsFilter);
    })
  );
