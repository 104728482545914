import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {defaultBanners} from '@wallex-core-client/core/constants/banners-constants';
import {ENVIRONMENT_TOKEN} from '@wallex-core-client/core/constants/main-constants';
import {IEnvironment} from '@wallex-core-client/core/interfaces/common.interface';
import {IBanner} from '@wallex-core-client/core/interfaces/banner.interface';

@Injectable({
  providedIn: 'root'
})
export class BannerService {
  private host!: string;

  constructor(private http: HttpClient, @Inject(ENVIRONMENT_TOKEN) private environment: IEnvironment) {
    this.host = environment.serverBaseUri;
  }

  public getBanners(): Observable<IBanner[]> {
    return this.http.get<IBanner[]>(`${this.host}/banner/active`).pipe(
      map(banners => {
        if (!banners.length) {
          return defaultBanners;
        }

        return this.sortBanners(banners);
      })
    );
  }

  private sortBanners(banners: IBanner[]): IBanner[] {
    return banners.sort((firstEl, secondEl) => {
      return secondEl.visualisationPosition - secondEl.visualisationPosition;
    });
  }
}
