<form class="form" *ngIf="form" [formGroup]="form">
  <div class="form-group">
    <label class="label">{{ 'account.send-dialog.amount' | translate }}</label>
    <app-amount-input
      formControlName="amount"
      [account]="account"
      [inputPlaceholder]="'common.enter-amount' | translate"
      inputId="amount"
      [isControlInvalid]="!!feeEstimateError"
      [isAllButtonShown]="false"
      [isRateShown]="false"
    ></app-amount-input>
    <span class="error-message" *ngIf="feeEstimateError">
      {{ feeEstimateError }}
    </span>
  </div>

  <div class="fee-container">
    <p class="fee-row">
      {{ 'account.top-up.estimated-fee' | translate }} <span class="fee-value">{{ fee | cropNumber : account.decimals }} {{ account.asset }}</span>
    </p>
    <p class="fee-row">
      {{ 'account.top-up.you-will-get' | translate }} <span class="fee-value">{{ totalSum | cropNumber : account.decimals }} {{ account.asset }}</span>
    </p>
  </div>
</form>

<div class="button-area">
  <button pButton label="Top up" (click)="submit()" [ngClass]="{'disabled-button': form.invalid || isConfirmClicked || feeEstimateError}"></button>
</div>

<button class="circle-close reset-btn" (click)="ref.close()">
  <img src="/assets/close-circle.svg" alt="" width="44" height="44" />
</button>
