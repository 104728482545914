import {cloneDeep, dropRight, isNull, last} from 'lodash-es';

import {ServerErrorMessage} from '@wallex-core-client/core/dtos/server-error.dto';
import {IAppState, initialAppState} from './app-state.model';
import {NeoBankingAction} from '../action.model';
import {AppActionType} from './app.actions';

export function appReducer(state = initialAppState, action: NeoBankingAction): IAppState {
  switch (action.type) {
    case AppActionType.SET_LOADING_STATUS:
      return {
        ...state,
        appStatus: {
          ...state.appStatus,
          isLoading: action.loading
        }
      };

    case AppActionType.MARK_APPLICATION_FATAL:
      return {
        ...state,
        appStatus: {
          ...state.appStatus,
          isLoading: false,
          hasFatalError: true
        }
      };

    case AppActionType.MARK_SERVER_ERROR:
      return {
        ...state,
        appStatus: {
          ...state.appStatus,
          isLoading: false,
          errorData: action.error
        }
      };

    case AppActionType.CLEAR_USER_SERVER_ERROR:
      const existingErrorData = cloneDeep(state.appStatus.errorData);
      if (!isNull(existingErrorData) && Array.isArray(existingErrorData?.error?.messages) && existingErrorData?.error?.messages.length) {
        const messages = existingErrorData.error.messages;
        const foundErrorMessageIndex = messages.findIndex((message: ServerErrorMessage) => message.type === action.name);
        if (foundErrorMessageIndex !== -1) {
          existingErrorData.error.messages.splice(foundErrorMessageIndex, 1);
        }
      }
      return {
        ...state,
        appStatus: {
          ...state.appStatus,
          errorData: existingErrorData
        }
      };

    case AppActionType.ADD_ROUTER_HISTORY:
      return {
        ...state,
        routerHistory: getUpdatedRouterHistory(state.routerHistory, action.route)
      };

    case AppActionType.UPDATE_ROUTER_HISTORY:
      return {
        ...state,
        routerHistory: action.routes
      };

    case AppActionType.GO_BACK_SUCCESS:
      return {
        ...state,
        routerHistory: dropRight(state.routerHistory, 2)
      };

    case AppActionType.CLEAR_ALL_ROUTER_HISTORY:
      const newRouterHistory = state.routerHistory.length > 0 ? [state.routerHistory[state.routerHistory.length - 1]] : [];
      return {
        ...state,
        routerHistory: newRouterHistory
      };

    case AppActionType.SET_PAGE_TITLE:
      return {
        ...state,
        title: action.title
      };

    case AppActionType.SET_MAIN_SCREEN_ACTIVE:
      return {
        ...state,
        isMainScreenActive: action.isActive
      };

    case AppActionType.CLEAR_ERROR:
      return {
        ...state,
        appStatus: {
          ...state.appStatus,
          isLoading: false,
          hasFatalError: false,
          errorData: null
        }
      };

    case AppActionType.DELETE_LAST_ROUTES:
      let routerHistory = [...state.routerHistory];
      routerHistory.length = routerHistory.length - action.routesNumber;

      return {
        ...state,
        routerHistory
      };

    default:
      return state;
  }
}

function getUpdatedRouterHistory(routerHistory: string[], route: string): string[] {
  const lastUrl = last(routerHistory)?.split('?')?.[0];
  const currentUrl = route.split('?')[0];

  if (lastUrl !== currentUrl) {
    return [...routerHistory, route];
  }

  return [...routerHistory];
}
