import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {HeaderMessageType, IHeaderMessage} from '@wallex-core-client/core/interfaces/common.interface';

@Component({
  selector: 'app-header-message-text',
  templateUrl: './header-message-text.component.html',
  styleUrls: ['./header-message-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderMessageTextComponent {
  @Input() message!: IHeaderMessage;
  public headerMessageType = HeaderMessageType;
}
