import {blankAssetsState, IAssetsState, initialAssetsState} from '@wallex-core/store/assets/assets-state.model';
import {AssetsActionType} from '@wallex-core/store/assets/assets.actions';
import {NeoBankingAction} from '@wallex-core/store/action.model';

export function assetsReducer(state = initialAssetsState, action: NeoBankingAction): IAssetsState {
  switch (action.type) {
    case AssetsActionType.LOAD_ASSETS_SUCCESS:
      return {
        ...state,
        assets: action.assets,
        isAssetsLoadingFailure: false
      };

    case AssetsActionType.LOAD_ASSETS_FAILURE:
      return {
        ...state,
        assets: [],
        isAssetsLoadingFailure: true
      };

    case AssetsActionType.CLEAR_ASSETS:
      return {...blankAssetsState};

    default:
      return state;
  }
}
