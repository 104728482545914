import {filter, map, shareReplay, withLatestFrom} from 'rxjs/operators';
import {combineLatest, Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';

import {
  ClearCredits,
  AddCreditAssets,
  AddCollateralAssets,
  AddCreditPeriods,
  AddLtvGradation,
  AddActiveCredits,
  AddArchiveCredits,
  RequestCredit,
  ClearRequestedCredit,
  RepayCredit,
  ClearRepaidCredit
} from '@wallex-core/store/credits/credits.actions';
import {
  ICredit,
  ICreditAsset,
  ICreditPeriod,
  ILtvGradation,
  IRequestedCredit,
  LtvGradationType,
  LtvGradationTypeExtended
} from '@wallex-core-client/core/interfaces/credits.interface';
import {ICreditsState, selectCreditsState} from '@wallex-core/store/credits/credits-state.model';
import {WalletsStateFacade} from '@wallex-core/store/wallets/wallets-state-facade.service';
import {IPaginatedList} from '@wallex-core-client/core/interfaces/api-response.interface';
import {AssetsStateFacade} from '@wallex-core/store/assets/assets-state-facade.service';
import {ltvGradationColorMap} from '@wallex-core/constants/ltv-meter-colors.constants';
import {IMeterDataset} from '@wallex-core-client/core/interfaces/meter.interface';
import {UserStateFacade} from '@wallex-core/store/user/user-state-facade.service';
import {IRateModel} from '@wallex-core-client/core/interfaces/rate.interface';
import {IWallet} from '@wallex-core-client/core/interfaces/wallet.interface';
import {IAsset} from '@wallex-core-client/core/interfaces/asset.interface';
import {daysToMonths} from '@wallex-core-client/utils';

@Injectable({
  providedIn: 'root'
})
export class CreditsStateFacade {
  private readonly state$ = this.store$.pipe(select(selectCreditsState));

  constructor(
    private readonly store$: Store<ICreditsState>,
    private readonly assetsStateFacade: AssetsStateFacade,
    private readonly walletsStateFacade: WalletsStateFacade,
    private readonly userStateFacade: UserStateFacade
  ) {}

  readonly creditAssets$ = this.state$.pipe(
    select(state => state.creditAssets),
    shareReplay(1)
  );

  readonly creditAssetsWithIcon$ = combineLatest([this.creditAssets$, this.assetsStateFacade.assets$]).pipe(
    filter(([creditAssets, assets]) => Boolean(creditAssets.length) && Boolean(assets.length)),
    map(([creditAssets, assets]) => this.filterAssets(creditAssets, assets)),
    shareReplay(1)
  );

  readonly collateralAssets$ = this.state$.pipe(
    select(state => state.collateralAssets),
    shareReplay(1)
  );

  readonly collateralAssetsShortcodes$ = this.collateralAssets$.pipe(
    map(collateralAssets => collateralAssets.map(asset => asset.shortcode)),
    shareReplay(1)
  );

  readonly collateralAssetsWithIcon$ = combineLatest([this.collateralAssets$, this.assetsStateFacade.assets$]).pipe(
    filter(([collateralAssets, assets]) => Boolean(collateralAssets.length) && Boolean(assets.length)),
    map(([collateralAssets, assets]) => this.filterAssets(collateralAssets, assets)),
    shareReplay(1)
  );

  readonly possibleCollateralWallets$ = combineLatest([this.collateralAssets$, this.walletsStateFacade.wallets$]).pipe(
    filter(([collateralAssets, wallets]) => Boolean(collateralAssets?.length)),
    map(([collateralAssets, wallets]) => this.filterWallets(collateralAssets, wallets)),
    shareReplay(1)
  );

  readonly creditPeriods$ = this.state$.pipe(
    select(state => state.creditPeriods),
    map(periods => [...periods].sort((a, b) => a.duration - b.duration).map(period => ({...period, duration: Number(daysToMonths(period.duration))}))),
    shareReplay(1)
  );

  readonly referenceAsset$ = this.creditPeriods$.pipe(
    map(periods => periods[0].refAsset),
    shareReplay(1)
  );

  readonly requestedCredit$ = this.state$.pipe(
    select(state => state.requestedCredit),
    shareReplay(1)
  );

  readonly repaidCredit$ = this.state$.pipe(
    select(state => state.repaidCredit),
    shareReplay(1)
  );

  readonly activeCredits$ = this.state$.pipe(
    filter(state => !!state.activeCredits),
    select(state => state.activeCredits.data),
    shareReplay(1)
  );

  readonly archiveCredits$ = this.state$.pipe(
    filter(state => !!state.archiveCredits),
    select(state => state.archiveCredits.data),
    shareReplay(1)
  );

  readonly isUserAllowedToTakeNewCredit$ = this.activeCredits$.pipe(
    withLatestFrom(this.userStateFacade.isDeletionRequested$),
    map(([activeCredits, isDeletionRequested]) => activeCredits.length < 4 && !isDeletionRequested),
    shareReplay(1)
  );

  getCreditPeriodById$(id: number): Observable<ICreditPeriod | undefined> {
    return this.creditPeriods$.pipe(map(periods => periods.find(period => period.id === id)));
  }

  getActiveCreditById$(id: string): Observable<ICredit | undefined> {
    return this.activeCredits$.pipe(map(activeCredits => activeCredits.find(credit => credit.id === id)));
  }

  readonly ltvGradation$ = this.state$.pipe(
    select(state => state.ltvGradation),
    shareReplay(1)
  );

  readonly maxAllowedLtvValue$ = this.ltvGradation$.pipe(
    map(gradations => {
      const lowGradation = gradations.find(gradation => gradation.type === LtvGradationType.low);
      if (!lowGradation) return 0;
      return lowGradation.maxValue;
    }),
    shareReplay(1)
  );

  public getMaxRemovableCollateralAmount(credit: ICredit, rates: Record<string, IRateModel>): Observable<number> {
    return this.maxAllowedLtvValue$.pipe(
      map(maxAllowedLtvValue => {
        const collateralAssetRate = rates[credit.collateralAsset].rate;
        const creditAssetRate = rates[credit.creditAsset].rate;
        const creditAmountInBaseRefCurrency = parseFloat(credit.creditAmount) * creditAssetRate;
        const collateralAmountInBaseRefCurrency = parseFloat(credit.collateralAmount) * collateralAssetRate;
        const minAllowedCollateralAmountInBaseRefCurrency = creditAmountInBaseRefCurrency / (maxAllowedLtvValue / 100);
        const maxRemovableCollateralAmountInBaseRefCurrency = collateralAmountInBaseRefCurrency - minAllowedCollateralAmountInBaseRefCurrency;
        return maxRemovableCollateralAmountInBaseRefCurrency / collateralAssetRate;
      })
    );
  }

  readonly ltvMeterData$ = this.ltvGradation$.pipe(
    filter(ltvGradation => !!ltvGradation.length),
    map(gradations => {
      const ltvMeterData: IMeterDataset[] = [];
      for (const gradation of gradations) {
        const correspondingColors = ltvGradationColorMap.get(gradation.type as unknown as LtvGradationTypeExtended);
        if (correspondingColors) {
          ltvMeterData.push({
            activeColor: correspondingColors.activeColor,
            inactiveColor: correspondingColors.inactiveColor,
            minValue: gradation.minValue,
            maxValue: gradation.maxValue
          });
        }
      }
      ltvMeterData.sort((a, b) => a.maxValue - b.maxValue);
      const lastBarData = ltvGradationColorMap.get(LtvGradationTypeExtended.liquidation);
      if (lastBarData && ltvMeterData.length) {
        ltvMeterData.push({
          activeColor: lastBarData.activeColor,
          inactiveColor: lastBarData.inactiveColor,
          minValue: ltvMeterData[ltvMeterData.length - 1].maxValue + 1,
          maxValue: 100
        });
      }
      return ltvMeterData;
    }),
    shareReplay(1)
  );

  public getLTVMeterDataByGradationType$(ltvGradationTypes: LtvGradationTypeExtended[]): Observable<IMeterDataset[]> {
    return this.ltvMeterData$.pipe(
      map(ltvMeterData => {
        const meterDataSet: IMeterDataset[] = [];
        for (const ltvGradationType of ltvGradationTypes) {
          const ltvGradationColors = ltvGradationColorMap.get(ltvGradationType);
          if (ltvGradationColors) {
            const requiredMeterData = ltvMeterData.find(ltvData => ltvData.activeColor === ltvGradationColors.activeColor);
            if (requiredMeterData) {
              meterDataSet.push(requiredMeterData);
            }
          }
        }
        return meterDataSet;
      })
    );
  }

  public addCreditAssets(assets: ICreditAsset[]): void {
    this.store$.dispatch(new AddCreditAssets(assets));
  }

  public addCollateralAssets(assets: ICreditAsset[]): void {
    this.store$.dispatch(new AddCollateralAssets(assets));
  }

  public addCreditPeriods(periods: ICreditPeriod[]): void {
    this.store$.dispatch(new AddCreditPeriods(periods));
  }

  public addLtvGradation(ltvGradation: ILtvGradation[]): void {
    this.store$.dispatch(new AddLtvGradation(ltvGradation));
  }

  public addActiveCredits(credits: IPaginatedList<ICredit>): void {
    this.store$.dispatch(new AddActiveCredits(credits));
  }

  public addArchiveCredits(credits: IPaginatedList<ICredit>): void {
    this.store$.dispatch(new AddArchiveCredits(credits));
  }

  public requestCredit(credit: IRequestedCredit): void {
    this.store$.dispatch(new RequestCredit(credit));
  }

  public repayCredit(credit: IRequestedCredit): void {
    this.store$.dispatch(new RepayCredit(credit));
  }

  public clearRequestedCredit(): void {
    this.store$.dispatch(new ClearRequestedCredit());
  }

  public clearRepaidCredit(): void {
    this.store$.dispatch(new ClearRepaidCredit());
  }

  public clearCredits(): void {
    this.store$.dispatch(new ClearCredits());
  }

  private filterAssets(creditAssets: ICreditAsset[], assets: IAsset[]): IAsset[] {
    return assets.filter(asset => creditAssets.some(creditAsset => creditAsset.shortcode === asset.shortcode));
  }

  private filterWallets(collateralAssets: ICreditAsset[], wallets: IWallet[]): IWallet[] {
    return wallets.filter(wallet => collateralAssets.some(collateralAsset => collateralAsset.shortcode === wallet.asset));
  }
}
