import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {SafeHtmlPipeModule} from '@wallex-core-client/pipes';
import {CoinIconComponent} from '@wallex-core-client/ui';

@NgModule({
  imports: [SafeHtmlPipeModule, CommonModule],
  declarations: [CoinIconComponent],
  exports: [CoinIconComponent]
})
export class CoinIconModule {}
