import {IbanFormFieldInterface} from '@wallex-core-client/core/interfaces/iban.interface';

export interface IRequestedCryptoTransaction {
  fee: number;
  totalAmount: number;
  type: TransactionType.send;
  asset: string;
  recipient: string;
  amount: number;
  beneficiary: string;
  reference: string;
}

export type RequestedFiatTransaction = [{[key: string]: string | number}, IbanFormFieldInterface[], string];

export interface IExchangeTransactionPayload {
  assetFrom: string;
  assetTo: string;
  amount: number | string;
  storeKey: string;
}

export interface ITransactionModel {
  amount: number;
  asset: string;
  createdAt: string;
  fee: number;
  id: string;
  sign: accountSign;
  state: TransactionState;
  title: string;
  isForTopUp: boolean;
  type: TransactionTypeList;
  updatedAt: string;
  userId: string;
  sendAsset?: string;
  receivedAsset?: string;
  source?: string;
}

export interface ITransactionDetails {
  createdAt: string;
  destination: string;
  beneficiary: string;
  reference: string;
  fee: 0;
  id: string;
  payload: any & {type: TransactionPayloadType};
  receivedAmountGross: number;
  receivedAmountNet: number;
  receivedAsset: string;
  receiverId: string;
  sendAmountGross: number;
  sendAmountNet: number;
  sendAsset: string;
  senderId: string;
  isForTopUp: boolean;
  txBlockExplorerUrl: string | null;
  boundedTransactionId: string | null;
  foundsSource: string | null;
  file: ITransactionFile | null;
  sign: accountSign;
  source: string;
  state: TransactionState;
  type: TransactionTypeList;
  updatedAt: string;
  title?: string;
  cardNumber?: string;
}

export interface ITransactionFile {
  id: string;
  fileName: string;
}

export interface ISendTransactionTwoFaPayload {
  asset: string;
  recipient: string;
  amount: string;
  beneficiary: string;
  reference: string;
  token2fa?: string;
  walletId: string;
}

export interface ITransactionView extends ITransactionModel {
  date: string;
}

export enum accountSign {
  plus = '+',
  minus = '-',
  exchange = '+/-'
}

export enum TransactionPayloadType {
  OPENED = 'opened',
  CLOSED = 'closed',
  DEPOSIT = 'deposit',
  ACCRUAL = 'accrual',
  PARTIAL_WITHDRAWAL = 'partialWithdrawal'
}

export enum TransactionType {
  send = 'send',
  receive = 'receive',
  exchange = 'exchange'
}

export enum TransactionState {
  pending = 'PENDING',
  failed = 'FAILED',
  completed = 'COMPLETED',
  rejected = 'REJECTED'
}

export enum TransactionTypeList {
  transfer = 'TRANSFER',
  exchange = 'EXCHANGE',
  saving = 'SAVING',
  credit = 'CREDIT',
  topUp = 'TOP_UP',
  fee = 'FEE'
}
