import {Directive, EventEmitter, HostListener, Output} from '@angular/core';
import {Subject, takeUntil} from 'rxjs';

import {UserStateFacade} from '@wallex-core/store/user/user-state-facade.service';
import {KycService} from '@wallex-core/services/kyc.service';

@Directive({
  selector: '[appKycGuard]'
})
export class KycGuardDirective {
  @Output() onGuardPassed = new EventEmitter<void>();

  private isKYCVerificationCompleted!: boolean;
  private notifier$ = new Subject<void>();

  constructor(private userStateFacade: UserStateFacade, private kycService: KycService) {}

  ngOnInit() {
    this.initializeValues();
  }

  private initializeValues(): void {
    this.userStateFacade.isKYCVerificationCompleted$.pipe(takeUntil(this.notifier$)).subscribe(isKYCVerificationCompleted => {
      this.isKYCVerificationCompleted = isKYCVerificationCompleted;
    });
  }

  @HostListener('click', ['$event'])
  onClick(): void {
    if (!this.isKYCVerificationCompleted) {
      this.kycService.initKYC();
      return;
    }

    this.onGuardPassed.emit();
  }

  ngOnDestroy() {
    this.notifier$.next();
    this.notifier$.complete();
  }
}
