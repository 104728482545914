import {catchError, mergeMap, switchMap, throttleTime} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';

import {Router} from '@angular/router';

import {
  DeleteAvatarAction,
  DeleteAvatarFailureAction,
  DeleteAvatarSuccessAction,
  LoadLastTransactionsAction,
  LoadLastTransactionsFailure,
  SetLastTransactionsAction,
  UploadAvatarAction,
  UploadAvatarFailureAction,
  UploadAvatarSuccessAction,
  UserActionType
} from '@wallex-core/store/user/user.actions';
import {AppStateFacade} from '@wallex-core/store/app/app-state-facade.service';
import {TransactionsService} from '@wallex-core/services/transactions.service';
import {UserService} from '@wallex-core/services/user.service';
import {AuthService} from '@wallex-core/services/auth.service';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private transactionsService: TransactionsService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private appStateFacade: AppStateFacade
  ) {}

  readonly loadLastTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadLastTransactionsAction>(UserActionType.LOAD_LAST_TRANSACTIONS),
      switchMap(() =>
        this.transactionsService.getLastTransactions(3).pipe(
          mergeMap(transactions => [new SetLastTransactionsAction(transactions)]),
          catchError(response => {
            this.appStateFacade.handleFatalError(response);
            return [new LoadLastTransactionsFailure()];
          })
        )
      )
    )
  );

  readonly uploadAvatar$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UploadAvatarAction>(UserActionType.UPLOAD_AVATAR),
      switchMap(_ => this.userService.uploadAvatar(_.formData)),
      mergeMap(avatar => [new UploadAvatarSuccessAction(avatar.profileImageLink)]),
      catchError(response => {
        this.appStateFacade.handleFatalError(response);
        return [new UploadAvatarFailureAction()];
      })
    )
  );

  readonly deleteAvatar$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteAvatarAction>(UserActionType.DELETE_AVATAR),
      switchMap(_ => this.userService.deleteAvatar()),
      mergeMap(() => [new DeleteAvatarSuccessAction()]),
      catchError(response => {
        this.appStateFacade.handleFatalError(response);
        return [new DeleteAvatarFailureAction()];
      })
    )
  );
}
