<span class="hidden" #phoneSuffix>{{ defaultCountry?.phoneRoot }}</span>

<div class="phone-number__wrapper">
  <div class="phone-number__country">
    <img *ngIf="defaultCountry" [src]="defaultCountry.flag" width="24" height="16" class="phone-number__country-flag" alt="" />
  </div>
  <p-dropdown
    [options]="countries"
    inputId="phone"
    optionLabel="phoneRoot"
    [filter]="true"
    filterBy="name"
    filterMatchMode="startsWith"
    [autoDisplayFirst]="true"
    [(ngModel)]="defaultCountry"
    (ngModelChange)="updatePhone()"
    [placeholder]="'common.phone-field' | translate"
    [filterPlaceholder]="'common.search' | translate"
    class="phone-countries"
  >
    <ng-template pTemplate="selectedItem">{{ defaultCountry?.phoneRoot }}</ng-template>
    <ng-template let-item pTemplate="item">
      <div class="phone-number__item" [ngClass]="{active: defaultCountry?.name === item?.name}">
        <img [src]="item.flag" width="24" class="phone-number__item-flag" height="16" alt="" />
        <div class="phone-number__item-text">
          {{ item.name }}
          <span> {{ item?.phoneRoot }} </span>
        </div>
      </div>
    </ng-template>
  </p-dropdown>
  <input
    class="form-control phone-number__field"
    type="text"
    [ngClass]="{'control-invalid': isControlInvalid}"
    [style.--suffix-width]="calcFieldOffset(phoneSuffix)"
    [(ngModel)]="phone"
    (ngModelChange)="updatePhone()"
    appNumberOnly
    pInput
  />
</div>
