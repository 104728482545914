<div
  class="status"
  (click)="initKyc(kycStatus)"
  [ngClass]="{
    verified: kycStatus === kycStatuses.approved,
    unverified: kycStatus === kycStatuses.unverified,
    pending: kycStatus === kycStatuses.pending,
    rejected: kycStatus === kycStatuses.rejected
  }"
>
  {{ userStateFacade.KYCStatusTranslated$ | async }}
</div>
