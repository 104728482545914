<div class="total-amount" [ngClass]="{'control-invalid': areFundsInsufficient || isExternalAmountError}">
  <span class="subheader secondary"> {{ labelText | translate }}: </span>
  <span class="subheader primary" data-qa="additional-fee"> {{ totalAmount | cropNumber : account.decimals }} {{ account.asset }} </span>
</div>

<span class="secondary" data-qa="ref-currency" *ngIf="isShowAmountInRefCurrency && totalAmountInRefCurrency && !areFundsInsufficient && !isExternalAmountError">
  ≈ {{ totalAmountInRefCurrency | numberFr }} {{ currency }}
</span>
<span class="error-message" *ngIf="isExternalAmountError"> An error occurred, please try again later </span>
<span class="error-message" *ngIf="areFundsInsufficient">
  {{ 'account.send-dialog.insufficient-funds' | translate }}
</span>
