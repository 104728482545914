import {shareReplay} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';

import {AddBannersToAuthScreen, DeleteAllBanners, LoadMainScreenBanners} from '@wallex-core/store/banners/banners.actions';
import {IBannersState, selectBannersState} from '@wallex-core/store/banners/banners-state.model';
import {IBanner} from '@wallex-core-client/core/interfaces/banner.interface';

@Injectable({
  providedIn: 'root'
})
export class BannersStateFacade {
  constructor(private readonly store$: Store<IBannersState>) {}

  private readonly state$ = this.store$.pipe(select(selectBannersState));

  readonly mainScreenBanners$ = this.state$.pipe(
    select(state => state.mainScreenBanners),
    shareReplay(1)
  );

  readonly authScreenBanners$ = this.state$.pipe(
    select(state => state.authScreenBanners),
    shareReplay(1)
  );

  public addBannersToAuthScreen(banners: IBanner[]): void {
    this.store$.dispatch(new AddBannersToAuthScreen(banners));
  }

  public loadMainScreenBanners(): void {
    this.store$.dispatch(new LoadMainScreenBanners());
  }

  public clearBanners(): void {
    this.store$.dispatch(new DeleteAllBanners());
  }
}
