import {NgModule} from '@angular/core';

import {CountryNamePipe} from '@wallex-core-client/pipes';

@NgModule({
  imports: [],
  declarations: [CountryNamePipe],
  exports: [CountryNamePipe]
})
export class CountryNamePipeModule {}
