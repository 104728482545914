<p-toast class="toast-message waiting"></p-toast>

<div class="receive-fiat__wrapper" *ngIf="ibanReceiveInfo">
  <div class="receive-fiat__section">
    <p class="receive-fiat__section-title" *ngIf="ibanReceiveInfo.info">{{ ibanReceiveInfo.info }}</p>
    <div class="receive-fiat__list">
      <div class="receive-fiat__item" *ngFor="let item of ibanReceiveInfo.fields">
        <div class="receive-fiat__item-col">
          <p class="receive-fiat__item-title">{{ item.title }}</p>
          <p class="receive-fiat__item-text">{{ item.value }}</p>
        </div>
        <button *ngIf="item.allowCopy" type="button" (click)="copy(item.value, item.title)" class="action-icon">
          <img src="/assets/buttons/copy.svg" alt="" width="20" height="20" />
        </button>
        <p *ngIf="item.description" class="receive-fiat__item-description">{{ item.description }}</p>
      </div>
    </div>
  </div>
</div>
<div class="button-area">
  <button pButton (click)="close($event)" type="button" [label]="'common.okay' | translate"></button>
</div>
