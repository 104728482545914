import {Action} from '@ngrx/store';

import {IServerErrorDisplayData} from '@wallex-core-client/core/interfaces/error-display.interface';

export enum AppActionType {
  SET_LOADING_STATUS = '[App] Set loading status',
  MARK_APPLICATION_FATAL = '[App] Fatal application error occurred',
  MARK_SERVER_ERROR = '[App] Server error occurred',
  CLEAR_USER_SERVER_ERROR = '[App] Clear user server error',
  CLEAR_ERROR = '[App] Clear error',
  MARK_LOGOUT = '[App] Logged out from app',
  ADD_ROUTER_HISTORY = '[App] Add router history',
  CLEAR_ALL_ROUTER_HISTORY = '[App] Clear all router history',
  GO_BACK = '[App] Go back',
  GO_BACK_SUCCESS = '[App] Go back success',
  SET_PAGE_TITLE = '[App] Set page title',
  SET_MAIN_SCREEN_ACTIVE = '[App] Set main screen active',
  UPDATE_ROUTER_HISTORY = '[App] Update Router History',
  DELETE_LAST_ROUTES = '[App] Delete Last Routes'
}

export class SetLoadingStatus implements Action {
  readonly type = AppActionType.SET_LOADING_STATUS;
  constructor(readonly loading: boolean) {}
}

export class MarkServerError implements Action {
  readonly type = AppActionType.MARK_SERVER_ERROR;
  constructor(readonly error: IServerErrorDisplayData) {}
}

export class MarkApplicationFatal implements Action {
  readonly type = AppActionType.MARK_APPLICATION_FATAL;
}

export class MarkLogout implements Action {
  readonly type = AppActionType.CLEAR_ERROR;
}

export class ClearUserServerError implements Action {
  readonly type = AppActionType.CLEAR_USER_SERVER_ERROR;
  constructor(readonly name: string) {}
}

export class AddRouterHistory implements Action {
  readonly type = AppActionType.ADD_ROUTER_HISTORY;
  constructor(readonly route: string) {}
}

export class ClearAllRouterHistory implements Action {
  readonly type = AppActionType.CLEAR_ALL_ROUTER_HISTORY;
  constructor() {}
}

export class GoBack implements Action {
  readonly type = AppActionType.GO_BACK;
  constructor() {}
}

export class GoBackSuccess implements Action {
  readonly type = AppActionType.GO_BACK_SUCCESS;
  constructor() {}
}

export class SetPageTitle implements Action {
  readonly type = AppActionType.SET_PAGE_TITLE;
  constructor(readonly title: string) {}
}

export class SetMainScreenActive implements Action {
  readonly type = AppActionType.SET_MAIN_SCREEN_ACTIVE;
  constructor(readonly isActive: boolean) {}
}

export class UpdateRouterHistory implements Action {
  readonly type = AppActionType.UPDATE_ROUTER_HISTORY;
  constructor(readonly routes: string[]) {}
}

export class DeleteLastRoutes implements Action {
  readonly type = AppActionType.DELETE_LAST_ROUTES;
  constructor(readonly routesNumber: number) {}
}

export class ClearError implements Action {
  readonly type = AppActionType.CLEAR_ERROR;
  constructor() {}
}

export type AppAction =
  | SetLoadingStatus
  | MarkServerError
  | MarkApplicationFatal
  | ClearUserServerError
  | MarkLogout
  | AddRouterHistory
  | GoBack
  | GoBackSuccess
  | ClearAllRouterHistory
  | SetPageTitle
  | SetMainScreenActive
  | UpdateRouterHistory
  | ClearError
  | DeleteLastRoutes;
