import {Component, ChangeDetectionStrategy, Input, forwardRef, ChangeDetectorRef, HostBinding, Output, EventEmitter} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

import {DecimalPipe} from '@angular/common';

import {IWallet} from '@wallex-core-client/core/interfaces/wallet.interface';
import {numberToString, toFixedHard} from '@wallex-core-client/utils';

@Component({
  selector: 'app-amount-input',
  templateUrl: './amount-input.component.html',
  styleUrls: ['./amount-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AmountInputComponent),
      multi: true
    },
    DecimalPipe
  ]
})
export class AmountInputComponent implements ControlValueAccessor {
  @HostBinding('class.amount-input') className: boolean = true;

  @Input() account!: IWallet | null | undefined;
  @Input() shortcode: string | null | undefined;
  @Input() isAssetHidden = false;
  @Input() maxlength?: number;
  @Input() inputPlaceholder!: string;
  @Input() inputId!: string;
  @Input() isControlInvalid!: boolean;
  @Input() isAllButtonShown!: boolean;
  @Input() isRateShown!: boolean;
  @Input() currency?: string | null;
  @Input() rate?: number | null;
  @Output() blur = new EventEmitter<void>();

  public value!: string;
  public isDisabled!: boolean;

  constructor(private cd: ChangeDetectorRef) {}

  public changed!: (value: string) => void;
  public touched!: () => void;

  public onChange(event: Event): void {
    const value: string = (<HTMLInputElement>event.target).value;
    this.value = value;
    this.changed(value);
  }

  public registerOnChange(fn: any): void {
    this.changed = fn;
  }

  public registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  public writeValue(value: string): void {
    this.value = value;
    this.cd.detectChanges();
  }

  public setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this.cd.detectChanges();
  }

  public putAllFunds(event: MouseEvent): void {
    event.preventDefault();

    const balance = toFixedHard(this.account?.balance!, this.account?.decimals!);

    this.changed(numberToString(balance));
    this.value = numberToString(balance);
    this.cd.detectChanges();
  }
}
